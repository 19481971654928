import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter, FaYoutube } from "react-icons/fa6";

import "./mediamenu.css";

const MediaMenu = () => {
  return (
    <div className="top-header d-flex">
      <ul className="top-header-menu d-flex justify-content-between align-items-center gap-3 mb-0 px-0 py-2">
        <li className="media-icons facebook">
          <a
            href="https://www.facebook.com/rauniyarfoundation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="icon" />
          </a>
        </li>

        <li className="media-icons insta">
          <a
            href="https://www.instagram.com/rauniyarfoundationbharat/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="icon" />
          </a>
        </li>

        <li className="media-icons you-tube">
          <a
            href="https://www.youtube.com/@rauniyarfoundation22"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="icon" />
          </a>
        </li>

        <li className="media-icons twitter">
          <a
            href="https://x.com/rauniyarf?s=21"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaXTwitter className="icon" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MediaMenu;
