import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Painting = () => {
  const Point = [
    "The participants have to submit their entry in JPG/JPEG/ PNG/ PDF format as well as the hard copy",
    "The painting should be in a size not less than 2ft by 1.5 ft (24” x 18”)",
    "The painting should be made up of the following medium: water, oil and acrylic.",
    "Selection criteria: Creative, innovative and relevant to the contest theme",
    "The entry must not contain any provocative, objectionable, or inappropriate content.",
    "The photographs should be shot in HD Standard.",
    "The description about the painting needs to be submitted as a PDF document.",
    "Initial Submission of the painting could be from any of the themes mentioned above.",
    "A participant can submit only once. In case it is found that nay participant has submitted more than one entry, all his/ her entries will be considered as invalid.",
  ];
  const timeLine = [
    {
      thead: "Start Date",
      tbody: "Announce soon",
    },
    {
      thead: "End Date for Submission",
      tbody: "Announce soon",
    },
    {
      thead: "Screening",
      tbody: "Last week of November 2024",
    },
    {
      thead: "Winner Announcement Blog",
      tbody: "Last week of November 2024",
    },
    {
      thead: "Grand Finale",
      tbody: "First Week of December 2024",
    },
  ];
  const stages = [
    {
      head: "Round 1",
      list: [
        "The Top 200 participants will be chosen from the total number of entries received on the Rauniyar Foundation Bharat(RFB) platform.",
        "Submissions will be online on Rauniyar Foundation Bharat(RFB) platform in which the selection committee will choose on the basis of the photos submitted by the participants.",
      ],
    },
    {
      head: "Round 2",
      list: [
        " Out of 200 selected participants from the 1st round, 20 participants will be selected for the Grand Finale by the Jury on the basis of their creativity.",
      ],
    },
    {
      head: "Grand Finale",
      list: [
        "Top 20 Contestants will be called for a physical event in Bairgania Mahotsav 2024.",
        "The Top 3 Winners will be announced by the Celebrity Jury, after judging their paintings LIVE at the Physical Event on an undisclosed theme.",
      ],
    },
    {
      head: "Mentorship",
      list: [
        "The Top 3 winners will be mentored for a period of 1 month with a mentorship stipend (if the winner's city is different from the city of the mentor)",
      ],
    },
  ];
  const prizeMoney = [
    {
      prize: "1st Winner INR. Cash/- + Trophy/Medal + Certificate",
    },
    {
      prize: "2nd Winner INR. Cash/- + Trophy/Medal + Certificate",
    },
    {
      prize: "3rd Winner INR. Cash/- + Trophy/Medal + Certificate",
    },
  ];
  const termsandcondition = [
    "The contest is open to all Indian & Nepali citizens.",
    "All participants must be between the age group of 10 and 25 to participate in the contest.",
    "All entries must be submitted on the Rauniyar Foundation Bharat(RFB) portal.",
    "Entries submitted through any other mode will not be considered for evaluation.",
    "The participants have to submit their entry in JPG/JPEG/ PNG/ PDF format and Hard copy along with the short description about the painting.",
    "The painting should be in a size not less than 2ft by 1.5 ft (24” x 18”).",
    "The painting should be made up of the following medium: water, oil and acrylic.",
    "Selection criteria: Creative, innovative and relevant to the contest theme.",
    "The entry must not contain any provocative, objectionable, or inappropriate content.",
    "The submitted entry should be shot in HD Standard.",
    "The description about the painting needs to be submitted as a PDF document.",
    "The participant should ensure that his/her Rauniyar Foundation Bharat(RFB) registered Marathon profile is accurate and updated, as organisers would use this for further communication.",
    "This includes details such as name, photo, complete postal address, email ID, and phone number, state.",
    "Participant & profile owner should be the same.",
    "Mismatch will lead to disqualification.",
    "Submission of the Painting must be original and should not violate any provision of the Indian Copyright Act, 1957.",
    "If any entry found infringing on others, the entry will be disqualified from the competition.",
    "Selection process will be based on Painting Submission (though photograph) – Viewers’ Choice – Jury selection.",
    "The winners will be declared by way of announcing their names on the Rauniyar Foundation Bharat(RFB) blog page after every level.",
    "Organisers reserve the right to reject any entry that does not feel is suitable or appropriate or which does not conform to any of the conditions listed above.",
    "By sending the entries, the Entrant accepts and agrees to be bound by these Terms & Conditions mentioned above.",
    "In the event of unforeseen circumstances, organizers reserve the right to amend or withdraw the competition at any time.",
    "For the avoidance of doubt this includes the right to amend these terms and conditions.",
  ];

  return (
    <>
      <section className="mearathon">
        <div className="container">
          <div className="row w-100 gx-3 gy-4">
            <div className="col-md-7">
              <div className="merathon-detail">
                <div className="register-dec">
                  <Tabs
                    defaultActiveKey="Regular"
                    id="uncontrolled-tab-example"
                    className="mb-3 d-none"
                  >
                    <Tab
                      eventKey="Regular"
                      className="tab-title"
                      
                    >
                      <div className="main-text-mahostav">
                        <p
                          className={`regular-dec mahostav-dec main-text-chairman list-table-one `}
                        >
                          <p>
                            <div className="chairman-title d-flex align-items-center px-2 py-1 my-3">
                              <h1
                                className="main-text-vicechairman-title mb-0"
                                style={{ lineHeight: "30px" }}
                              >
                                Rauniyar Foundation Bharat(RFB) YUVA
                                Pratibha-2024 <br /> (Painting Talent Hunt)
                              </h1>
                            </div>
                            Indian painting has a rich heritage and history in
                            Indian art. The oldest Indian paintings were
                            prehistoric rock paintings, such as the petroglyphs
                            discovered in places like the Bhimbetka rock
                            shelters. Some of the Stone Age rock art discovered
                            among the Bhimbetka cave walls dates back 10,000
                            years.
                            <br />
                            <br />
                            Unleash your creativity and paint your way to top at
                            the YUVA Pratibha-2024 (Painting Talent Hunt).{" "}
                            <br />
                            <br />
                            Painting Talent Hunt is a unique opportunity for
                            citizens across India & Nepal to display their
                            artistic talent and skills to gain National &
                            International recognition. If you think you want to
                            be New India’s emerging artist, illustrator,
                            miniaturist or portrait maker, participate in the
                            YUVA Pratibha-2024 (Painting Talent Hunt) and
                            showcase your creativity and craftsmanship on the
                            various themes
                            <span className="fw-semibold d-flex align-items-center gap-3">
                              <FaArrowRightLong className="me-2 my-4" />
                              Entry Fee:{" "}
                              <Link
                                to={"/bairganiaMahotsav"}
                                className="text-mahostav"
                              >
                                Entry Link
                              </Link>{" "}
                              <br />
                            </span>
                            <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                              <h1 className="main-text-vicechairman-title mb-0">
                                YUVA PRATIBHA
                              </h1>
                            </div>
                            <p className="list-table-one">
                              Painting Talent Hunt is a unique opportunity for
                              citizens across India & Nepal to display their
                              artistic talent and skills to gain National &
                              International recognition. If you think you want
                              to be New India’s emerging artist, illustrator,
                              miniaturist or portrait maker, participate in the
                              YUVA Pratibha-2024 (Painting Talent Hunt) and
                              showcase your creativity and craftsmanship on the
                              various themes
                            </p>
                            <div className="row w-100 align-items-center gx-3">
                              <div className="col-md-3 p-2 border">
                                <div className="culter-ing overflow-hidden" style={{height:'200px'}}>
                                  <img src={require('../../../assetes/img/heritage_one.webp')} alt="" className="w-100 h-100 object-fit-cover" />
                                </div>
                                <p className="mb-0 text-center my-2 ms-0">Heritage & Cultural</p>
                              </div>
                              <div className="col-md-3 p-2 border">
                                <div className="culter-ing overflow-hidden" style={{height:'200px'}}>
                                  <img src={require('../../../assetes/img/vaour.webp')} alt="" className="w-100 h-100 object-fit-cover" />
                                </div>
                                <p className="mb-0 text-center my-2">Valour & Patriotism</p>
                              </div>
                              <div className="col-md-3 p-2 border">
                                <div className="culter-ing overflow-hidden" style={{height:'200px'}}>
                                  <img src={require('../../../assetes/img/nature.webp')} alt="" className="w-100 h-100 object-fit-cover" />
                                </div>
                                <p className="mb-0 text-center my-2" >Nature & Environment</p>
                              </div>
                              <div className="col-md-3 p-2 border">
                                <div className="culter-ing overflow-hidden" style={{height:'200px'}}>
                                  <img src={require('../../../assetes/img/modi.webp')} alt="" className="w-100 h-100 object-fit-cover" />
                                </div>
                                <p className="mb-0 text-center my-2" style={{fontSize:"12px"}}>Public Heroes & Leaders</p>
                              </div>
                            </div>
                           
                           
                         
                            <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                              <h1 className="main-text-vicechairman-title mb-0">
                                POINTS TO NOTE
                              </h1>
                            </div>
                            {Point.map((item, index) => {
                              return (
                                <>
                                  <ul
                                    className="my-2"
                                    style={{ listStyle: "disc" }}
                                    key={index}
                                  >
                                    <li>
                                      <p className="list-table-one">{item}</p>
                                    </li>
                                  </ul>
                                </>
                              );
                            })}
                            <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                              <h1 className="main-text-vicechairman-title mb-0">
                                TIMELINE
                              </h1>
                            </div>
                            <div className="mt-2">
                              <table className="table w-100">
                                {timeLine.map((item, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <th>{item.thead}</th>
                                        <td>{item.tbody}</td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </table>
                            </div>
                          </p>
                          <p className="list-table-one fw-medium ms-0 p-2">
                            <span className="fw-semibold">Please Note:</span>{" "}
                            The above-mentioned timeline can be updated.
                            Participants need to have a regular check on the
                            content for all the updates.
                          </p>
                          <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                            <h1 className="main-text-vicechairman-title mb-0">
                              STAGES
                            </h1>
                          </div>
                          <p className="list-table-one fw-medium mb-2">
                            The competition will be divided into the following
                            Round
                          </p>
                          {stages.map((item, index) => {
                            return (
                              <>
                                <div
                                  className="d-flex  w-100 list-table-one"
                                  key={index}
                                >
                                  <p className="w-25">{item.head}</p>
                                  <div className="container">
                                    <ul style={{ listStyle: "disc" }}>
                                      {item.list.map((item, index) => {
                                        return (
                                          <li key={index}>
                                            <p className="list-table-one mb-1 fw-medium ms-0">
                                              {item}
                                            </p>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                          <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                            <h1 className="main-text-vicechairman-title mb-0">
                              PRIZE MONEY
                            </h1>
                          </div>
                          <ul className="my-3 ms-3 ps-0">
                            {prizeMoney.map((item, index) => (
                              <li
                                key={index}
                                className="d-flex gap-3 align-items-center"
                              >
                                <FaArrowRightLong className="demo-icon-one" />
                                <p className="list-table-one fw-medium mb-1">
                                  &nbsp; {item.prize}
                                </p>
                              </li>
                            ))}
                          </ul>
                          <div className="container">
                            <ul className="ms-3" style={{ listStyle: "disc" }}>
                              <li>
                                <p className="list-table-one fw-medium mb-1 mt-2">
                                  Remaining 17 Contestants in the physical event
                                  will be rewarded with a Cash Prize of INR.
                                  N/A/- each
                                </p>
                              </li>
                              <li>
                                <p className="list-table-one fw-medium">
                                  The initial 200 selected Contestants by the
                                  middle level jury will get a digital
                                  Certificate of Recognition.
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                            <h1 className="main-text-vicechairman-title mb-0">
                              MENTORSHIP
                            </h1>
                          </div>
                          <p className="list-table-one fw-medium">
                            The Top 3 winners will be mentored for a period of 1
                            month with a mentorship stipend, if the winner's
                            city is different from the city of the mentor.
                          </p>
                          <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                            <h1 className="main-text-vicechairman-title mb-0">
                              TERMS & CONDITIONS
                            </h1>
                          </div>
                          <ul className="my-2" style={{ listStyle: "disc" }}>
                            {termsandcondition.map((item, index) => {
                              return (
                                <>
                                  <li key={index}>
                                    <p className="list-table-one">{item}</p>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </p>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex  align-items-center w-100 h-100 overflow-hidden">
                <div className="mahostav-video p-3 w-100">
                  <div className="video-mahostav overflow-hidden rounded-2">
                    <video
                      className="w-100 h-100 object-fit-cover"
                      poster={require("../../../assetes/img/BairganiaPNG.png")}
                      controls
                    >
                      <source
                        src={require("../../../assetes/video/painting.MOV")}
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Painting;
