import React from "react";
import "./about.css";
const MOA = () => {
  const mainList = [
    " To promote education and learning among children, including but not limited to Establishing, running, managing,and supporting educational institutions such as schools,educational centers, and vocational training institutes and to conduct research, organizing workshops, seminars, and conferences aimed at improving educational methodologies and outcomes for the holistic development of children.",
    " To provide scholarships to economically disadvantaged children for their education, also to develop and distribute educational materials, including books, digital content, and learning aids, to enhance educational opportunities for children and to collaborate nongovernmental organizations (NGOs), and other stakeholders to advocate for policies and initiatives that benefit children's education",
    "  To set up training, quality control, process development, technology, common facility centres and other infrastructural activities for the proper upliftment of Page 2 of 12 children and to organize all types of sports events and other cultural events to support the above mentioned objects.",
  ];
  const mainList1 = [
    "To form and manage universities and all kinds of other formal and informal educational institutions for the promotion of pre-primary,primary, middle, secondary and higher education.",
    "  To open, found,establish, promote, set up, run,maintain, assist, finance,support and/or aid and help in the setting up the different kinds of technical/non-technical schools, engineering colleges,medical colleges and other establishments or institutions for advancement of all kinds of education and knowledge (also get permission from the competent authority/department, if required).",
    "  To provide educational scholarship to children from disadvantaged background and training in arts and crafts and in skills to children, this shall help them to become self sustaining.",
    " To conduct basic and advanced level workshop for school students and college graduates and different level championship based on knowledge event competition so as to enhance skills team building and competitive spirit among students of India and abroad.",
    "To organize seminar, symposium, workshop, and exhibition, brainstorming session at national and international level to discuss about future of various modes of education and how it can contribute to the growth of the country.",
    " To provide all the materials, components, customized kits, at a competitive rate with informative books and manuals and to promote research in areas of personality Page 3 of 12 development, arts, crafts, child psychology.",
    " To open, construct, manage and control urban and rural medical and health services centers, hospitals and dispensaries with adequate building, wards, including maternity and child welfare unites, ambulance service, laboratories, operation theaters, diagnostic and treatment appliances and instruments, medicines, store and proper store facilities, quarters, etc.,with special view to provide surgical and medical facilities in any path at minimum rates or free of costs to the poor people,and to arrange or raise the funds and to beg to State/Central Govt., International agencies for relevant aids, or funds, or affiliation for the above objects of this company.",
    " To work for fight against global warming, global pollution to save the earth and making the earth best place to live and to raise awareness on environmental issues and push for changes in policy and development strategies",
    " To distribute nutritious food to the poor people or to people of weaker section of the society and also to spread awareness so as not to waste any food in marriage and other functions.",
    " To open, found, establish, promote, set-up, run, maintain, assist, finance,support or help the various community development programmers/activities and also construct and develop the old age home, orphanage,community Halls, Marriage Home, night shelters, health care centers, charitable dispensaries hospital, yoga training centers, and the Institution for the upliftment and betterment of the society and for the general welfare of the general public, in all over India.",
    "To establish, conduct,Page 4 of 12 maintenance of clinical laboratories, hospitals, nursing homes, dispensaries and institutions of similar nature and providing financial assistance to the deserving persons for medical treatment, in any medical institution and to frequent organize programs of plantation of trees to public place, like school, college, cinemas rail and bus station,market etc,",
    " To encourage women?s literacy and to empower women to live with dignity and contributing as equal partners in development in an environment free from violence and discrimination and spread awareness regarding importance of sanitary pads in rural and slum areas and distribute sanitary pads through seminars/ camps/ campaign towards women of the society",
    // " To promote education and learning among children, including but not limited to Establishing, running, managing,and supporting educational institutions such as schools,educational centers, and vocational training institutes and to conduct research, organizing workshops, seminars, and conferences aimed at improving educational methodologies and outcomes for the holistic development of children.",
    "To Provide assistance to hospitals in setting up the various facilities such as Blood Bank, Eye Bank, Burn Centers etc., for poor patients and outdoor dispensary or assistance thereof and also to run Mobile Medical Services.",
    "To organize community outreach programs for youth and women and to empower tribal women and community through livelihood program.",
    "To Promote social and economic empowerment of women through cross-cutting policies and programs, mainstreaming gender concerns, creating awareness about their rights and facilitating institutional and legislative support for enabling them to realize their human rights and develop to their full potential.",
    "To undertake welfare activities and awareness program on women’s/child health and nutrition, equality, development, violence against women, rural and tribal empowerments etc. and to promote self-defense among the women to overcome anti-social elements of the society and other issues of Female feticide in India, child sexual abuse, dowry system, alcohol abuse, love marriages and Honor killings, domestic violence and rape in India.",
    "The vocational training Center provides women from the community such as girls who have dropped out of school, former female and illiterate women.",
    "To form women groups, youth groups, self-help groups, old aged groups, adolescent groups, children group/ clubs, community leaders groups and group of volunteers and to work with special concentration for the child and women welfare.",
    "Conventional and non-conversion energy resources.",
    "To conduct research and development, intellectual property protection, and international cooperation, promotion, and coordination in renewable energy sources such as wind power, small hydro, biogas, and solar power. The Company’s aim is to develop and deploy new and renewable energy for supplementing the energy requirements of India.",
    "To protect the environment through training, research and creating awareness through seminars, workshops, awareness campaigns and eco-clubs, and use of environmentally sound and appropriate technologies, biodegradation of wastes, renewable energy, biodiversity, occupational health, desertification, wildlife protection etc. and promote sustainable development through wasteland development, water shed management, environment awareness at the grass-root level, develop nurseries, kitchen gardens etc. To promote social forestry and to take measure to prevent pollution of water, soil, air etc.",
    "To develop and mobilize community and natural resources to give the right direction to people of the marginalized and economically weaker section of the society for their sustainable overall development and to address child healthcare issues as a major priority along with child development and education and aspire to take every possible step to fight with Infant mortality, child abuse, child labor, and trafficking in our country and to provide free meals, clothes, medicines, and other needy things to the poor and needy children of widows etc.",
    "To empower individuals/groups/general public regarding issues involving the law and to promote consciousness of legal culture, participation in the formation of laws and the rule of law as per the Constitution of our Nation and to provide legal aid, social economic help and assistance to the needy people.",
    "To promote National Integration, Communal Harmony, Universal Brotherhood, and Global Peace and to advocate Human Rights and Fundamental Freedom for all without any discrimination of race, religion, caste, color, sex, and language.",
    "To prevent human rights violations and safeguard the fundamental rights of every citizen of India and to support and motivate institutions/organizations in carrying out training and other entrepreneurship development-related activities.",
    "To spread awareness about health services of childbirth and care services and hygiene and unhygienic and provide sanitary and medicines towards women of the society.",
    "To detect, prevent and manage chronic illness cardiovascular diseases, cancer, typhoid, and other kinds of preventive diseases also to screening and counseling for people with diseases like diabetes, cancer, tuberculosis, polio, and tetanus.",
    "To construct rehabilitation center, hospital for disabled mental health disorders such as mental retardation, cerebral palsy, spasticity, autism, down syndrome, and physical disabilities, and to provide treatment for therapist center, health park care center, and yoga center.",
    "To fight against the national calamities, and or any national disasters, and to start or to arrange relief, relief camps, fooding, clothing, shelters, financial, moral, medical aids, and the medical centers for the victims. To arrange and raise the funds and other aids for the purpose and to establish a disaster management group for providing environmental facilities to the people, and or the affected people, and to start, maintain and assist in relief measures for them.",
    "To promote other employment activities and labor welfare schemes and give contribution to the upliftment of the standards of employment and labor welfare in India.",
    "To provide financial and medical assistance for treatments and surgeries, such as open-heart surgery, cancer treatment, eye surgery, AIDS, etc., and to provide medicines and other medical services including dental service and surgical aid at lower rates to the needy member of the persons.",
    "To open generic medicines, shops, clinic etc. running by the ministry of chemicals and fertilizers program under the Government of India.",
    "To organize/construct shelter homes for homeless/helpless children. To provide free subsidies or free education to the poor, helpless and needy children/students and to spread awareness among masses and to educate elderly people about ‘Maintenance and Welfare of Parents and Senior Citizens ACT’ especially in urban India to empower them with sense of security and to work for rural development via activities.",
    "To address the issue of Drinking Water and Sanitation with the help of public-private partnership and to create self-sustainable economic model of Drinking Water and Sanitation among communities with emphasis on Urban Sanitation and its challenges in our country.",
    "To provide food, clothes, medical aid, stationary, libraries, laboratories, reading rooms, hostels, playgrounds, swimming pool and other possible facilities to needy person and to spread awareness regarding democratic and fundamental rights by organizing Seminars, Meetings, Press-Conferences, and other lawful gatherings from time to time.",
    "To establish research Centre, publishing books, magazines, booklets and others related to education by promoting literacy, cultural and other social activities by Awareness Programs, Adult Education Classes, Sarv Shiksha Abhiyan, Lectures, Essay Competitions, Exhibitions, Symposiums, Cultural Programs, Press Conferences, and Seminars.",
    "To spread awareness about Traffic Rules/Road Safety Rules and also to run various Road Safety Programmes and also to assist the Transport Department in this connection and to promote training for rural sports, nationally recognized sports, paralympic sports, and Olympic sports.",
    "To accept donations, grants, presents and offerings, in cash or in kind from persons, association of persons, firms, companies, institutions, universities in India and abroad and to deal with the same for the purpose of achieving the objects of the company mentioned above upon such terms and conditions as companies may think fit and consistent with the objects of the company.",
    "To receive financial and non-financial assistance from Govt., Non-Govt. Organizations, International Agencies, Banks, and any other legal entity or individual and to invest money of the Company in Mutual Funds, UTI, Securities or Deposits as are permitted by the law for the time being in force also to purchase/acquire lands and/or the buildings in the name of the Company for the upliftment and fulfillment of the Aims of the Company.",
    "To pay salary/wages/fee including consultancy, retainer-ship fee, sitting fee or rent and incur all other expenses needed in fulfillment of the objects of the Company.",
    "To pay all expenses, preliminary or incidental to the formation of the Company and its registration.",
    "To receive subscription/donations and grants for accomplishing the academic and professional goals for standardizing the working of the institutes/schools run by the Company so as to borrow or receive money (with interest or without interest) and upon such terms and conditions as are approved by the Board of Company.",
    "To erect, construct, alter, maintain, sell, lease, mortgage, transfer, improve, develop, manage and control all or any part of the property or the building of the above Company, necessary or convenient for the purpose of the attainment of the Aims and Objects of the Company, and all the acts/activities/programs shall be Non-Profitable and shall be done on 'No Profit-No Loss’ bases. Also, this Company will invest its money and funds according to Sec.-11(5) of Income Tax Act, 1961. This Company can also work for any activities mentioned in schedule VII of Companies Act. 2013 related to C.S.R. (Corporate Social Responsibility).",
  ];
  const contentList = [
    {
      header:
        "the doing of all such other lawful things as considered necessary for the furtherance of the above objects:",
      text: " Provided that the company shall not support with its funds, or endeavor to impose on, or procure to be observed by its members or others, any regulation or restriction which, as an object of the company, would make it a trade union.",
    },
    {
      header: "4 *The objects of the company extend to the",
      text: "whole of India",
    },
    {
      header:
        "5 (i) The profits, if any, or other income and property of the company, whensoever derived, shall be applied, solely for the promotion of its objects as set forth in this memorandum.",
      text: `(ii) No portion of the profits, other income or property aforesaid shall be paid or transferred, directly or indirectly, by way of dividend, bonus or otherwise by way of profit, to persons who, at any time are, or have been, members of the company or to any one or more of them or to any persons claiming through any one or more of them.

(iii) No remuneration or other benefit in money or money’s worth shall be given by the company to any of its members, whether officers or members of the company or not, except payment of out-of-pocket expenses, reasonable and proper interest on money lent, or reasonable and proper rent on premises let to the company.

(iv) Nothing in this clause shall prevent the payment by the company in good faith of prudent remuneration to any of its officers or servants (not being members) or to any other person (not being a member), in return for any services actually rendered to the company.

(v) Nothing in these clauses (iii) and (iv) shall prevent the payment by the company in good faith of prudent remuneration to any of its members in return for any services (not being services of a kind which are required to be rendered by a member), actually rendered to the company.`,
    },
    {
      header:
        "6 No alteration shall be made to this memorandum of association or to the articles of association of the company which are for the time being in force, unless the alteration has been previously submitted to and approved by the Registrar.",
      text: "",
    },
    {
      header: "7 The liability of the members is limited.",
      text: "",
    },
    {
      header: "8 Table applicable to Section 8/ Part I Section 8 company",
      text: "",
    },
    {
      header: `Table A / B / C 
            (A - MEMORANDUM OF ASSOCIATION OF A COMPANY LIMITED BY SHARES
            B - MEMORANDUM OF ASSOCIATION OF A COMPANY LIMITED BY GUARANTEE AND NOT HAVING SHARE CAPITAL
              C - MEMORANDUM OF ASSOCIATION OF A COMPANY LIMITED BY GUARANTEE AND HAVING SHARE CAPITAL)`,
      text: `Each member undertakes to contribute to the assets of the company in the event of its being wound up while he is a member or within one year afterwards, for the payment of the debts or liabilities of the company contracted before he ceases to be a member and of the costs, charges, and expenses of winding up, and for adjustment of the rights of the contributories among themselves such amount as may be required not exceeding a sum of Rs *.

The share capital of the company is ------ rupees, divided into...`,
    },
    {
      header:
        "9 True accounts shall be kept of all sums of money received and expended by the company and the matters in respect of which such receipts and expenditure take place, and of the property, credits, and liabilities of the company; and, subject to any reasonable restrictions as to the time and manner of inspecting the same that may be imposed in accordance with the regulations of the company for the time being in force, the accounts shall be open to the inspection of the members. Once at least in every year, the accounts of the company shall be examined, and the correctness of the balance-sheet and the income and expenditure account ascertained by one or more properly qualified auditor or auditors.",
      text: "",
    },
    {
      header:
        "10 If upon a winding up or dissolution of the company, there remains, after the satisfaction of all the debts and liabilities, any property whatsoever, the same shall not be distributed amongst the members of the company but shall be given or transferred to such other company having objects similar to the objects of this company, subject to such conditions as the Tribunal may impose, or may be sold and proceeds thereof credited to the Rehabilitation and Insolvency Fund formed under Section 269 of the Act.",
      text: "",
    },
    {
      header:
        "11 The Company can be amalgamated only with another company registered under section 8 of the Act and having similar objects.",
      text: "",
    },
    {
      header:
        "12 We, the several persons, whose names and addresses are subscribed, are desirous of being formed into a company in pursuance of this memorandum of association:",
      text: "",
    },
    {
      header: "",
      text: `1. VIJAY KUMAR GUPTA
            2. ARCHANA PATEL GUPTA`,
    },
  ];

  return (
    <section className="moa-sec">
      <div className="container">
        <div className="mt-5">
          <div className="chairman-title  d-flex align-items-center px-2 py-1 mb-3">
            <h1 className="main-text-vicechairman-title">
              Memorandum of Association (MOA)
            </h1>
          </div>
          <div className="about-us-list list-table-one">
            <div className="d-flex gap-2 align-items-center">
              <p className="about-title main-moa-title fs-6 fw-semibold mb-3">
                1 The name of the company is
              </p>
              <p className="fw-bold">RAUNIYAR FOUNDATION BHARAT</p>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <p className="about-title main-moa-title fs-6 fw-semibold mb-3">
                2 The registered office of the company will be situated in the
                state of Gujarat
              </p>
            </div>
            <p className="about-title main-moa-title fs-6 fw-semibold mb-3">
              3 (a) The objects to be pursued by the company on its
              incorporation are Primary education and
            </p>
            <ol className="main-about-list">
              {mainList.map((item, index) => {
                return (
                  <li className="list-table-one" key={index}>
                    {item}
                  </li>
                );
              })}
            </ol>
            <p className="about-title main-moa-title fs-6 fw-semibold mb-3">
              (b) Matters which are necessary for furtherance of the objects
              specified in clause 3(a) are are
            </p>
            <ol className="main-about-list ">
              {mainList1.map((item, index) => {
                return (
                  <li
                    className="list-table-one  main-text-moa   w-75"
                    key={index}
                  >
                    {item}
                  </li>
                );
              })}
            </ol>
            {contentList.map((item, index) => (
              <div
                key={index}
                className="main-text-moa  d-flex flex-column gap-2 w-75 mb-4"
              >
                {item.header && (
                  <p className="header-class main-moa-title fs-6 fw-semibold">
                    {item.header}
                  </p>
                )}
                {item.text && (
                  <div className="list-table-one  text-class">
                    {item.text.split("\n").map((para, idx) => (
                      <p key={idx}>{para}</p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MOA;
