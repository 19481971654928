import React from "react";
import "./ourpartner.css";
const OurPartners = () => {
  return (
    <>
      <section className="partner-sec mb-5">
        <div className="container mb-2">
          <div className="main-partner">
            <div className="official-partner-header d-flex justify-content-center pt-5 pb-3">
              <p className="official-partner-title text-center fw-semibold mb-0">
                Our Title Sponsor
              </p>
            </div>
            <div className="d-flex justify-content-evenly align-items-center w-100 p-3">
              <div className="official-partner bob-img ">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/RauniyarLifestyle_Logo(01).png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100 my-2">
              <p className="title-sponsoer text-uppercase text-center">
                RAUNIYAR LIFESTYLE PRIVATE LIMITED <br />{" "}
                surat,gujarat,bharat-395007
              </p>
            </div>
          </div>
        </div>
        <div className="container mb-2">
          <div className="main-partner">
            <div className="official-partner-header d-flex justify-content-center pt-5 pb-3">
              <p className="official-partner-title text-center fw-semibold mb-0">
                Our Apparel Partner
              </p>
            </div>
            <div className="d-flex justify-content-evenly align-items-center w-100 p-3">
              <div className="official-partner bob-img ">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/appareal01.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-2">
          <div className="main-partner">
            <div className="official-partner-header d-flex justify-content-center pt-5 pb-3">
              <p className="official-partner-title text-center fw-semibold mb-0">
                Our Official Associate
              </p>
            </div>
            <div className="d-flex justify-content-evenly align-items-center w-100 p-3">
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/ssblogo.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/Ram-PNG.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/img/श्री_राम_जानकी_बाबा_लाल_दास_मठ.jpg")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/IMG_0122.jpeg")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
             
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/Women_Empowerment.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/brahmansenalogo.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/Sita_Punaura.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
             
             
            </div>
          </div>
        </div>
        <div className="container mb-2">
          <div className="main-partner">
            <div className="official-partner-header d-flex justify-content-center pt-5 pb-3">
              <p className="official-partner-title text-center fw-semibold mb-0">
                Our Banking Partner
              </p>
            </div>
            <div className="d-flex justify-content-evenly align-items-center w-100 pb-3">
              <div className="official-partner bob-img">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/bob.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-2">
          <div className="main-partner ">
            <div className="official-partner-header d-flex justify-content-center pt-5 pb-3">
              <p className="official-partner-title text-center fw-semibold mb-0">
                Our Up Coming Event
              </p>
            </div>
            <div className="d-flex justify-content-center gap-3 align-items-center w-100 p-3">
              <div className="official-partner">
                <div
                  className="official-img d-flex justify-content-center align-items-center overflow-hidden"
                  style={{ width: "70%" }}
                >
                  <img
                    src={require("../assetes/img/Hanumanji-PNG.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/ourassosiates/bpl-logo.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/img/BairganiaPNG.png")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="official-partner">
                <div className="official-img d-flex justify-content-center align-items-center overflow-hidden">
                  <img
                    src={require("../assetes/img/Integrity-JPEG.jpg")}
                    alt=""
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurPartners;
