import React from "react";
import "./pages.css";
const RefundAndCancelationPolicy = () => {
  return (
    <section className="moa-sec">
      <div className="container">
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="refundandpolicy p-3">
            <div className="refund-title d-flex align-items-center px-2 py-1">
              <h1 className="main-text-vicechairman-title">
                Refunds/Cancellations Policy
              </h1>
            </div>

            <p className="list-table-one">
              At{" "}
              <span className="fw-semibold text-uppercase">
                Rauniyar Foundation Bharat
              </span>
              , we are dedicated to supporting charitable causes through our
              events, including marathons. The registration fees collected for
              our marathon events are considered donations to our charitable
              programs.
            </p>
            <div className="refund-title d-flex align-items-center px-2 py-1">
              <h1 className="main-text-vicechairman-title">Refund-Policy</h1>
            </div>
            <p className="list-table-one">
              Since your registration is a donation to support the welfare
              initiatives of{" "}
              <span className="fw-semibold text-uppercase">
                Rauniyar Foundation Bharat
              </span>{" "}
              , we do not offer refunds or accept cancellations once a donation
              has been made. Your contribution will be directly utilized for the
              causes we support, and we deeply appreciate your generosity and
              understanding.
            </p>
            <div className="refund-title d-flex align-items-center px-2 py-1">
              <h1 className="main-text-vicechairman-title">Cancellations</h1>
            </div>

            <p className="list-table-one">
              If you are unable to participate in the marathon for any reason,
              we kindly request that you consider your registration fee as a
              donation to our foundation. Your contribution will directly
              support the causes we champion, and we sincerely appreciate your
              generosity and understanding.
            </p>
            <div className="refund-title d-flex align-items-center px-2 py-1">
              <h1 className="main-text-vicechairman-title">
                Event Cancellations
              </h1>
            </div>

            <p className="list-table-one">
              In the event that we have to cancel the marathon due to unforeseen
              circumstances, we will make every effort to either reschedule the
              event or manage the funds towards a future event. The rescheduled
              event may be the same marathon or a different one, depending on
              the circumstances and environment at the time.
            </p>
            <p className="list-table-one">
              We will notify all participants of any changes or cancellations as
              soon as possible.
            </p>
            <p className="list-table-one">
              Thank you for your continued support and understanding.
            </p>
            <div className="mt-3">
              <p className="list-table-one">
                If you have any questions or concerns, please feel free to
                contact us at:
              </p>
              <p className="list-table-one">
                <span className="fw-semibold">Email : </span>{" "}
                <a href="mailto:rauniyarfoundation@gmail.com">
                  info@rauniyarfoundationbharat.org
                </a>{" "}
              </p>
              <p className="list-table-one">
                <span className="fw-semibold">Phone : </span>
                <a href="+918155067100">+918155067100</a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RefundAndCancelationPolicy;
