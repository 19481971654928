import React from "react";
import "./about.css";
const HalfMerathon = () => {
  const content = [
    {
      header: "Unemployment Awareness",
      text: [
        `Unemployment is a pressing issue in India that affects millions of people across the country. It is essential to
         understand the various factors that contribute to this problem and explore potential solutions to combat it. In
         this article, we will delve into the causes of unemployment in India, shedding light on the intricacies of this
         multifaceted challenge.`,
        `An extensive body of research has linked job loss to poorer physical and mental health and higher risk of premature
         death. Recent literature has focused on establishing the causal nature of this association, but few studies have
         explored whether specific social programs modify the health effects of job loss. Understanding the impact of policies is
         useful for identifying intervention approaches to reduce the harms associated with unemployment, but they may also reveal
         some of the mechanisms explaining the association between job loss and health. Job loss is associated with a
         substantial loss in earnings. If earnings losses are the primary mechanism linking job loss to health, we would
         expect generous unemployment benefit programs to mitigate some of the negative consequences of job loss on health. On
         the other hand, unemployment benefits may be less effective if job loss influences health primarily through nonfinancial
         mechanisms, such as the loss of a time structure for the day, decreased self-esteem, chronic stress, or changes in
         health-related behaviour.`,
        `In this study, we assessed the impact of unemployment benefit programs on the health of the unemployed. We
         hypothesized that income from unemployment benefits reduces psychological and physical morbidity among displaced workers
         such that individuals losing their job at a time of more generous unemployment benefit policies will suffer fewer
         health consequences than comparable individuals losing their jobs during years of lower benefit generosity. By focusing
         on unemployment benefit program generosity at the state level, we circumvent the bias generated by selection into
         benefits in the aforementioned studies. To identify this effect, we exploited variation in state unemployment benefit
         program generosity across US states and linked these to longitudinal individual-level data.`,
      ],
    },
    {
      header: "What are the Causes of Unemployment?",
      text: [
        `India as a country is on a development path; however, there are few loose ends like unemployment that barricades the
         growth of total population. Here are some of the causes of unemployment that will help you understand current
         situation!`,
      ],
    },
    {
      header: "The Caste System: A Social Barrier to Employment",
      text: [
        `The caste system, deeply ingrained in Indian society, is a significant contributor to unemployment. It is a structure
         of social stratification that can potentially pervade virtually every aspect of life in India. In some regions,
         certain types of work are prohibited for members of particular castes. This leads to situations where jobs are
         often given to individuals from a specific community, rather than to those who genuinely possess the requisite skills. As
         a result, there is a higher prevalence of unemployment among those who deserve employment opportunities but are denied
         due to their caste.`,
      ],
    },
    {
      header: "Inadequate Economic Growth",
      text: [
        `India's economy, though steadily growing, faces challenges related to its pace of development. The role of economic
         growth in generating employment is crucial, and the current rate of growth is inadequate to provide sufficient job
         opportunities to the burgeoning population. As the population continues to increase, the economy struggles to
         keep up with the demands for employment. Consequently, a growing number of people find themselves unable to secure
         gainful employment, leading to inadequate levels of employment nationwide.`,
      ],
    },
    {
      header: "Increase in Population",
      text: [
        `India's population is on track to exceed that of China by the year 2025, making it the most populous country in the
         21st century. However, the country's economic growth rate is not commensurate with its population growth. This imbalance
         results in a larger percentage of the population being unemployed, as the job market cannot absorb the growing
         workforce effectively. Understand the relationship between poverty and unemployment!`,
      ],
    },
    {
      header: "Agriculture: A Seasonal Occupation",
      text: [
        `Agriculture, a primary occupation in India, offers employment to a substantial segment of the population.
         However, it is primarily seasonal, with work available for only a few months each year. This seasonal nature of
         agricultural work leaves many agricultural labourers without employment and income for a significant portion of the year.`,
      ],
    },
    {
      header: "Loss of Small-Scale/Cottage Industries",
      text: [
        `Industrialization and the rise of large-scale mass production have made small-scale and cottage industries less
         economically attractive. The demand for cost-effective, mass-produced goods often overshadows the appreciation for
         handcrafted, skill-intensive products. Consequently, cottage and small-scale industries have suffered, leading to the
         unemployment of skilled artisans.`,
      ],
    },
    {
      header: "Low Rates of Saving and Investment",
      text: [
        `India faces a shortage of capital and low rates of savings and investment. Investment, which relies on savings, remains
         insufficient to create new job opportunities and stimulate economic growth. Moreover, there is a notable lack of
         investment in rural areas and tier 2 and tier 3 cities, leaving untapped employment potential unexplored.`,
      ],
    },
    {
      header: "Ineffective Economic Planning",
      text: [
        `One of the critical issues exacerbating unemployment in India is the absence of comprehensive national plans to
         bridge the significant gap between labour supply (abundant) and labour demand (lower). A well-balanced labour market is
         essential to ensure that those seeking jobs can secure them. Without such planning, the job market becomes highly
         competitive, with numerous individuals vying for a limited number of positions.`,
      ],
    },
    {
      header: "Labour Immobility",
      text: [
        `Cultural values that prioritize maintaining proximity to family play a significant role in labour immobility in
         India. Many individuals avoid long-distance travel for employment, as attachment to family is a top priority.
         Language, religion, and climate also contribute to the low mobility of labour. When potential job seekers are unable to
         relocate for work, unemployment rates rise.`,
      ],
    },
    {
      header: "Job Specialization and Skilling",
      text: [
        `In today's globalized economy, jobs have become highly specialized. However, India's education system often falls
         short in providing the necessary training and specialization required for these roles. As a result, many willing workers
         become unemployable due to a lack of essential skills. A study has shown that 33% of educated youth in India are
         unemployed because they lack the skills demanded by the job market. This highlights the urgent need for educational
         reforms and skill development programs.`,
      ],
    },
  ];
  const contentOfList = [
    {
      header: "Solutions to Remove Causes of Unemployment",
      text: [
        "Solving the issue of unemployment in India is a complex challenge that requires a multifaceted approach. Here are some detailed solutions to combat unemployment in the country:",
        "Skill Development Programs: Invest in comprehensive skill development programs to equip the workforce with the skills needed for the job market. Collaborate with industries to identify skill gaps and tailor training accordingly.",
        "Education Reforms: Revamp the education system to align with the demands of the job market. Introduce practical and vocational training that prepares students for real-world employment opportunities.",
        "Promote Entrepreneurship: Encourage entrepreneurship by providing financial incentives, reducing bureaucratic hurdles, and offering mentorship programs. Start-ups and small businesses can be significant sources of job creation.",
        "Invest in Infrastructure: Infrastructure projects such as roads, railways, and urban development not only boost economic growth but also generate employment in construction and related sectors.",
        "Industrial Diversification: Promote the diversification of industries to create a more balanced job market. Encourage the growth of sectors beyond traditional areas like agriculture and IT.",
        "Labour Market Information: Establish platforms or agencies that provide up-to-date information on job vacancies, skill requirements, and market trends. This helps job seekers make informed choices.",
        "Flexible Labour Laws: Reform labour laws to strike a balance between workers' rights and industry needs. Creating a more flexible labour market can encourage job creation.",
        "Public Sector Jobs: Increase opportunities in the public sector by filling vacant government positions. This can provide stable employment options for a significant portion of the population.",
        "Incentivize Foreign Investment: Attract foreign investment by improving the ease of doing business, offering tax incentives, and ensuring a stable regulatory environment. Foreign companies can bring in new job opportunities.",
        "Rural Development: Invest in rural development projects to reduce the urban-rural gap. Providing employment opportunities in rural areas can alleviate migration to overcrowded cities.",
        "Entrepreneurial Education: Incorporate entrepreneurial education in schools and colleges to install a culture of innovation and self-employment.",
      ],
    },
    {
      header: "IMPORTANT NOTES",
      text: [
        "Registration will be open from 15th August 2024 with a registration fee of ₹659/-",
        "45 days prior to the event date, transfer of registration will not be accepted.",
        "Transfer Fee of INR 200/- will be charged for any transfer of registration or credit note.",
        "Change in a category will be accepted only 45 days prior to the event.",
        "Customized BIBs: To receive a customized BIB with your name, please make sure to register at least 60 days before the event date (extra changes).",
        "Non-Transferable Registration: Registrations for the race are not transferable from one person to another. In case the registered participant is unable to participate in the event, they can get their registration transferred to a different race in our race calendar within the same season, subject to sending a mail confirmation 45 days prior to the event date, and paying a transfer fee of INR 200 and the difference in registration cost, if any. Cancellation and refund of registration fee will not be entertained in any case.",
        "BIB Expo: The BIB Expo will take place one day prior to the event at the designated venue. There will be no BIB Expo on the event day.",
        "BIB Collection on Behalf of Others: If someone is collecting BIBs on behalf of other participants, they must present a consent letter from the registered participants in order to receive the BIB.",
        "No On-Spot Registration: On-spot registrations will not be available. Participants must register in advance to secure their participation.",
        "BIB Exchange: Participants are not allowed to exchange their timing BIBs with others. Each BIB is unique and should only be used by the assigned participant. Any participant found wearing another participant's BIB will be disqualified.",
        "Proper Placement of BIB: Participants must wear their BIB on their chest, ensuring it is visible. Wearing the BIB on the back will result in disqualification from the winner's list.",
        "Email Updates: All event-related updates will be shared via the registered email address & RF Whatsapp Groups.",
      ],
    },
    {
      header: "General",
      text: [
        "Use only the application modes suggested on this website or social media to submit your entries.",
        "Please thoroughly go through all relevant information given on the event website or social media and application form prior to submitting your application.",
        "Requests for participation cancellation, and thereby refund, will not be entertained.",
        "The organisers reserve the right to ban participants if they are found abusive and behave rudely with the organisers/volunteers/vendors/third party contractors during the event.",
        "All issues related to legal will be Subject to Surat Jurisdiction.",
      ],
    },
    {
      header: "Entries",
      text: [
        "Only individual entries for Half Marathon, 21K 10K 5K and 4K shall be accepted.",
        "Applicants for Half Marathon: Applicants for 21K must be the age of 18 years as on 15th November 2024; Applicants for 10K must be the age of 18 years as on 15th November 2024. Applicants for 5K must be the age of 16 years as on 15th November 2024. Applicants for 5K (Senior Citizen) must be the age of 60 years as on 15th November 2024. Applicants for 4K must be the age of below 16 years as on 15th November 2024.",
      ],
    },
    {
      header: "Entry Confirmations",
      text: [
        "Confirmation of applications received will be subject to Entry Rules & Guidelines given herein.",
        "Post close of registrations, email confirmations and running number [BIB / Chest Number] will be sent to all the confirmed participants who have given their valid email id in the registration form.",
      ],
    },
    {
      header: "Registration Status",
      text: [
        "On registration being successfully submitted, registrants will receive an email, WhatsApp and SMS, confirming their registration with a unique booking ID.",
        <p className="Runners will receive an email and SMS with their BIB number and other details closer to the race day. Those wishing to confirm their registration status in the interim can send us an email to">
          Runners will receive an email and SMS with their BIB number and other
          details closer to the race day. Those wishing to confirm their
          registration status in the interim can send us an email to{" "}
          <a href="mailto:rauniyarfoundation@gmail.com" target="_blank">
            rauniyarfoundation@gmail.com
          </a>
          & &nbsp;
          <a href="https://rauniyarfoundationbharat.org/" target="_blank">
            info@rauniyarfoundationbharat.org
          </a>
        </p>,
      ],
    },
    {
      header: "Race Regulation",
      text: [
        "You are to participate only in the race category for which you had applied and for which your entry has been confirmed. Your entry and running number bib and/or bib tag is not transferable to any other person under any circumstance.",
        "Runners found to have interchanged their running number bib and/or bib tag with another individual, will be disqualified from the Event and shall not be allowed to apply or participate in the upcoming [2024-25] edition of any of our events across country. ",
        "The Promoter reserves the right to disqualify or exclude any person from the event/competition who gives incorrect personal data/details on the entry form, who has been banned from competition under IAAF jurisdiction, or who is suspected of having taken banned substances. No refund of entry fee will be made.",
        "No result or certificate will be given to runners who do not start their run at the respective start times of the race categories they have been confirmed in.",
        "Please note if you tamper with the bib tag in any way or remove/exchange it with another runner, you won't be able to get an official finish time and thereby the official timing certificate recording your finish times. No Chip, No Timing, No Prize Money.",
        "Participants are not allowed to run with pets, unregistered runners or babies/children who are under aged for the designated category.",
        "Participants will not be allowed to enter the event venue with gas balloons.",
        "In order to ensure the re-opening of roads by the mentioned time (Or as decided by the event organisers), the organizers will designate cut off times at specified locations. Runners failing to reach these specific locations within times specified must stick to the footpath in order to complete your race.",
        "Only those confirmed participants wearing the current Half Marathon running number bibs will be allowed on the route. Any person found wearing the incorrect bib/no bib will be disqualified and removed by security.",
        "Further, every runner must wear his/her running number bib on the front of the vest. Any mutilation, fold, alteration or damage to the bib will amount to disqualification of the runner from the Event.",
        "Organizers reserve the right to stop any participant from participating who is found medically unfit to continue in the opinion of the medical personnel.",
        "Baggage counters are available for all the participants. For security reasons, participants are requested to come, as far as possible, without any baggage. It is strongly recommended that you do not leave valuables like mobile phones, cameras, jewellery, watches, electronic gadgets, etc. in the bag stored. Organizers are not responsible for any loss of baggage and belongings.",
        "Participants are requested to co-operate with the volunteers, police and security personnel deputed at the event venues and on route. Participants/supporters are liable to random checking on race day this is for your own security.",
        "Photographs taken of runners during any Half Marathon event organised prior to and on race day can be used for the purpose of promoting the event.",
      ],
    },
    {
      header: "Winners",
      text: [
        "On race final day, a victory ceremony will be conducted for the winners and trophies will be presented to them.",
        "The provisional results will be announced on our website and social media pages on race day, and the final results will be announced post verification 7-10 days after race day on the website or social media.",
        "The prize money will be given 60 days from the finish of the event, provided the winners have a bank account in India. The organizers will get in touch with the winners across all categories 30 days post-race day to collect their bank account details.",
      ],
    },
    {
      header: "Finishers Medal",
      text: [
        "Finishers across all the race categories who finish their run within the specified cut-off timings for their respective categories will receive get a Finisher's Medal post their run.",
      ],
    },
    {
      header: "Timing Certificate",
      text: [
        "E-timing certificates of the Marathon, Half Marathon (21 KM) and 10 KM finishers will be available for download from the event website or social media within 5 weeks from race day.",
        "Prize Money will be updated closer to the event.",
        "Route map will be updated closer to the race day.",
      ],
    },
  ];
  return (
    <>
      <section className="moa-sec">
        <div className="container">
          <div className="mt-5">
            <div className="half-merathone-list">
              <div className="half-merathone-list-header d-flex gap-3 align-items-center w-100">
                <div className="half-marathon-img">
                  <img
                    src={require("../../assetes/img/Hanumanji-PNG.png")}
                    alt=""
                    className="w-100"
                  />
                </div>
                <div className="chairman-title  d-flex align-items-center px-2 py-1">
                  <h1 className="main-text-vicechairman-title">
                    About The Rauniyar Foundation Bharat Unemployment <br />{" "}
                    Awareness Half Marathon Run 2024 1st Edition!
                  </h1>
                </div>
              </div>
              <div className="half-mearthone-header-text mt-4">
                <p className="half-mearahon-dec list-table-one">
                  Sitamarhi district, known as the “Birth place of goddess Sita”
                  is a district where the past meets the present. Rauniyar
                  Foundation Bharat is proud to present the 1st Edition of the
                  Rauniyar Foundation Bharat Unemployment Awareness Half
                  Marathon Run 2024, powered by Rauniyar Lifestyle Private
                  Limited in this culturally, historically and politically
                  decorated district. With an exciting running route that starts
                  and ends at the main road and takes you through the historical
                  landmarks of the district, the event promises to be an
                  unmatched running experience for all professional and amateur
                  runners alike.
                </p>
                <p className="half-mearahon-dec list-table-one">
                  Whether you are a seasoned jogger who wants to work toward a
                  new goal, or a novice runner who is inspired by a friend's
                  accomplishment of winning a race, there are many personal
                  reasons as to why you would want to train for and run in a
                  marathon.
                </p>
                <p className="half-mearahon-dec list-table-one">
                  Sure, there are plenty of excuses not to run a{" "}
                  <span className="fw-bold"> 4 KM 5KM 10KM 21KM </span> race.
                  You may tell yourself it's too hard and too far. You may be
                  scared of getting injured and don’t want to lose toenails,
                  deal with swollen feet, or experience constant soreness, so
                  you talk yourself out of realizing your marathon dreams. But
                  somewhere deep down you always go back to wondering what it
                  would be like to put the effort into training for a marathon.
                </p>
                <p className="half-mearahon-dec list-table-one">
                  Although running a marathon can be a scary and painful
                  endeavour, training for and running a race can offer a number
                  of benefits for the body, mind, and soul. In fact, there are
                  many reasons why you should run. Below are many reasons, one
                  for every mile you’ll run in a marathon, for why you should
                  start training now.
                </p>
                <div className="half-merathone-list-header">
                  <p className="half-merathone-list-title list-table-one fw-bold h5">
                    &nbsp; &nbsp; Benefits for Your Physical Health
                  </p>
                </div>
                <div className="half-mearthone-header-text mt-4">
                  <p className="list-table-one">
                    Training for a marathon has many physical health benefits
                    for the body.
                  </p>
                  <ul className="half-marathone-list list-table-one">
                    <li>
                      <p>
                        Improved overall health: Running regularly strengthens
                        your heart by increasing your VO2 max (aerobic
                        capacity) and helping keep your blood pressure and high
                        cholesterol at healthy levels. It also improves your
                        immune system and increases muscle strength. In
                        addition, longer runs push your body to strengthen
                        fast-twitch fibres in the muscles to combat fatigue and
                        build strength and power.
                      </p>
                    </li>
                    <li>
                      <p>
                        Better sleep: Marathon training can be exhausting. Your
                        body will crave sleep, since it repairs itself while
                        you’re resting. You’ll find yourself getting to bed
                        earlier and sleeping more soundly.
                      </p>
                    </li>
                    <li>
                      <p>
                        Toned legs: Weight loss is often a goal of
                        marathoners-in-training but, as many discover,
                        it doesn’t always happen. Although you may not lose a
                        lot of weight, logging all those miles will absolutely
                        increase your lean muscle mass and tone your legs.
                      </p>
                    </li>
                    <li>
                      <p>
                        Guilt-free massages: Marathoners-in-training tend to get
                        tight muscles, and regular massages can help you feel
                        comfortable and stay injury-free. Treat yourself to a
                        massage or two during your training to help relieve some
                        of that tightness.
                      </p>
                    </li>
                    <li>
                      <p>
                        Room for extra calories: During your very long runs,
                        you’ll be burning thousands of calories, so it’s OK to
                        be a little indulgent after. You will be hungry, after
                        all. You can run hard, and celebrate hard.
                      </p>
                    </li>
                  </ul>
                  <p className="list-table-one">
                    It’s important that you don’t assume that marathon training
                    gives you a license to eat whatever you want, whenever you
                    want. Running and weight loss don't always go hand in hand,
                    so unless you're taking in fewer calories than you expend,
                    you will not see a change on the scale. Pay careful
                    attention to what you eat while training for a marathon to
                    ensure you are eating for optimal performance.
                  </p>
                </div>
                <div className="half-mearthone-header-text mt-4">
                  <div className="half-merathone-list-header">
                    <p className="half-merathone-list-title list-table-one fw-bold h5">
                      &nbsp; Benefits for Your Mental Health
                    </p>
                  </div>
                  <p className="list-table-one">
                    Long runs play a big role in helping you hone your mental
                    toughness and develop a coping system to deal with running
                    day-after-day, mile-after-mile, in tough conditions. Running
                    a marathon will also help you reap the following benefits.
                  </p>
                  <ul className="half-marathone-list list-table-one">
                    <li>
                      <p>
                        Stress reduction: Research suggests that running in
                        times of stress helps you better handle life's
                        challenges by increasing your mental resilience.
                      </p>
                    </li>
                    <li>
                      <p>
                        A new outlook: This well-known maxim says it all: “The
                        person who starts a marathon is not the same person who
                        finishes a marathon.” You are sure to be a different
                        person at the finish line because you’ll forever know
                        that you have the mental and physical strength to
                        persevere, even during times when you think you can’t
                        and won’t succeed.
                      </p>
                    </li>
                    <li>
                      <p>
                        Inspiration: Whether it’s the double-amputee wheelchair
                        racer, the 73-year-old grandmother running her 35th
                        marathon, or your own kids holding “We love you!” signs,
                        you'll find some inspiration around every turn.
                      </p>
                    </li>
                    <li>
                      <p>
                        Improved motivation: You can’t get away with not
                        training when it comes to a marathon, so having one on
                        your calendar will keep you motivated to stick to your
                        training schedule. On days when your motivation is
                        lacking, you'll think about how you'll feel if you're
                        undertrained, can’t finish, or have to cancel and find
                        the strength train anyway.
                      </p>
                    </li>
                  </ul>
                  <p className="list-table-one">
                    Research shows that running may help alleviate symptoms of
                    mood and anxiety disorders. Regular exercise, such as
                    running, was found to be moderately more effective than no
                    therapy for reducing depressive symptoms.
                  </p>
                </div>
                <div className="half-mearthone-header-text mt-4">
                  <div className="half-merathone-list-header">
                    <p className="half-merathone-list-title list-table-one fw-bold h5">
                      &nbsp; Benefits for Your Soul
                    </p>
                  </div>
                  <p className="list-table-one">
                    Running a marathon isn't just good for your body and mind;
                    it can also help feed your soul.
                  </p>
                  <ul className="half-marathone-list list-table-one">
                    <li>
                      <p>
                        Support for a good cause: Many marathons benefit
                        charities and worthwhile causes, from disaster relief to
                        fighting cancer. Running for something that's bigger
                        than you is a great way to stay motivated to keep
                        training, meet other runners to train with, and make
                        your training and races even more meaningful.
                      </p>
                    </li>
                    <li>
                      <p>
                        New friends: Joining a running group is a great way to
                        meet new, like-minded people. In fact, many runners meet
                        their best friends or even future partners
                        through running groups.
                      </p>
                    </li>
                    <li>
                      <p>
                        Stronger relationships: You can also convince a friend
                        or family member to train for a marathon with
                        you. Training together and traveling to the race is a
                        fun way to bond and spend time together.
                      </p>
                    </li>
                    <li>
                      <p>
                        Being a role model: It’s hard not to respect someone who
                        is dedicated and determined to complete a substantial
                        goal such as running a marathon. You’ll be setting a
                        great example for many people in your life.
                      </p>
                    </li>
                    <li>
                      <p>
                        Gestures of love: It seems like a small thing, but
                        seeing your friends and family on the side-lines,
                        holding a funny or inspiring sign made just for you, is
                        an entirely different and awesome feeling. Running a
                        marathon is definitely a sign-worthy endeavour.
                      </p>
                    </li>
                  </ul>
                  <p className="list-table-one">
                    When running for a charity, choose a cause that matters to
                    you. When you choose a cause has personal significance, you
                    are far more likely to be invested in the sometimes
                    exhausting fundraising process. Set small, incremental
                    goals, enlist the help of friends and family, and start
                    early.
                  </p>
                </div>
                <div className="half-mearthone-header-text mt-4">
                  <div className="half-merathone-list-header">
                    <p className="half-merathone-list-title list-table-one fw-bold h5">
                      &nbsp; Benefits for Your Personal Development
                    </p>
                  </div>
                  <p className="list-table-one">
                    Sign up for a marathon and you will instantly feel a sense
                    of accomplishment. Start training for said marathon and you
                    will find yourself experiencing changing and growing in many
                    ways.
                  </p>
                  <ul className="half-marathone-list list-table-one">
                    <li>
                      <p>
                        Staying busy: Training for a marathon takes up a lot of
                        time. If you’ve gone through a recent life change that
                        gives you some more free time, like retirement, a
                        break-up, a job loss, or a newly empty nest, now might
                        be the perfect time to take on the marathon challenge.
                      </p>
                    </li>
                    <li>
                      <p>
                        A new community: If you’ve been a casual runner up until
                        this point, training for a marathon means that you won’t
                        be able to avoid the runner’s subculture much
                        longer. You’ll find yourself speaking in running
                        acronyms and talking about running a lot.
                      </p>
                    </li>
                    <li>
                      <p>
                        Achieving a life-long goal: Many people have “run a
                        marathon” on their bucket list, but few actually achieve
                        it.
                      </p>
                    </li>
                    <li>
                      <p>
                        Proof you’re an athlete: OK, so proving
                        to naysayers that you can complete an incredible
                        physical achievement shouldn’t be the only reason you
                        decide to train for a marathon, but it is a nice little
                        bonus.
                      </p>
                    </li>
                    <li>
                      <p>
                        Confidence: Once you’ve logged a 20-miler, you’ll feel
                        like you can conquer the world (after a post-run nap, of
                        course). Your running confidence will surely overflow
                        into other areas of your life, such as work and
                        relationships.
                      </p>
                    </li>
                    <li>
                      <p>
                        Accomplishment. Although you’ll experience rough
                        patches during marathon training and during the race,
                        it’s all worth it once you cross that beautiful finish
                        line. You’ll feel proud knowing that you set a goal of
                        finishing a marathon and you followed through with it.
                      </p>
                    </li>
                  </ul>
                  <p className="list-table-one">
                    Even a bad run or race can be a teachable moment that forces
                    you to learn something new about yourself. Although a slow
                    or difficult run can be disheartening and frustrating, you
                    can move forward by figuring out what went wrong and then
                    hopping right back into your training. Down the road, it is
                    the struggles, obstacles, and bad runs that help you become
                    a better, more experienced runner.
                  </p>
                </div>
                <div className="half-mearthone-header-text mt-4">
                  <div className="half-merathone-list-header">
                    <p className="half-merathone-list-title  list-table-one fw-bold h5">
                      &nbsp; Fun Benefits
                    </p>
                  </div>
                  <p className="list-table-one">
                    Training for and running a marathon takes so much mental and
                    physical energy that it is sometimes easy to forget how much
                    fun you are having. But there is definitely a lot of fun to
                    be had in the overall experience.
                  </p>
                  <ul className="half-marathone-list list-table-one">
                    <li>
                      <p>
                        Travel: If you love to travel, marathon running is a
                        great excuse to visit a new city or country. You'll get
                        to see a lot of the local area in the Local discoveries:
                        If you typically stick to shorter distances for running
                        and racing, training for a marathon will force you to
                        find new places to run, since you'll be running a lot
                        and you’ll get bored with the same routes. Check
                        out MapMyRun.com or ask local runners for suggestions on
                        where to run.
                      </p>
                    </li>
                    <li>
                      <p>
                        Finisher's swag: Whether it's a medal, a shirt, or a
                        great finishing photo, you’ll get something that’s
                        recognition of your achievement. You may not wear it or
                        look at it all the time, but it'll be there when you
                        need a tangible reminder of your running prowess.
                      </p>
                    </li>
                    <li>
                      <p>
                        Bragging rights: Once you cross that finish line, you’re
                        a marathon finisher and will always be one. No one can
                        ever take that title away from you.
                      </p>
                    </li>
                    <li>
                      <p>
                        Race and may even be able to score discounted marathon
                        runner rates on hotel rooms.
                      </p>
                    </li>
                    <li>
                      <p>
                        New running clothes: You’ll be running a lot, so you
                        won’t be able to get away with one or two running
                        outfits, including running shorts, unless you want to be
                        doing laundry constantly. Picking up new running gear is
                        a great way to reward yourself for sticking to your
                        training.
                      </p>
                    </li>
                  </ul>
                  <p className="list-table-one">
                    Traveling to a new destination for a marathon can be a
                    special way to experience a city or country that you have
                    never been to before. But packing for a brand new city and a
                    marathon can be a daunting task. Do your homework about the
                    destination, check the weather, pack your favourite gels and
                    race fuels, and most important of all, start considering
                    what you need to pack the moment you sign up for that race.
                  </p>
                  <p className="list-table-one">
                    Many of the physiological and mental health benefits of
                    training for a marathon come as a result of a consistent
                    running schedule that includes long-distance running. If you
                    don't run that marathon you have been considering, you are
                    less likely to include the long runs in your training. As
                    always, be sure to first assess your fitness level before
                    you jump into training for a marathon.
                  </p>
                </div>
                {content.map((section, index) => (
                  <div key={index} className="half-mearthone-header-text mt-4">
                    <div className="half-merathone-list-header">
                      <p
                        className={`half-merathone-list-title list-table-one fw-bold h5 ${
                          index == 1 && "main-half-header"
                        }`}
                      >
                        &nbsp;{section.header}
                      </p>
                    </div>
                    {section.text.map((paragraph, i) => (
                      <p key={i} className="list-table-one">
                        {paragraph}
                      </p>
                    ))}
                  </div>
                ))}
                {contentOfList.map((section, index) => (
                  <div key={index} className="half-mearthone-header-text mt-4">
                    <div className="half-merathone-list-header">
                      <p
                        className={`half-merathone-list-title list-table-one fw-bold h5  main-half-header`}
                      >
                        &nbsp; {section.header}
                      </p>
                    </div>
                    <ul className="half-marathone-list list-table-one">
                      {section.text.map((paragraph, idx) => (
                        <li>
                          <p key={idx} className="list-table-one">
                            {paragraph}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
                <p className="list-table-one mt-5">
                  The Pride of Sitamadhi district, the perfect platform for all
                  the runners across the district to come together to celebrate
                  their running experience. Running in India has been growing by
                  leaps and bounds over the years. Sitamadhi district boasts of
                  having the largest runner’s community in India and many
                  Sitamadhi district runners across different age categories
                  have been winning at the major marathon events in the country.
                  In future Sitamadhi district will be referred to as the
                  fitness district of India. Sitamadhi district is all set for
                  the 1st edition of its annual district marathon, which will
                  take runners through important landmarks of the district. We
                  believe this event will go a long way in promoting running in
                  the district and encourage a fit and healthy lifestyle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HalfMerathon;
