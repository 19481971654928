import React, { useEffect, useRef, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ArrayObj from "../../Constant/arrayObj";
import axios from "axios";
import Loader from "../Loader";
import Pako from "pako";
import RegisterUser from "../RegisterUser";
import PratePolicyPopup from "../../pages/PratePolicyPopup";
import OpenTermsAndConditionPopup from "../../pages/OpenTermsAndConditionPopup";
import OurPartners from "../OurPartners";
import CreatableSelect from "react-select/creatable";
import { IoShirtOutline } from "react-icons/io5";
import { FaBottleWater, FaMedal, FaStethoscope, FaPlus } from "react-icons/fa6";
import { TbCertificate } from "react-icons/tb";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import { toast } from "react-toastify";
import "./dashboard.css";

const Dashboard = () => {
  let token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Half");
  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [validCheck, setValidCheck] = useState(false);
  const [validCheckTermsAndCondition, setValidCheckTermsAndCondition] =
    useState(false);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const [base64, setBase64] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [raceCategory, setraceCategory] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [mDistance, setMDistance] = useState("");
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [gender, setgender] = useState("");
  const [dob, setDob] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [tsize, setTsize] = useState("");
  const [PersonalDetails, setPersonalDetails] = useState("");
  const [MCountry, setMCountry] = useState("");
  const [mState, setMState] = useState("");
  const [city, setCity] = useState("");
  const [add, setAdd] = useState("");
  const [area, setArea] = useState("");
  const [pincode, setPincode] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [healthHistrory, setHealthHistrory] = useState("");
  const [relation, setRelation] = useState("");
  const [relationContact, setRelationContact] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [familyDoctorContact, setfamilyDoctorContact] = useState("");
  const [validDate, setValidDate] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [termsAndConditionPopup, setTermsAndConditionPopup] = useState(false);
  const [privatePolicyPopup, setPrivatePolicyPopup] = useState(false);
  const [sendPaymentData, setSendPaymentData] = useState(null);
  const [getPrice, setGetPrice] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const RAZORPAY_API_KEY = process.env.REACT_APP_RAZORPAY_KEY_ID;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState(null);
  const [other, setOther] = useState(null);

  const options = ArrayObj.schoolList.map((item) => ({
    value: item.name.toLowerCase(),
    label: item.name,
    other: item.is_other,
  }));

  const HandleAddSchool = (name) => {
    if (name) {
      setCollegeName(name.value);
      setOther(name?.other);
    }
  };
  useEffect(() => {
    GetCountries().then((result) => {
      let filteredCountries = result.filter(
        (item) => item.iso2 === "IN" || item.iso2 === "NP"
      );

      setSelectedCountry(filteredCountries);
    });

    const backdrops = document.querySelectorAll(".offcanvas-backdrop");

    if (backdrops) {
      backdrops?.forEach((backdrop) => backdrop?.remove());

      document.body.classList.remove("offcanvas-open", "overflow-hidden");
    }
  }, []);

  useEffect(() => {
    setValue("lastname", lName);
    setValue("pincod", pincode);
    setValue("cityOne", city);
    setValue("address", add);
    setValue("school", schoolName);  
    
  }, [lName, pincode, city, add, schoolName, setValue]);

  useEffect(() => {
    if (lName) {
      clearErrors("lastname");
    }
    if (pincode) {
      clearErrors("pincod");
    }
    if (city) {
      clearErrors("cityOne");
    }
    if (add) clearErrors("address");
    if (schoolName) clearErrors("schoolName");
  }, [lName, pincode, city, add, schoolName, clearErrors]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const marathonRegister = (mtype, item, mFees, id) => {
    const bgClass = id === 1 ? "bg-grey" : "bg-main";
    const btnClass = id === 1 ? "bg-grey-btn" : "bg-main-btn";

    return (
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className={`${bgClass} merathone-card shadow-lg rounded-3 h-100`}>
          <div className="row w-100">
            <div
              className="main-card-col col-lg-6 col-md-6 col-sm-12 px-3 py-3"
              style={{ height: "7rem" }}
            >
              <div className="category">
                <p className="category-mini fw-semibold mb-1 text-uppercase">
                  Race Category
                </p>
                <p className="category-name fw-semibold mb-1 text-uppercase">
                  {mtype}
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 px-3 py-3">
              <div className="category">
                <p className="category-mini fw-semibold mb-1 text-uppercase">
                  Charity Amount
                </p>
                <p className="category-name fw-semibold mb-1">
                  &#8377;&nbsp;{mFees}
                  &nbsp; INR
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 px-3 py-3">
              <div className="category">
                <p className="category-mini catagory-main fw-semibold mb-1 text-uppercase">
                  Runner's Kit
                </p>
                <div className="runnerkit-icons d-flex gap-2 align-items-center px-1">
                  <IoShirtOutline className="icon" />
                  <FaBottleWater className="icon" />
                  <FaMedal className="icon" />

                  <FaStethoscope className="icon" />
                  <TbCertificate className="icon" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="main-register-btn d-flex justify-content-center align-items-center h-100">
                <button
                  className={`${btnClass}  card-register-btn text-light border-0  fw-semibold rounded-2 d-flex justify-content-center align-items-center shadow-sm`}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={() => togleRegisterForm(item)}
                  disabled={id == 1}
                >
                  <div className="r-icon me-2">
                    <FaPlus />
                  </div>
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleClose = () => {
    setShow(false);
  };

  const togleRegisterForm = (item) => {
    if (show == true) {
      if (item.id == 1) {
        setShow(false);
      } else {
        setShow(true);
      }
    }
  };

  const handleCloseCanvas = () => {
    setShowCanvas(false);
    const backdrops = document.querySelectorAll(".offcanvas-backdrop");

    if (backdrops) {
      backdrops?.forEach((backdrop) => backdrop?.remove());

      document.body.classList.remove("offcanvas-open", "overflow-hidden");
    }
  };

  const handleRegistrationSuccess = () => {
    setShow(false);
    navigate("/");
  };

  const onSelectCountry = (data) => {    
    
    
    setCountryid(data.target.value);
    GetState(Number(data.target.value)).then((result) => {
      setStateList(result);
    });

    setMState(stateList);
    setMCountry(data.target.value);
  };

  const onSelectState = (data) => {
 
     
    setStateid(data.target.value);
    GetCity(Number(data.target.value)).then((result) => {
      setCityList(result);
    });
    setMState(data.target.value);
   
        
  };

  const uint8ArrayToBase64 = (uint8Array) => {
    const binaryString = Array.from(uint8Array)
      .map((byte) => String.fromCharCode(byte))
      .join("");
    return window.btoa(binaryString);
  };

  const onConvertImg = async (data) => {
    let compressedBase64String = "";

    const event = data;

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        compressedBase64String = compressBase64(base64String);
        setBase64(compressedBase64String);
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    }
  };

  const base64ToUint8Array = (base64) => {
    const binaryString = window.atob(base64.split(",")[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  };

  const compressBase64 = (base64String) => {
    const uint8Array = base64ToUint8Array(base64String);

    const compressed = Pako.deflate(uint8Array);

    return uint8ArrayToBase64(compressed);
  };

  const onSubmit = async (data) => {
    // Age validation logic
    const birthDate = new Date(data.dob);
    const refDate = new Date(new Date().getFullYear(), 10, 15);
    let age = refDate.getFullYear() - birthDate.getFullYear();
    const ageMonthDif = refDate.getMonth() - birthDate.getMonth();
    if (
      ageMonthDif < 0 ||
      (ageMonthDif === 0 && refDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setValidDate(age);

    const payload = {
      race_category: mDistance.slice(0, 5),
      distance: mDistance.toUpperCase(),
      first_name: fName.toUpperCase(),
      last_name: lName.toUpperCase(),
      gender: gender.toUpperCase(),
      dob: dob,
      email: email,
      tshirt_size: tsize,
      phonenumber: `${countryCode}${phone}`,
      school_name: collegeName == "other" ? schoolName.toUpperCase() : collegeName.toUpperCase(),
      identity_type: PersonalDetails,
      identity_proof_url: base64,
      country: MCountry === 154 ? "NEPAL" : "INDIA",
      state: mState.toUpperCase(),
      city: city.toUpperCase(),
      area: area.toUpperCase(),
      address: add.toUpperCase(),
      pin_code: pincode,
      blood_group: bloodGroup,
      health_issue: healthHistrory.toUpperCase(),
      emergency_contact: `${countryCode}${relationContact}`,
      contact_relation: relation.toUpperCase(),
      dr_contact: `${countryCode}${familyDoctorContact}`,
    };
   

    setLoader(true);
    axios
      .post(`${BASE_URL}/api/user/register/particiapnt`, payload, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const participantData = response.data.data;

        setSendPaymentData(participantData);

        handlePayment(participantData);

        setShow(false);
       
      })
      .catch(function (error) {
        toast.error("Something went wrong!");
        setLoader(false);
      })
      .finally(function () {
        setLoader(false);
      });

    setfName("");
    setlName("");
    setBloodGroup("");
    setfamilyDoctorContact("");
    setRelationContact("");
    setRelation("");
    setHealthHistrory("");
    setPincode("");
    setSchoolName("");
    setMDistance("");
    setgender("");
    setDob("");
    setemail("");
    setphone("");
    setTsize("");
    setPersonalDetails("");
    setMCountry("");
    setMState("");
    setCity("");
    setAdd("");
    setDoctorName("");
    setCollegeName("");
    setValidCheckTermsAndCondition(false);
    setValidCheck(false);
    reset();
    const backdrops = document.querySelectorAll(".offcanvas-backdrop");

    if (backdrops) {
      backdrops?.forEach((backdrop) => backdrop?.remove());

      document.body.classList.remove("offcanvas-open", "overflow-hidden");
    }
  };

  const onChangeMarathonPrice = (e) => {
    const data = JSON.parse(e.target.value);

    setMDistance(data.fRace);
    setGetPrice(data.price);
  };

  const handlePayment = async (participantData) => {
    try {
      const orderUrl = `${BASE_URL}/api/user/order/create`;
      const { data } = await axios.post(
        orderUrl,
        {
          amount: getPrice,
          race_id: "",
        },
        {
          headers: {
            authorization: token,
          },
        }
      );

      setLoader(false);

      const options = {
        key: RAZORPAY_API_KEY,
        amount: getPrice,
        currency: data.currency,
        name: "RAUNIYAR FOUNDATIN BHARAT",
        description: "MARTHON RAGISTRATION CHARITY AMOUNT",
        order_id: data?.data,
        handler: function (response) {
          verifyPayment(response, participantData?._id);
          setLoader(true);
        },
        prefill: {
          name: `${participantData?.first_name} ${participantData?.last_name}`,
          email: participantData?.email,
          contact: participantData?.phonenumber,
          participant_id: participantData?._id,
        },
        theme: {
          color: "#e41c3c",
        },
      };

      const paymentObject = new window.Razorpay(options, participantData?._id);
      const response = paymentObject.open();
    } catch (error) {
      console.error("Error while creating order:", error);
    }
  };

  const verifyPayment = (data, participant_id) => {
    const payload = {
      order_id: data.razorpay_order_id,
      payment_id: data.razorpay_payment_id,
      signature: data.razorpay_signature,
      participant_id: participant_id,
      amount: getPrice,
    };

    axios
      .post(`${BASE_URL}/api/user/order/payment/verify`, payload, {
        headers: {
          authorization: token,
        },
      })
      .then(function (response) {
        setTimeout(() => {
          toast.success("Payment successful!");
          
        }, 2000);
        toast.success("Registration successful!");
        setLoader(false);
        navigate("/");
        setShowPopup(true);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        navigate("/");
      })
      .finally(function () {
        setLoader(false);
      });
  };
  const hideRegisterForm = (data) => {
    if (data == false) {
      setShow(data);
    }
  };

  return (
    <>
      {loader == true ? (
        <Loader />
      ) : (
        <>
          <section className="mearathon mb-5">
            <div className="container">
              <div className="row w-100 gx-3 gy-4">
                <div className="col-md-5 order-md-2">
                  <div className="mearathon-map">
                    <div className="d-flex align-items-center flex-column w-100">
                      <div className="merathone-maplist w-100">
                        <p className="map-title">View Route Maps</p>
                        <div className="row g-0 w-100 marathone-map-type w-100">
                          <ul className="pagination p-2 rounded-pill">
                            <li
                              className={`page-item rounded-pill overflow-hidden ms-1 ${
                                activeTab === "Full" ? "tab-active" : ""
                              }`}
                            >
                              <button
                                className="marathn-map-tab first-menu fw-semibold bg-secondary-emphasis text-uppercase"
                                // onClick={() => handleTabClick("Full")}
                              >
                                42 km
                              </button>
                            </li>
                            <li
                              className={`page-item rounded-pill overflow-hidden ms-1 ${
                                activeTab === "Half" ? "tab-active" : ""
                              }`}
                            >
                              <button
                                className="marathn-map-tab fw-semibold text-uppercase"
                                onClick={() => handleTabClick("Half")}
                              >
                                21 km
                              </button>
                            </li>
                            <li
                              className={`page-item rounded-pill overflow-hidden ms-1 ${
                                activeTab === "10K" ? "tab-active" : ""
                              }`}
                            >
                              <button
                                className="marathn-map-tab fw-semibold text-uppercase"
                                onClick={() => handleTabClick("10K")}
                              >
                                10 Km
                              </button>
                            </li>
                            <li
                              className={`page-item rounded-pill overflow-hidden ms-1 ${
                                activeTab === "5K" ? "tab-active" : ""
                              }`}
                            >
                              <button
                                className="marathn-map-tab fw-semibold text-uppercase"
                                onClick={() => handleTabClick("5K")}
                              >
                                5 Km
                              </button>
                            </li>
                            <li
                              className={`page-item rounded-pill overflow-hidden ms-1 ${
                                activeTab === "4K" ? "tab-active" : ""
                              }`}
                            >
                              <button
                                className="marathn-map-tab fw-semibold text-uppercase"
                                onClick={() => handleTabClick("4K")}
                              >
                                4 Km
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="main-video">
                        <div className="mahostav-video p-3 w-100">
                          <div className="video-mahostav overflow-hidden rounded-2">
                            <video
                              className="w-100 h-100 object-fit-cover"
                              poster={require("../../assetes/img/header-logo.jpg")}
                              controls
                            >
                              <source
                                src={require("../../assetes/video/main_page_video.mov")}
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="merathon-detail">
                    <div className="merathone-header d-flex flex-column align-items-center">
                      <div className="hanuman-img mb-3">
                        <img
                          src={require("../../assetes/img/Hanumanji-PNG.png")}
                          alt=""
                          className="w-100"
                        />
                      </div>
                    </div>
                    <div className="register-dec">
                      <Tabs
                        defaultActiveKey="Regular"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab
                          eventKey="Regular"
                          className="show tab-title"
                          title=" About The Rauniyar Foundation Bharat Unemployment Awareness Half Marathon Run 2024 1st Edition!"
                        >
                          <p
                            className={`regular-dec main-text-chairman ${
                              showMore ? "show-all" : "show-less"
                            }`}
                          >
                            Sitamarhi district, known as the “Birth place of
                            goddess Sita” is a district where the past meets the
                            present. Rauniyar Foundation Bharat is proud to
                            present the 1st Edition of the Rauniyar Foundation
                            Bharat Unemployment Awareness Half Marathon Run
                            2024, powered by Rauniyar Lifestyle Private Limited
                            in this culturally, historically and politically
                            decorated district. With an exciting running route
                            that starts and ends at the main road and takes you
                            through the historical landmarks of the district,
                            the event promises to be an unmatched running
                            experience for all professional and amateur runners
                            alike. Whether you are a seasoned jogger who wants
                            to work toward a new goal, or a novice runner who is
                            inspired by a friend's accomplishment of winning a
                            race, there are many personal reasons as to why you
                            would want to train for and run in a marathon. Sure,
                            there are plenty of excuses not to run a 4 KM 5KM
                            10KM 21KM race. You may tell yourself it's too hard
                            and too far. You may be scared of getting injured
                            and don’t want to lose toenails, deal with swollen
                            feet, or experience constant soreness, so you talk
                            yourself out of realizing your marathon dreams. But
                            somewhere deep down you always go back to wondering
                            what it would be like to put the effort into
                            training for a marathon. Although running a marathon
                            can be a scary and painful endeavour, training for
                            and running a race can offer a number of benefits
                            for the body, mind, and soul. In fact, there are
                            many reasons why you should run.
                          </p>
                          <p className="text-center">
                            <button
                              onClick={toggleShowMore}
                              className="show-more-btn"
                            >
                              {showMore ? "Show Less" : "Show More..."}
                            </button>
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
              <div className="finle-date d-flex align-items-center w-100">
                <div className="d-flex align-items-center w-100" style={{backgroundColor:'#e41c3c',color:'#fff',padding:'8px 10px'}}>
                    <marquee behavior="smooth" scrollamount="15" direction="left"><p className="reminder_msg fw-semibold text-light mb-0">Reminder : The last date to register for the marathon is 15 Nov 2024.</p></marquee>
                </div>
              </div>
              <div className="register-card mt-5">
                <div className="register-first-card row gy-3 gx-4 h-100">
                  {ArrayObj.marathonRegisterData.map((item, index) => {
                    return marathonRegister(
                      item.mtype,
                      item,
                      item.mFees,
                      item.id
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <div
            className={`offcanvas offcanvas-end ${show && "show"}`}
            tabIndex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header px-3 py-3">
              <h5 id="offcanvasRightLabel" className="offcanvas-title">
                {token ? "Participant Details" : "Register"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="offcanvas-body-header">
                {token ? (
                  <>
                    <p className="offcanvas-body-title  mb-2">
                      Add Participant
                    </p>
                    <p className="offcanvas-body-dec">
                      Enter each participant's details. If you are an existing
                      user, your information will be pre-filled.
                    </p>
                    <div className="merathone-details fw-semibold mb-2">
                      Marathon Details
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              {token ? (
                <>
                  <div className="row w-100 gx-3 gy-4">
                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Race Category
                      </label>
                      <select
                        name=""
                        id=""
                        className="race-category"
                        value={raceCategory}
                        onChange={(e) => setraceCategory(e.target.value)}
                      >
                        <option value="Chairity" selected>
                          Chairity
                        </option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Marathon Distance*
                      </label>
                      <select
                        {...register("distance", {
                          required: "Marathon Distance is required",
                        })}
                        className="race-category"
                        defaultValue={mDistance}
                        onChange={(e) => onChangeMarathonPrice(e)}
                      >
                        <option value="" disabled selected>
                          Select Distance
                        </option>
                        {ArrayObj?.raceType?.map((item, index) => (
                          <option value={JSON.stringify(item)} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </select>

                      {errors.distance && (
                        <p className="invalid">{errors.distance.message}</p>
                      )}
                    </div>
                    <div className="merathone-details fw-semibold mb-2">
                      Personal Details
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        First Name*
                      </label>
                      <input
                        {...register("first_name", {
                          required: true,
                          maxLength: 20,
                          pattern: /^[A-Za-z]+$/i,
                        })}
                        type="text"
                        className="race-category"
                        placeholder="Enter Name"
                        value={fName}
                        onChange={(e) => setfName(e.target.value)}
                      />
                      {errors?.first_name?.type === "required" && (
                        <p className="invalid">This field is required</p>
                      )}

                      {errors?.first_name?.type === "pattern" && (
                        <p className="invalid">Alphabetical characters only</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Last Name*
                      </label>
                      <input
                        {...register("lastname", {
                          required: true,
                          maxLength: 20,
                          pattern: /^[A-Za-z]+$/i,
                        })}
                        type="text"
                        className="race-category"
                        placeholder="Enter last name"
                        defaultValue={lName}
                        onChange={(e) => setlName(e.target.value)}
                      />
                      {errors.lastname && (
                        <p className="invalid">{errors.lastname.message}</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Gender*
                      </label>
                      <select
                        {...register("gender", {
                          required: "Select your Gender",
                        })}
                        className="race-category"
                        value={gender}
                        onChange={(e) => setgender(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      {errors.gender && (
                        <p className="invalid">{errors.gender.message}</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label
                        htmlFor="dob"
                        className="race-details fw-bold mb-2"
                      >
                        Date of Birth*
                      </label>
                      <input
                        {...register("dob", {
                          required: "Date of Birth is required",
                          pattern: {
                            value: /^\d{4}-\d{2}-\d{2}$/,
                            message: "Enter a valid date in YYYY-MM-DD format",
                          },
                        })}
                        type="date"
                        pattern="mm/dd/yyyy"
                        className="race-category"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                      {errors.dob && (
                        <p className="invalid">{errors.dob.message}</p>
                      )}

                      {validDate !== null && validDate < 18 && (
                        <p className="invalid">
                          You are not eligible for the race
                        </p>
                      )}
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Email Address*
                      </label>
                      <input
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Enter a valid email address",
                          },
                        })}
                        type="email"
                        className="race-category"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                      {errors.email && (
                        <p className="invalid">{errors.email.message}</p>
                      )}
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        School / College Name*
                      </label>

                      <CreatableSelect
                        isClearable
                        options={options}
                        onChange={HandleAddSchool}
                        className="school-select"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid gray",
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          colors: {
                            ...theme.colors,
                            primary: "gray",
                          },
                        })}
                      />
                    </div>

                    {collegeName == "other" && (
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="race-category"
                          placeholder="Enter school name"
                          value={schoolName}
                          onChange={(e) => setSchoolName(e.target.value)}
                        />
                      </div>
                    )}

                    <div className="col-md-8">
                      <label
                        htmlFor="phone"
                        className="race-details fw-bold mb-2"
                      >
                        Mobile no.*
                      </label>
                      <div className="race-mobile rounded-3 d-flex align-items-center gap-2">
                        <select
                          className="country-drop"
                          onChange={(e) => setCountryCode(e.target.value)}
                        >
                          <option value="+91">+91 (INA)</option>
                          <option value="+977">+977 (NP)</option>
                        </select>
                        <input
                          {...register("phonenumber", {
                            required: "Mobile number is required",
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Numeric characters only",
                            },
                          })}
                          type="tel"
                          className="race-category"
                          placeholder="Enter Mobile Number"
                          value={phone}
                          onChange={(e) => setphone(e.target.value)}
                          maxLength={10}
                        />
                      </div>
                      {errors.phonenumber && (
                        <p className="invalid">{errors.phonenumber.message}</p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        T-Shirt Size*
                      </label>
                      <select
                        {...register("tshirt_size", {
                          required: "T-Shirt Size is required",
                        })}
                        className="race-category"
                        value={tsize}
                        onChange={(e) => setTsize(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Select your T-shirt size
                        </option>
                        {ArrayObj.tshirtSize.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      {errors.tshirt_size && (
                        <p className="invalid">{errors.tshirt_size.message}</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Identity Proof Type*
                      </label>
                      <select
                        {...register("identity_type", {
                          required: "Identity Proof Type is required",
                        })}
                        className="race-category"
                        value={PersonalDetails}
                        onChange={(e) => setPersonalDetails(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Select Identity Proof Type*
                        </option>
                        {ArrayObj.personalDetails.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      {errors.identity_type && (
                        <p className="invalid">
                          {errors.identity_type.message}
                        </p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Select{" "}
                        {PersonalDetails == "" ? "Id Card" : PersonalDetails}*
                      </label>
                      <input
                        {...register("identity_proof_url", {
                          required: "ID Card is required",
                        })}
                        type="file"
                        className="race-category"
                        onChange={(e) => onConvertImg(e)}
                      />
                      {errors.identity_proof_url && (
                        <p className="invalid">
                          {errors.identity_proof_url.message}
                        </p>
                      )}
                    </div>

                    <div className="merathone-details fw-semibold mb-2">
                      Residential Details
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Country*
                      </label>
                      <select
                        className="race-category"
                        {...register("country", {
                          required: "Country is required",
                        })}
                        onChange={(e) => onSelectCountry(e)}
                      >
                        <option value="Select Country" disabled selected>
                          Select Country
                        </option>
                        {selectedCountry.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.country && (
                        <p className="invalid">{errors.country.message}</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        State*
                      </label>
                      <select
                        className="race-category"
                        {...register("state", {
                          required: "state is required",
                        })}
                        onChange={(e) => onSelectState(e)}
                      >
                        <option value="Select State" selected disabled>
                          Select State
                        </option>
                        {stateList.map((item, index) => (
                          <option
                            value={item?.name}
                            key={index}
                            className="text-dark"
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {errors.state && (
                        <p className="invalid">{errors.state.message}</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        City*
                      </label>

                      <input
                        type="text"
                        placeholder="Enter City"
                        className="race-category"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      {errors.city && (
                        <p className="invalid">{errors.city.message}</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label
                        htmlFor="area"
                        className="race-details fw-bold mb-2"
                      >
                        Area*
                      </label>
                      <input
                        id="area"
                        type="text"
                        className="race-category"
                        placeholder="Enter Area"
                        {...register("area", {
                          required: "Area is required",
                        })}
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                      />
                      {errors.area && (
                        <p className="invalid">{errors.area.message}</p>
                      )}
                    </div>

                    <div className="col-md-8">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Address*
                      </label>
                      <textarea
                        {...register("address", {
                          required: "Address is required",
                        })}
                        name=""
                        id=""
                        rows={3}
                        className="race-details fw-semibold mb-2 w-100 text-area-add"
                        value={add}
                        onChange={(e) => setAdd(e.target.value)}
                      ></textarea>
                      {errors.address && (
                        <p className="invalid">{errors.address.message}</p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Pin code*
                      </label>
                      <input
                        id="pin_code"
                        type="tel"
                        className="race-category"
                        placeholder="Enter Pin code"
                        inputMode="numeric"
                        {...register("pincod", {
                          required: "Pin code is required",
                        })}
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                      {errors.pincod && (
                        <p className="invalid">{errors.pincod.message}</p>
                      )}
                    </div>

                    <div className="merathone-details fw-semibold mb-2">
                      Health History
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Blood Group*
                      </label>
                      <select
                        {...register("blood_group", {
                          required: "Select your Blood Group",
                        })}
                        className="race-category"
                        value={bloodGroup}
                        onChange={(e) => setBloodGroup(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Select Blood Group
                        </option>
                        {ArrayObj.bloodGroup.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      {errors.blood_group && (
                        <p className="invalid">{errors.blood_group.message}</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        History of Health Issues*
                      </label>
                      <select
                        {...register("health_issue", {
                          required: "Select your Health History",
                        })}
                        className="race-category"
                        value={healthHistrory}
                        onChange={(e) => setHealthHistrory(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Select Health History
                        </option>
                        {ArrayObj.Historus.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      {errors.health_issue?.message ||
                        (healthHistrory && healthHistrory !== "None" && (
                          <p className="invalid">
                            {"You are not eligible for the race"}
                          </p>
                        ))}
                    </div>

                    <div className="merathone-details fw-semibold mb-2">
                      Emergency Contact Details
                    </div>

                    <div className="col-md-7">
                      <label
                        htmlFor="phone"
                        className="race-details fw-bold mb-2"
                      >
                        Mobile no.*
                      </label>
                      <div className="race-mobile rounded-3 d-flex align-items-center w-100 gap-2">
                        <select
                          className="country-drop"
                          onChange={(e) => setCountryCode(e.target.value)}
                        >
                          <option value="+91">+91 (INA)</option>
                          <option value="+977">+977 (NP)</option>
                        </select>
                        <input
                          {...register("emergency_contact", {
                            required: "Mobile number is required",
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Numeric characters only",
                            },
                          })}
                          type="tel"
                          className="race-category"
                          placeholder="Enter Mobile Number"
                          value={relationContact}
                          onChange={(e) => setRelationContact(e.target.value)}
                          maxLength={10}
                        />
                      </div>
                      {errors.emergency_contact && (
                        <p className="invalid">
                          {errors.emergency_contact.message}
                        </p>
                      )}
                    </div>

                    <div className="col-md-5">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Relation*
                      </label>
                      <select
                        {...register("contact_relation", {
                          required: "Select your relation",
                        })}
                        className="race-category"
                        value={relation}
                        onChange={(e) => setRelation(e.target.value)}
                      >
                        <option value="" disabled selected>
                          Select Relation
                        </option>
                        {ArrayObj.relation.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      {errors.contact_relation && (
                        <p className="invalid">
                          {errors.contact_relation.message}
                        </p>
                      )}
                    </div>
                    <div className="col-md-5">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Family Doctoer's Name
                      </label>
                      <input
                        className="race-category"
                        placeholder="Enter Doctor's name"
                        value={doctorName}
                        onChange={(e) => setDoctorName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-7">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Family Doctor's Number
                      </label>
                      <div className="race-mobile rounded-3 d-flex align-items-center w-100 gap-2">
                        <select
                          className="country-drop"
                          onChange={(e) => setCountryCode(e.target.value)}
                          style={{ width: "36%" }}
                        >
                          <option value="+91">+91 (INA)</option>
                          <option value="+977">+977 (NP)</option>
                        </select>
                        <input
                          type="tel"
                          className="race-category"
                          placeholder="Enter Doctor's Number"
                          value={familyDoctorContact}
                          onChange={(e) =>
                            setfamilyDoctorContact(e.target.value)
                          }
                          maxLength={10}
                        />
                      </div>
                    </div>

                    <div className="d-flex align-items-center w-100 gap-2 ">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="form-check-input"
                        value={validCheck}
                        onChange={(e) => setValidCheck(e.target.checked)}
                        required
                      />
                      <span className="check-text">
                        Consent to participate in the marathon
                      </span>
                    </div>
                    <div className="d-flex align-items-center w-100 gap-2 mt-2">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="form-check-input"
                        value={validCheckTermsAndCondition}
                        onChange={(e) =>
                          setValidCheckTermsAndCondition(e.target.checked)
                        }
                        required
                      />
                      <span className="check-text">
                        Agree to our &nbsp;
                        <Link
                          className="check-link"
                          target="_blank"
                          to={"/termsandcondition"}
                        >
                          Terms and Conditions{" "}
                        </Link>{" "}
                        and &nbsp;
                        <Link
                          className="check-link"
                          target="_blank"
                          to={"/privatepolicy"}
                        >
                          Privacy Policy
                        </Link>
                      </span>
                    </div>

                    <div className="d-flex align-items-center justify-content-center gap-2 w-100">
                      <div className="cancel-btn d-flex  align-items-center w-100">
                        <button
                          className="action-btn fw-semibold w-100"
                          data-bs-dismiss="offcanvas"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="submit-btn d-flex align-items-center w-100">
                        <button
                          className={`action-btn fw-semibold text-light w-100 ${
                            (!validCheck && !validCheckTermsAndCondition) ||
                            (validCheck && !validCheckTermsAndCondition) ||
                            (!validCheck && validCheckTermsAndCondition) ||
                            healthHistrory !== "None"
                              ? "disable"
                              : "submit"
                          }`}
                          onClick={handleSubmit(onSubmit)}
                          disabled={
                            (!validCheck && !validCheckTermsAndCondition) ||
                            (validCheck && !validCheckTermsAndCondition) ||
                            (!validCheck && validCheckTermsAndCondition) ||
                            healthHistrory !== "None"
                          }
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <RegisterUser
                  onClose={handleCloseCanvas}
                  onSuccess={handleRegistrationSuccess}
                  onSendData={hideRegisterForm}
                />
              )}
            </div>
          </div>
        </>
      )}

      {termsAndConditionPopup == true ? (
        <OpenTermsAndConditionPopup
          onClose={() => setTermsAndConditionPopup(false)}
        />
      ) : (
        <></>
      )}
      {privatePolicyPopup == true ? (
        <PratePolicyPopup onClose={() => setPrivatePolicyPopup(false)} />
      ) : (
        <></>
      )}
      <OurPartners />
      {showPopup ? (
        <div className="popup">
          <div className="popup_inner text-center">
            <div className="header">
              <p className="h3 fw-semibold">Thanks You 😊</p>
            </div>
            <hr />
            <p>Pleases Check your payment details on your register email..!</p>
            <button
              className="btn btn-primary m-auto my-3"
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboard;
