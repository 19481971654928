import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";

const NewsAndPr = () => {
  return (
    <>
      <section className="moa-sec">
        <div className="container">
          <div className="row align-items-start w-100">
            <div className="col-md-6">
              <div className="d-flex justify-content-center align-items-center w-100">
                <div className="news">
                  <div className="chairman-title d-flex align-items-center px-2 py-1 my-3">
                    <h1
                      className="main-text-vicechairman-title mb-0"
                      style={{ lineHeight: "30px" }}
                    >
                      PRESS CONFERENCE
                    </h1>
                  </div>
                  <p className="list-table-one">
                    हमारे बीच में आए हुए हमारे पत्रकार बंधुओं उनका एक बार फिर से
                    हार्दिक अभिनंदन। हमारी परंपरा रही है अतिथि सत्कार। एक दूसरे
                    का आदर सम्मान करना यह एक मानवीय मूल्य है जो हम लोग निभाते
                    रहें।
                  </p>
                  <div className="half-merathone-list-title">
                    <p className="list-table-one mt-5 mb-3">
                      Discussion about strategic event direction for{" "}
                    </p>
                  </div>
                  <ul className="mt-4" style={{ listStyle: "disc" }}>
                    <li>
                      <p className="list-table-one">
                        BAIRGANIA MAHOTSAV 2024 2d Edition
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one">
                        BAIRGANIA PREMIER LEAGUE 2024 1st Edition
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one">
                        UNEMPLOYMENT AWARENESS HALF MARATHON RUN 2024 1st
                        Edition
                      </p>
                    </li>
                  </ul>
                  <div className="d-flex align-items-center my-4">
                    <span>
                      <FaArrowRightLong className="me-2 mb-4" />
                    </span>
                    <p className="list-table-one mb-0">
                      <span className="fw-semibold">Date</span> : 18th October
                      2024 Venue : Target Tutorial Center,Baba Lal Das Math
                      Road, Bairgania, Sitamarhi-843 313 Bharat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="d-flex  align-items-center w-100 h-100 overflow-hidden">
                  <div className="mahostav-video p-3 w-100 h-100">
                    <div className="video-mahostav overflow-hidden rounded-2">
                      <video
                        className="w-100 h-100 object-fit-cover"
                        
                        controls
                      >
                        <source
                          src={require("../assetes/video/IMG_2497.MOV")}
                        />
                      </video>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsAndPr;
