import React, { useEffect, useRef, useState } from "react";
import { Modal as BSModal } from "bootstrap";
import { FaPlus } from "react-icons/fa";
import ArrayObj from "../../../Constant/arrayObj";
import ParticipentForm from "./ParticipentForm";
import "./bairganiyamahostav.css";
import chairity_school from "../../../assetes/video/mahostav-video.MOV";
import chairity_school_two from "../../../assetes/video/school_video.mov";
import chairity_school_three from "../../../assetes/video/coching.mov";

const BairganiaMahotsav = () => {
  const [openParticipentModal, setOpenParticipentModal] = useState(false);
  const mahostavRef = useRef(null);
  const modalRef = useRef(null);
  const [dance, setDance] = useState(false);

  useEffect(() => {
    if (openParticipentModal && modalRef.current) {
      const modalElement = modalRef.current;
      const bsModal = new BSModal(modalElement);
      bsModal.show();

      modalElement.addEventListener("hidden.bs.modal", () => {
        setOpenParticipentModal(false);
      });
    }
  }, [openParticipentModal]);

  const chairityEvent = [
    {
      video: chairity_school,
    },
    {
      video: chairity_school_two,
    },
    {
      video: chairity_school_three,
    },
  ];

  const onOpenRegister = (item) => {
    setOpenParticipentModal(true);
    if (item.id == 5) {
      setDance(true);
    } else {
      setDance(false);
    }
  };

  const EventCard = (eType, price, item, index) => {
    return (
      <>
        <div
          className="col-lg-4 col-md-6 col-sm-12"
          ref={mahostavRef}
          key={index}
        >
          <div className="mahostav-card  rounded-3">
            <div className="row w-100">
              <div className="main-card-col col-lg-6 col-md-6 col-sm-12 px-3 py-3">
                <div className="category">
                  <p className="mahostav-mini fw-semibold mb-1 text-uppercase text-light">
                    Program Category
                  </p>
                  <p className="mahostav-name fw-semibold mb-1 text-uppercase text-light">
                    Charity
                  </p>
                </div>
              </div>
              <div className="main-card-col col-lg-6 col-md-6 col-sm-12 px-3 py-3">
                <div className="category">
                  <p className="mahostav-mini fw-semibold mb-1 text-uppercase text-light">
                    Charity Amount
                  </p>
                  <p className="mahostav-name fw-semibold mb-1 text-light">
                    &#8377;&nbsp;{price} &nbsp; INR
                  </p>
                </div>
              </div>
              <div className="main-card-col col-lg-6 col-md-6 col-sm-12 px-3 py-3">
                <div className="category">
                  <p className="mahostav-mini fw-semibold mb-1 text-uppercase text-light">
                    Event
                  </p>
                  <p className="mahostav-name fw-semibold mb-1 text-light">
                    {eType}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="main-register-btn d-flex justify-content-center align-items-center h-100">
                  <button
                    className={`mahostav-card-register-btn text-light border-0 fw-semibold rounded-2 d-flex justify-content-center align-items-center shadow-sm`}
                    data-toggle="modal"
                    data-target="#exampleModalRight"
                    onClick={() => onOpenRegister(item)}
                  >
                    <div className="r-icon me-2">
                      <FaPlus />
                    </div>
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <section className="moa-sec">
        <div className="container">
          <div className="row w-100 gy-3">
            {chairityEvent.map((item, index) => {
              return (
                <>
                  <div
                    className="col-md-6 d-flex justify-content-center align-items-center"
                    key={index}
                  >
                    <div className="mahostav-video overflow-hidden rounded-2">
                      <video className="about-video object-fit-cover" controls>
                        <source src={item.video} />
                      </video>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="merathone-header d-flex flex-column align-items-center">
            <div className="my-5">
              <div className="d-flex justify-content-center align-items-center w-100">
                <div className="row gx-2 gy-3 w-100">
                  {ArrayObj.EventCard.map((item, index) => {
                    return EventCard(item.eventType, item.price, item, index);
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Participent Modal */}

      {openParticipentModal && (
        <>
          <div
            className="modal fade"
            id="registerModal"
            tabIndex="-1"
            aria-labelledby="registerModalLabel"
            aria-hidden="true"
            ref={modalRef}
          >
            <ParticipentForm
              onDance={dance}
              onClose={() => {
                setOpenParticipentModal(false);
                setDance(false);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default BairganiaMahotsav;
