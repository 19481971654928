import React from "react";
import "./pages.css";

const HaeGHarTiranga = () => {
  const harGharTiranga = [
    {
      header: "About Har Ghar Tiranga Project",
      text: "Har Ghar Tiranga’ is a campaign that started under the aegis of Azadi Ka Amrit Mahotsav to encourage people to bring the Tiranga home and to hoist it to mark India’s independence. It stemmed from the thought that our relationship with the national flag has always been more formal and institutional than personal. Bringing the flag home collectively as a nation thus became symbolic of not only an act of personal connection to the Tiranga but also an embodiment of our collective commitment to nation-building. The idea behind the initiative was to invoke the feeling of patriotism in the hearts of the people and to promote awareness about the Indian National Flag.The Ministry of Culture is the nodal ministry for Har Ghar Tiranga.",
    },
    {
      header: "People's movement",
      text: "In the last two years, Har Ghar Tiranga has grown to be a people’s movement. People across the country, belonging to different segments of society have embraced the idea and infused it with their warmth and support via participation in online and offline programmes and events.",
    },
    {
      header: "Tiranga Yatra",
      text: "Tiranga Yatras are a key part of the Har Ghar Tiranga movement. Young and old, men and women all join the yatras across the length and breadth of India to celebrate and honour the National Flag, bringing a sense of national pride and unity.",
    },
    {
      header: "Tiranga Rallies",
      text: "Bike, cycle, and car rallies dedicated to the spirit Tiranga are being organized locally with active participation of hobby groups, youth volunteers, representatives of different States and others.",
    },
    {
      header: "Tiranga Runs",
      text: "Runs and marathons dedicated to the spirit of Tiranga celebrating the values of the national flag are scheduled in the run up to Independence Day. People from all walks of life are encouraged to join and honor the flag that binds us all together.",
    },
    {
      header: "Tiranga Concerts",
      text: "Large public concerts will be organized with patriotic music. The Tiranga Anthem from the Ministry of Culture will be played at all concerts. These events aim to create a festive atmosphere and celebrate our national pride through music. Tiranga Anthem is available for download in the download section of this website.",
    },
    {
      header: "Tiranga Canvas",
      text: "Tiranga Canvases will be set up at event venues where people can write “Har Ghar Tiranga” or “Jai Hind” in any Indian language. Each canvas is designed in the same ratio as the national flag (3:2). A reference design template for the Tiranga Canvas is available for download in the download section of this website.",
    },
    {
      header: "Tiranga Selfie",
      text: (
        <p className="list-table-one">
          Participants are encouraged to hoist the flag and take selfies with
          the Tiranga and upload them to the Har Ghar Tiranga website (
          <a href="https://harghartiranga.com/" target="_blank">
            harghartiranga.com
          </a>
          ). Certificates can be shared on social media with #HarGharTiranga and
          #HGT2024. This will help engage people and celebrate their
          participation.
        </p>
      ),
    },
    {
      header: "Tiranga Tribute",
      text: "Bravehearts and freedom fighters, along with their families, will be remembered, honored and felicitated in local programs on behalf of a grateful nation. Their sacrifices and contributions to India’s freedom and progress is a constant inspiration for all of us.",
    },
    {
      header: "Tiranga Melas",
      text: "Tiranga Melas will support local artisans and add to the festive mood. Stalls selling flags, merchandise, and food will be set up around event venues, creating a lively and celebratory atmosphere.",
    },
  ];
  const qaTiranga = [
    {
      question:
        " Is the use, display and hoisting of the National Flag guided by any overarching set of instructions?",
      ans: "Yes– the ‘Flag Code of India 2002’ and the Prevention of Insults to National Honour Act, 1971",
    },
    {
      question: "What is the Flag Code of India?",
      ans: "The Flag Code of India brings together all laws, conventions, practices, and instructions for the display of the National Flag. It governs the display of the National Flag by Private, Public, and Government Institutions. The Flag Code of India took effect on 26th January 2002.",
    },
    {
      question: "Which material can be used to produce the National Flag?",
      ans: "The Flag Code of India, 2002 was amended vide Order dated 30th December, 2021 and National Flag made of polyester or machine made Flag have been allowed. Now, the National Flag shall be made of handspun and handwoven or machine-made, cotton/polyester/wool/silk/khadi bunting.",
    },
    {
      question: "What is the appropriate size and ratio of the National Flag?",
      ans: "As per paragraph 1.3 & 1.4 of the Flag Code of India, the National Flag shall be rectangular in shape. The Flag can be of any size but the ratio of the length to the height (width) of the National Flag shall be 3:2.",
    },
    {
      question: "Can I display the National Flag at my home?",
      ans: "As per paragraph 2.2 of the Flag Code of India, a member of the public, a private organization, or an educational institution may hoist/display the National Flag on all days or occasions in accordance with the dignity and honour of the National Flag..",
    },
    {
      question:
        "What is the timing for flying the National Flag in the open/at the home?",
      ans: "The Flag Code of India, 2002 was amended vide Order dated 20th July, 2022 and clause (xi) of paragraph 2.2 of Part-II of the Flag Code of India was replaced by the following clause:- (xi) “where the Flag is displayed in open or displayed on the house of a member of public, it may be flown day and night;”",
    },
    {
      question:
        "What should I keep in mind while displaying the National Flag at my home?",
      ans: "Whenever the National Flag is on display, it should occupy the position of honor and should be distinctly placed. A damaged or disheveled National Flag should not be displayed.",
    },
    {
      question:
        "What should I keep in mind to avoid incorrect display of the National Flag?",
      list: [
        "The National Flag shall not be displayed in an inverted manner; i.e.; the saffron band should not be the bottom band.",
        "A damaged or disheveled National Flag shall not be displayed. The National Flag shall not be dipped in salute to any person or thing.",
        "The National Flag shall not be dipped in salute to any person or thing.",
        "No other flag or bunting shall be placed higher than or above or side by side with the National Flag; nor shall any object including flowers or garlands, or emblem be placed on or above the flagmast from which the National Flag is flown.",
        "The National Flag shall not be used as a festoon, rosette, bunting, or in any other manner for decoration.",
        "The National Flag shall not be allowed to touch the ground or the floor or trail in the water.",
        "The National Flag shall not be displayed or fastened in any manner as may damage it.",
        "The National Flag should not be flown from a single masthead (top part of a flagpole) simultaneously with any other flag or flags.",
        "The National Flag shall not be used to cover a speaker&apos;s desk, nor shall it be draped over a speaker&apos;s platform.",
        "The National Flag shall not be used as a portion of costume or uniform or accessory of any description which is worn below the waist of any person nor shall it be embroidered or printed on cushions, handkerchiefs, napkins, undergarments or any dress material.",
      ],
    },
    {
      question:
        "Are there any rules for prevention of insult to the Indian National Flag?",
      list: [
        "Yes. In accordance with explanation 4 to section 2 of “the Prevention of Insults to National Honour Act, 1971’, the following should be observed:",
        "The National Flag shall not be used as a form of drapery in any form whatsoever, including private funerals",
        "The National Flag shall not be used as a portion of costume or uniform or accessory of any description which is worn below the waist of any person nor shall it be embroidered or printed on cushions, handkerchiefs, napkins, undergarments or any dress material",
        "There shall be no lettering upon the National Flag",
        "The National Flag shall not be used to wrap, receive, or deliver things",
        "The National Flag shall not be used to cover the sides, back, and top of any vehicle",
      ],
    },
    {
      question:
        "What is the correct way to display the National Flag in the open/on public buildings?",
      list: [
        "When the National Flag is displayed flat and horizontal on a wall, the saffron band shall be upper most and when displayed vertically, the saffron band shall be to the right in reference to the National Flag i.e., it should be to the left of a person facing it.",
        "When the National Flag is displayed from a staff projecting horizontally or at an angle from a windowsill, balcony, or front of a building, the saffron band shall be at the farther end of the staff.",
      ],
    },
    {
      question: "Should the National Flag be flown at half-mast?",
      ans: "The National Flag shall not be flown at half-mast except on occasions instructed by the Government of India. When flown at half-mast, the National Flag shall be hoisted first to the peak/top of the staff, then lowered to the half-mast position. Before lowering the National Flag for the day, it should be raised again to its peak.",
    },
    {
      question: "Can I display the National Flag on my car?",
      list: [
        "The privilege of hoisting the National Flag on motor cars is only limited to the following people as per paragraph 3.44 of the Flag Code of India, 2002.",
        "President",
        "Vice-President",
        "Governors and Lieutenant Governors",
        "Heads of Indian Missions/Posts",
        "Prime Minister",
        "Cabinet Ministers, Minister of State, and Deputy Ministers of the Union",
        "Chief Minister and Cabinet Minister of a State or Union Territory",
        "Speaker of the Lok Sabha, Deputy Chairman of Rajya Sabha, Deputy Speaker of Lok Sabha, Chairman of Legislative Councils in States, Speakers of the Legislative Assemblies in States and Union Territories, Deputy Chairman of Legislative Council in States, Deputy Speakers of Legislative Assemblies in States and Union Territories",
        "Chief Justice of India",
        "Judges of Supreme Court",
        "Chief Justice of High Courts",
        "Judges of High Courts",
      ],
    },
    {
      question:
        "How can we display the Indian National Flag along with flags of other nations?",
      list: [
        "As per paragraph 3.32 of the Flag Code of India, when the National Flag is displayed in a straight line with flags of other countries, the National Flag shall be on the extreme right. The flags of other nations will follow in an alphabetical order as per the English versions of names of the nations.",
        "If the flags are flown in a closed circle formation, the National Flag is flown first and is followed by flags of other national in a clockwise manner.",
        "When the flag is displayed against the wall with another flag from crossed staffs, the National Flag shall be on the right and its staff will be in front of the staff of the other flag",
        "When the National Flag is flown with flags of other nations, the flag masts shall be of equal size.",
      ],
    },
    {
      question: " How should the National Flag be disposed?",
      list: [
        "As per paragraph 2.2 of the Flag Code of India, if the National Flag is damaged, it shall be destroyed as a whole in private, preferably by burning or any other method considering the dignity of the National Flag",
        "The National Flag, if made of paper, is waved by the general public, these flags should not be discarded on the ground. These should be discarded in private, keeping in mind the dignity of the National Flag.",
      ],
    },
  ];

  return (
    <section className="moa-sec">
      <div className="container">
        <div className="dflex justify-content-center align-items-center w-100">
          <div className="har_ghar_tiranga p-2">
            <div className="chairman-title d-flex align-items-center px-2 py-1">
              <h1 className="main-text-vicechairman-title flag-title ">
                Rauniyar Foundation Bharat Tiranga Yatra Program
              </h1>
            </div>
            {harGharTiranga.map((item, index) => {
              return (
                <>
                  <div key={index}>
                    <p className="half-merathone-list-title list-table-one mt-4">
                      &nbsp; {item.header}
                    </p>
                    <p className="list-table-one ms-4">{item.text}</p>
                    {index == 1 && (
                      <div className="tiranga_yatra_img overflow-hidden d-flex justify-content-center align-items-center w-100">
                        <div className="main-tiranga-img">
                          <img
                            src={require("../assetes/ourassosiates/tiranga_yatra_2024.jpg")}
                            alt=""
                            className="w-100 object-fit-cover"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
            <div className="faq">
              <p
                className="list-table-one fw-semibold  my-5"
                style={{ fontSize: "16px" }}
              >
                Frequently Asked Questions (FAQs) about the Indian National Flag
              </p>
              {qaTiranga.map((item, index) => {
                return (
                  <>
                    <div key={index}>
                      <p className="list-table-one quesation">
                        Q {index + 1}. {item.question}
                      </p>
                      <p className="list-table-one">{item.ans}</p>

                      {item.list?.map((item, index) => {
                        return (
                          <>
                            <div key={index}>
                              <ul className="p-0" style={{ listStyle: "disc" }}>
                                <li className="ms-5">
                                  <p className="list-table-one">{item}</p>
                                </li>
                              </ul>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                );
              })}
              <div>
                <p className="fw-semibold list-table-one my-3">Source:</p>
                <div className="link ms-5 overflow-hidden">
                  <a
                    href="https://www.mha.gov.in/sites/default/files/flagcodeofindia_070214.pdf"
                    target="_blank"
                    className="my-2"
                  >
                    https://www.mha.gov.in/sites/default/files/flagcodeofindia_070214.pdf
                  </a>
                </div>
                <div className="link ms-5 overflow-hidden">
                  <a
                    href="https://www.mha.gov.in/sites/default/files/Prevention_Insults_National_Honour_Act1971_1.pdf"
                    target="_blank"
                    className="my-2"
                  >
                    https://www.mha.gov.in/sites/default/files/Prevention_Insults_National_Honour_Act1971_1.pdf
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HaeGHarTiranga;
