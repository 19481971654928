import React from "react";
import "./pages.css";

const PratePolicyPopup = ({ onClose }) => {
  const demo = [
    {
      header: "Definitions and Key Concepts",
      subHeader: "In this Privacy Policy, the following definitions are used:",
      list: [
        {
          listkey: "Data",
          text: "Personal information, including sensitive personal information and special category personal data (as defined under Data Protection Laws) about you, which we collect, receive, or otherwise process in connection with your use of our website/App and/or the Platform.",
        },
        {
          listkey: "Cookies",
          text: "A small file placed on your device by our website/App or the Platform when you either visit or use certain features of our website/App or the Platform. A cookie generally allows a website or mobile application to remember your actions or preference for a certain period of time.",
        },
        {
          listkey: "Data Protection Laws",
          text: "Any applicable law for the time being in force relating to the processing of Data.",
        },
        {
          listkey: "Partners",
          text: "Select third parties (including RAUNIYAR FOUNDATION BHARAT GROUP Entities) with whom we have contracts for the businesses described in this Privacy Notice.",
        },
        {
          listkey: "Service Providers",
          text: "Includes entities which provide services to us and to whom we may disclose your Data for a specific purpose pursuant to a written contract.",
        },
        {
          text: "RAUNIYAR FOUNDATION BHARAT GROUP: RAUNIYAR FOUNDATION BHARAT GROUP, a company incorporated in India whose registered office is 418,Fourth Floor, Times Corner, Near Ambrosia Business Hub, VIP Road,Vesu,Surat - 395007, Gujarat, India.",
        },
        {
          text: "RAUNIYAR FOUNDATION BHARAT GROUP Entity: RAUNIYAR FOUNDATION BHARAT GROUP, and its subsidiaries, affiliates, associate companies, and joint venture companies.",
        },
        {
          listkey: "User or you",
          text: "The natural person who accesses or use the Services through our website/App or the Platform.",
        },
      ],
    },
  ];
  const demotwo = [
    {
      header: "What Data Do We Collect About You",
      subHeader:
        "We collect Data for various purposes set out in this Privacy Notice. This Data includes, without limitation, the following categories:",
      list: [
        {
          text: "(a) Contact information: first and last name, email address, postal address, country, phone number and other similar contact data.",
        },
        {
          text: "(b) Financial information: payment instrument information, transactions, transaction history, preferences, method, mode and manner of payment, spending pattern or trends, and other similar data. It is clarified that we do not store any of your cards details i.e. credit and debit card in our Application or website.",
        },
        {
          text: "(c) Technical information: website, device and mobile app usage, Internet Protocol (IP) address and similar information collected via automated means, such as cookies, pixels and similar technologies.",
        },
        {
          text: "(d) Product and service information: Your website/App or Platform account membership number, registration information, and program-specific information, when you request products and/or services directly from us or participate in marketing programs.",
        },
        {
          text: "(e) Your reviews, feedback and opinions about our products, programmes, and services.",
        },
        {
          text: "(f) Loyalty programme information: your loyalty membership information, account details, profile or password details, if any.",
        },
        {
          text: "(g) Transaction information: date of the transaction, transaction amount, transaction history and related details.",
        },
        {
          text: "(h) Other information: Age, sex, date of birth, marital status, nationality, occupation or any other personal information provided by you.",
        },
        {
          text: "(i) Location information: personal data collected during usage of service provided by us such as Maps and Navigation, cab services, or any other similar services which requires processing of such data.",
        },
      ],
    },
    {
      header: "How We Collect Data",
      subHeader: "We collect Data in the following ways:",
      list: [
        {
          text: "(a) Information You Give Us: We receive and store any information you enter on our website App or the Platform or give us in any other way. Please see the section below, titled “Data Shared by You” for more information.",
        },
        {
          text: "(b) Automatic Information We Collect: We use “cookies”, pixels and similar technologies to receive and store certain types of information whenever you interact with us. Please see the section below, titled “Data That is Collected Automatically” for more information.",
        },
        {
          text: "(c) E-mail Communications: We often receive a confirmation (if your device supports such capabilities) when you open e-mail from us or click on a link in the e-mail. You can choose not to receive marketing emails from us by clicking on the unsubscribe link in any marketing email.",
        },
        {
          text: "(d) Customer Care Support: We store data provided by you to the customer care support team including but not limited to the call recording.",
        },
        {
          text: "(e) Automatic Information We Collect from Other Websites: We receive and store certain types of information when you interact with third-party websites that use our technology or with whom we have a specific agreement.",
        },
        {
          text: "(f) Information from Other Sources: We may obtain information from other sources. An example where we receive your Data from RAUNIYAR FOUNDATION BHARAT GROUP Entities in connection with the Platform for the purposes of enrolment, offering you products, services and benefits on the Platform.",
        },
        {
          text: "(g) Information Previously Provided to RAUNIYAR FOUNDATION BHARAT GROUP Entities: Where you have shared any information previously with any of the Group Entities and such Group Entity is permitted to further share such information, it will share such information with us. If such information is sensitive personal information or special category personal data, the Group Entity will only share such information with us with your consent. Regardless of the terms on which you had originally provided the information to a Group Entity, you acknowledge that: (a) we will collect such information from such Group Entity for the purposes set out in this Privacy Notice; (b) the Group Entity that provides such information to us is subject to its own compliance obligations under the Data Protection Laws, and (c) we are not responsible for any acts or omissions of such Group Entity in relation to any such compliance obligations under the Data Protection Laws.",
        },
        {
          text: "(h) We automatically collect purchase or content use history, which we sometimes aggregate with similar information from other customers to create features such as Best Seller, Top Rated, etc.",
        },
        {
          text: "(i) The information about usage of the App, including crash logs and usage statistics.",
        },
        {
          text: "(j) Information about the location of your device, including geolocation information.",
        },
        {
          text: "(k) Information such as your location is collected upon availing service which requires processing of such data to provide the requisite service to you.",
        },
        {
          text: "(l) By using our App, you are agreeing that we may advertise your feedback on the App/website and marketing materials.",
        },
        {
          text: "(m) We will retain your information as long as we require this to provide you with the goods and services and for such period as mandated by the concerned laws.",
        },
      ],
    },
    {
      header: "Data Shared by You",
      subHeader:
        "We may collect your Data in several ways from your use of our website/App or the Platform. For instance:",
      list: [
        {
          text: "(a) When you register with us to receive our products and/or services.",
        },
        {
          text: "(b) When you conduct a transaction or attempt a transaction on our website/App or Platform.",
        },
        {
          text: "(c) When your complete surveys conducted by or for us.",
        },
        {
          text: "(d) When you elect to receive any communications (including promotional offers) from us.",
        },
        {
          text: "(e) From the information gathered by your visit to our website or the Our Platform.",
        },
        {
          text: "(f) Do not provide personal data about others unless you are authorized or required to do so by contract or applicable law.",
        },
      ],
    },
    {
      header: "Data We Received from Other Sources",
      list: [
        {
          text: "We may receive personal data about you from other sources including but not limited to the information provided by you to us for availing any product or service available at the Platform, our affiliate, a person, or company/organization authorized by you to apply on your behalf, and/or publicly available sources. We’ll contact you to let you know we are processing your personal information.",
        },
      ],
    },
    {
      header: "Data That is Collected Automatically",
      list: [
        {
          text: "(a) We automatically collect some information when you visit our website/App or the Platform. This information helps us to make improvements to our content and navigation. The information collected automatically includes your IP address.",
        },
        {
          text: "(b) Our web servers or affiliates who provide analytics and performance enhancement services collect IP addresses, operating system details, browsing details, device details and language settings. This information is aggregated to measure the number of visits, average time spent on the site, pages viewed and similar information. We use this information to measure the site usage, improve content and to ensure safety and security, as well enhance performance of our website/App or the Platform.",
        },
        {
          text: "(c) We may collect your Data automatically via Cookies, pixels and similar technologies in line with settings on your browser. For more information about Cookies, please see the section below, titled 'Cookies, Pixels and Similar Technologies'.",
        },
      ],
    },
    {
      header: "Our Use of Data",
      list: [
        {
          text: "We use the information collected through this website/App, as described in this policy and we may use your information to:",
        },
        {
          text: "I. Improve our services, website/App and how we operate our businesses.",
        },
        {
          text: "II. Understand and enhance your experience using our Website/App, products and services.",
        },
        {
          text: "III. Personalize our products or services and make recommendations.",
        },
        {
          text: "IV. Provide and deliver products and services you request.",
        },
        {
          text: "V. Process manage, complete, and account for transactions.",
        },
        {
          text: "VI. Provide customer support and respond to your requests, comments, and enquiries.",
        },
        {
          text: "VII. Create and manage the online accounts you manage on our App.",
        },
        {
          text: "VIII. Send you related information, including confirmations, technical notices, updates, security alerts and support and administrative messages.",
        },
        {
          text: "IX. Communicate with you about promotions, upcoming events, and news about products and services.",
        },
        {
          text: "X. We may process your personal information without your knowledge or consent where required by applicable law or regulation for the purposes of verification of identity or for prevention, detection, or investigation, including of cyber incidents, prosecution, and punishment of offences.",
        },
        {
          text: "XI. Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.",
        },
        {
          text: "XII. Protecting against, and preventing, fraud, illegal activity, harm, financial loss and other legal or information security risks.",
        },
        {
          text: "XIII. Providing marketing and promotional campaigns to you based on your profile.",
        },
        {
          text: "XV. In connection with loyalty programs owned and operated by us or by other Group Entities.",
        },
        {
          text: "XVI. Create a user profile based on data provided by you on using of the application.",
        },
      ],
    },
  ];
  const demothree = [
    {
      header: "PERMISSIBLE AGE",
      content: [
        {
          description:
            "a. The Services are not intended for users under the age of 18, unless permitted under applicable local laws (Permissible Age) or under the involvement of a parent or guardian. We do not knowingly collect any personal information from users or market to or solicit information from anyone under the Permissible Age. If we become aware that a person submitting personal information is under the Permissible Age, we will delete the account and any related information as soon as possible. If you believe we might have any information from or about a user under the Permissible Age, please contact us at info@rauniyarfoundationbharat.org.",
        },
        {
          description:
            "b. This Website is not directed at children under the age of 10 and we cannot distinguish the age of persons who access and use our website. If a minor (according to applicable laws) has provided us with Customer Data without parental or guardian consent, the parent or guardian should contact us (see Section 20) to remove the relevant Customer Data and unsubscribe the minor. If we become aware that Customer Data has been collected from a person under the age of 10 without parental or guardian consent, we will delete this Customer Data and, where that minor has an account, terminate the minor’s account.",
        },
      ],
    },
    {
      header: "SHARING OF DATA",
      content: [
        {
          description:
            "a. We share your data with your consent or to complete any transaction or provide any product or service you have requested or authorized. We also share data with our affiliates and subsidiaries, with vendors working on our behalf.",
        },
        {
          description:
            "b. We may employ other companies and individuals to perform functions on our behalf. The functions include fulfilling orders for products or services, delivering packages, sending e-mail, removing repetitive information from customer lists, providing marketing assistance, providing search results and links (including paid listings and links), processing payments, transmitting content, scoring credit risk, and providing customer service.",
        },
        {
          description:
            "c. Business Transfers: As we continue to develop our business, we might sell or buy subsidiaries or business units. Your Data (including in relation to loyalty programs) may be transferred as part of such transactions.",
        },
        {
          description:
            "d. Protecting Us: We may release Data when we believe that disclosure is reasonably necessary (a) to comply with a law, regulation or compulsory legal request; (b) to protect our or an RAUNIYAR FOUNDATION BHARAT GROUP Entity's intellectual property rights; (c) to protect our against harm or financial loss; (d) when we believe disclosure is necessary to protect individuals’ vital interests, or (e) in connection with an investigation of suspected or actual fraudulent or illegal activity. This may include exchanging information with other companies and organizations for fraud protection, risk management and dispute resolution.",
        },
        {
          description:
            "e. Third Parties: We may also share your Data with other third parties where: (a) You request or authorize us to do so; (b) We need to comply with applicable law or respond to valid legal process; or (c) We need to operate and maintain the security of our website/App or Platform, including to prevent or stop an attack on our computer systems or networks.",
        },
        {
          description:
            "f. We reserve our right to share the information collected within the **RAUNIYAR FOUNDATION BHARAT GROUP** Entities and internal departments for promotional and marketing activities and communications.",
        },
        {
          description:
            "g. Migration of Data: We may also process your data for other business and offerings controlled, maintained, governed and operated by **RAUNIYAR FOUNDATION BHARAT**, thereby, Your Data (including in relation to any loyalty program(s) operated, maintained, and governed by **RAUNIYAR FOUNDATION BHARAT GROUP** or any other Group Companies) may be migrated as part of such business transactions.",
        },
        {
          description:
            "h. The Unemployment Awareness Half Bairgania Mahotsav 2024  runs its partners/sponsors, **RAUNIYAR FOUNDATION BHARAT GROUP**, and their related businesses reserve the right to use event photos/videos for marketing purposes.",
        },
      ],
    },
    {
      header: "DATA SECURITY",
      content: [
        {
          description:
            "We will use technical and organizational measures to safeguard your Data and we store your Data on secure servers. Technical and organizational measures include measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorized access to your Data, please let us know immediately by contacting us by e-mail.",
        },
        {
          description:
            "The security of your Platform account depends on you keeping your account password(s), PINs, and other access information confidential. If you share your Platform account information with a third party, they will have access to your account and your Data. It is your responsibility to control access to your mobile device and the Platform on your device, including keeping your password(s) and/or PIN confidential and not sharing it with anyone. It is also your responsibility to alert us if you believe that the security of your Data in the Platform has been compromised.",
        },
      ],
    },
  ];
  const demofour = [
    {
      header: "Retention of Data",
      list: [
        {
          text: "We retain Data for as long as necessary for the use of our products and/or services or to provide access to and use of our website/App or the Platform, or for other essential purposes such as complying with our legal obligations, resolving disputes, enforcing our agreements and as long as processing and retaining your Data is necessary for our legitimate interests. Because these needs can vary for different data types and purposes, actual retention periods can vary significantly. Even if we delete your Data, including on account of exercise of your right under Clause 13 below, it may persist on backup or archival media for audit, legal, tax or regulatory purposes.",
        },
      ],
    },
    {
      header: "Where We Store Data",
      list: [
        {
          text: "We may transfer your Data to recipients in India, where we are headquartered, and other countries. These countries may not have the same data protection laws as the country in which you initially provided the information. If we transfer your Data to other countries, we will protect that information as described in this Privacy Policy, as disclosed to you at the time of data collection or as described in our program-specific privacy notice.",
        },
        {
          text: "If the GDPR/United Kingdom’s version of the GDPR applies to our processing of your Data, we will only process and/or transfer Data outside of the European Economic Area/United Kingdom where it is compliant with Data Protection Laws and the means of transfer provides adequate safeguards in relation to your Data, including for example:",
        },
        {
          text: "(a) by way of a data transfer agreement incorporating the current relevant standard contractual clauses adopted in the European Economic Area/United Kingdom; or",
        },
        {
          text: "(b) by transferring your Data to a country where there has been a finding of adequacy by the European Commission in respect of that country’s levels of data protection via its legislation; or",
        },
        {
          text: "(c) where it is necessary for the conclusion or performance of a contract between us and a third party and the transfer is in your interests for the purposes of that contract;",
        },
      ],
    },
    {
      header: "Your Rights and Choices",
      list: [
        {
          text: "Depending on which country’s laws are applicable to you, you may have various rights in relation to the Data that we hold about you. To get in touch with us about any of your rights under Data Protection Laws, please use the contact details set in the section below, titled “How to Contact Us”. We will seek to deal with your request without undue delay, and in any event, within any time limits provided for in Data Protection Laws (subject to any extensions to which we are lawfully entitled). Please note that we may keep a record of your communications to help us resolve any issues which you raise. Where we are legally permitted to do so, we may refuse your request.",
        },
        {
          text: "You may be entitled to the following rights in relation to your Data:",
        },
        {
          text: "(a) Right to Confirmation and Access - you may ask us to confirm what Data we hold about you at any time, and request us to modify, update or delete such Data. You may also request a copy of the Data we hold about you.",
        },
        {
          text: "(b) Right to Rectification - you have the right to request that we rectify any inaccurate or incomplete Data that we hold about you, including by means of providing a supplementary statement.",
        },
        {
          text: "(c) Right to be Erasure - you have the right to request that we 'erase' your Data in certain circumstances.",
        },
        {
          text: "(d) Right to Restrict Our Use of your Data - you have the right to request that we restrict our processing of your Data in certain circumstances, for example if you dispute the accuracy of the Data that we hold about you or you object to our processing of your Data (including where we process your Data for our legitimate interests, where permitted under applicable law).",
        },
        {
          text: "(e) Right to Object - this right enables you to object to us processing your Data where we do so for certain reasons.",
        },
        {
          text: "(f) Right to Data Portability - you have the right to request that we transfer your Data to another third party. This right of data portability only applies to certain types of Data.",
        },
        {
          text: "(g) Right to Withdraw Consent - where we have obtained your consent to process your Data for certain activities, you may withdraw this consent at any time and we will cease to carry out that particular activity that you previously consented to unless we consider that there is an alternative legal basis to justify our continued processing of your Data for this purpose.",
        },
        {
          text: "(h) Right to File Complaints - you have the right to lodge a complaint with the relevant supervisory authority in your jurisdiction.",
        },
        {
          text: "To get in touch with us about these rights, or for more information about managing your Data and promotional communications, please use the contact details set out in the section below, titled “How to Contact Us”.",
        },
        {
          text: "It is important that the Data we hold about you is accurate and current. Please keep us informed if your personal information changes during the period for which we hold it.",
        },
      ],
    },
    {
      header: "Processing Your Data",
      list: [
        {
          text: "We take steps to ensure that your Data is processed according to the provisions of this Privacy Notice and the requirements of applicable law.",
        },
      ],
    },
    {
      header: "Severability",
      list: [
        {
          text: "If any court or competent authority finds that any provision of this Privacy Policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this Privacy Policy will not be affected.",
        },
      ],
    },
    {
      header: "Links to Third Party Sites/Apps",
      list: [
        {
          text: "Our App may, from time to time, contain links to and from other Apps/websites of third parties. Please note that if you follow a link to any of these Apps/websites, such Apps/websites will apply different terms to the collection and privacy of your personal data, and we do not accept any responsibility or liability for these policies. When you leave our Site, we encourage you to read the privacy policy of every App/Website you visit.",
        },
      ],
    },
    {
      header: "Cookies",
      list: [
        {
          text: "To optimize our App presence, we use cookies. These are small text files stored on your device. These cookies are deleted after you close the App. Other cookies remain on your device (long-term cookies) and permit its recognition on your next visit. This allows us to improve your access to our site. This helps us to learn more about your interests, and provide you with essential features and services and for additional purposes, including:",
        },
        { text: "(a) Keeping track of items stored in your shopping basket." },
        {
          text: "(b) Conducting research and diagnostics to improve the content, products, and services.",
        },
        { text: "(c) Preventing fraudulent activity." },
        { text: "(d) Improving security." },
        {
          text: "Our cookies allow you to take advantage of some of our essential features. For instance, if you block or otherwise reject our cookies, you will not be able to add items to your shopping basket, proceed to checkout, or use any products or services that require you to sign in.",
        },
        {
          text: "Approved third parties also may set cookies when you interact with our services.",
        },
        {
          text: "Third parties include search engines, providers of measurement and analytics services, social media networks, and advertising companies.",
        },
        {
          text: "Third parties use cookies in the process of delivering content, including ads relevant to your interests, to measure the effectiveness of their ads, and to perform services on behalf of us.",
        },
        {
          text: "You can prevent the storage of cookies by choosing a 'disable cookies' option in your device settings. But this can limit the functionality of our services.",
        },
      ],
    },
    {
      header: "Changes to This Policy",
      list: [
        {
          text: "We may change this policy from time to time. If we make any changes to this policy, we will change the 'Last Updated' date above. You agree that your continued use of our services after such changes have been published to our services will constitute your acceptance of such revised policy. We will notify you and seek additional consent before using your personal data for a new purpose that is inconsistent with the original purpose for which we collected it.",
        },
      ],
    },
  ];

  const privacyPolicyData = [
    {
      header: "1. Information we may collect about you",
      list: [
        "Information you provide to us: Personal data that you provide to us, such as when using the contact form on our website or mobile app, including your name, email address, etc.",
        "Advanced Passenger Information (API): Including your passport, Visa and travel information, date of birth, gender, and nationality.",
        "Health data: Relating to passengers, in order to meet your requirements in flight (e.g., to respond to particular health risks or to understand allergies, etc.) and also to provide special assistance, for example, wheelchair access assistance.",
        "Payment information: Including payment type, information relating to the manner/method of payment, card information (for your security, we do not retain your CVV number; we simply use this to authenticate your card) and where relevant, the name of your bank.",
        "Our correspondence: If you contact us, we will typically keep a record of that correspondence.",
        "Survey information: We may also ask you to complete surveys that we use for research purposes. In such circumstances, we shall collect the information provided in the completed survey.",
        "Your transactions: If you have purchased any services through our website or platform, details of related transaction details and of the fulfilment of the services we provide.",
        "Website and communication usage: Details of your visits to the websites and information collected on our website including cookies and other tracking technologies including, but not limited to, your IP address and domain name, geographical location, your browser version and operating system, traffic data, location data, web logs and other communication data, and the resources that you access.",
        "Certain personal data (particularly details of your travel documentation, payment details and contact information) are required in order for us to supply our products and services, and if you fail to supply such personal data as requested for specific services, we may be unable to deliver you the products and services in full.",
        "Where you have provided us with personal data relating to another person (for example, where you have booked a flight on his or her behalf), please ensure that you have that person’s knowledge and permission to use their data. Please provide that person with a copy of (or a link to) this privacy policy so they understand how we process personal data.",
        "We may receive personal data from Global Distribution Systems, Online travel agents, or Travel management companies. The data received from these organizations will be limited to that described above and will not be used for any other purpose other than those described.",
      ],
    },
    {
      header: "2. USES MADE OF YOUR PERSONAL DATA",
      list: [
        "To communicate effectively with you and conduct our business: To conduct our business, including to respond to your queries, to otherwise communicate with you, or to carry out our obligations arising from any agreements entered into between you and us. Legal bases: Contract performance, legitimate interests (to enable us to perform our obligations and provide our services to you).",
        "To provide you with marketing materials: To provide you with updates and offers, where you have chosen to receive these. We may also use your information for marketing our own and our selected business partners’ products and services to you by post, email, SMS, phone and fax and private messages through other digital platforms and, where required by law, we will ask for your consent at the time we collect your data to conduct any of these types of marketing. We will provide an option to unsubscribe or opt-out of further communication on any electronic marketing communication sent to you or you may opt out by contacting us as set out in the “Contacting Us” section below. Legal bases: Consent, legitimate interest (to keep you updated with news in relation to our products and services).",
        "For research and development purposes: To analyse your personal data in order to better understand your and our other clients’ services and marketing requirements, to better understand our business and develop our products and services. Legal bases: Legitimate interests (to allow us to improve our services).",
        "To monitor certain activities: To monitor queries and transactions to ensure service quality, compliance with procedures and to combat criminal activity. Legal bases: Legal obligations, legal claims, legitimate interests (to ensure that the quality and legality of our services).",
        "To inform you of changes: To notify you about changes to our services and products. Legal bases: Legitimate interests (to notify you about changes to our service).",
        "To ensure website content is relevant: To ensure that content from our websites/Platform is presented in the most effective manner for you and for your device, which may include passing your data to business partners, suppliers and/or service providers. Legal bases: Legitimate interests (to allow us to provide you with the content and services on the websites).",
        "To reorganize or make changes to our business: In the event that we are subject to negotiations for the sale of our business or part thereof to a third party, or are sold to a third party, or undergo a re-organization, we may need to transfer some or all of your personal data to the relevant third party (or its advisors) as part of any due diligence process for the purpose of analyzing any proposed sale or re-organization. We may also need to transfer your personal data to that re-organized entity or third party after the sale or reorganization for them to use for the same purposes as set out in this policy. Legal bases: Legitimate interests (in order to allow us to change our business).",
        "In connection with legal or regulatory obligations: We may process your personal data to comply with our regulatory requirements or discourse with regulators as applicable which may include disclosing your personal data to third parties, the court service and/or regulators or law enforcement agencies in connection with enquiries, proceedings or investigations by such parties anywhere in the world or where compelled to do so. Where permitted, we will direct any such request to you or notify you before responding unless to do so would prejudice the prevention or detection of a crime. Legal bases: Legal obligations, legal claims, legitimate interests (to cooperate with law enforcement and regulatory authorities).",
        "Understanding your health requirements: To ensure that you are safe on flights and to manage any particular requirements for example, allergies. Legal bases: Explicit consent.",
      ],
    },
  ];

  return (
    <>
      <section className="moa-sec">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center w-100">
            <div className="privatePolicy p-3">
              <div className="chairman-title d-flex align-items-center px-2 py-1">
                <h1 className="main-text-vicechairman-title">Private Policy</h1>
              </div>
              <div className="privatePolicy-header termsandcondition-header-text mb-3">
                <p className="list-table-one w-100">
                  Welcome to{" "}
                  <span className="fw-semibold">
                    RAUNIYAR FOUNDATION BHARAT
                  </span>{" "}
                  policy (“Privacy Policy”, “Policy” or Privacy Notice).
                </p>
              </div>
              <div className="privatepolicy-uder-text">
                <p className="list-table-one mb-2">
                  <span className="fw-semibold">
                    {" "}
                    RAUNIYAR FOUNDATION BHARAT
                  </span>{" "}
                  and its affiliates (collectively, “RAUNIYAR FOUNDATION
                  BHARAT”, “we” or “us” or “our”) are engaged in sports academy
                  & franchise sports. This Policy outlines our practices in
                  relation to the collection, storage, usage, processing, and
                  disclosure of personal data that you have consented to share
                  with us when you access, use, or otherwise interact with our
                  website available at{" "}
                  <a
                    href="https://rauniyarfoundationbharat.org/"
                    className="text-link fw-semibold"
                  >
                    https://rauniyarfoundationbharat.org/
                  </a>{" "}
                  (collectively, “Platform”) or avail products or services that
                  <span className="fw-semibold">
                    {" "}
                    RAUNIYAR FOUNDATION BHARAT
                  </span>{" "}
                  offers you on or through the Platform (collectively, the
                  “Services”).
                </p>
                <p className="list-table-one mb-2">
                  In this Policy, the services offered to you through the
                  Platform are referred to as “Sports services”. At{" "}
                  <span className="fw-semibold">
                    {" "}
                    RAUNIYAR FOUNDATION BHARAT
                  </span>{" "}
                  , we are committed to protecting your personal data and
                  respecting your privacy. To provide you with services, we have
                  to collect and otherwise process certain data about you. This
                  Policy explains how we process and use personal data about
                  you. Please note that unless specifically defined in this
                  Policy, capitalized terms shall have the same meaning ascribed
                  to them in our Terms and Conditions, available at{" "}
                  <a
                    href="https://rauniyarfoundationbharat.org/"
                    className="text-link fw-semibold"
                  >
                    https://rauniyarfoundationbharat.org/
                  </a>
                  (“Terms”).
                </p>
                <p className="list-table-one mb-2">
                  We do collect your personal information and process your
                  personal data in accordance with the IT Act, 2000 (21 of 2000)
                  and other national and state laws which relate the processing
                  of personal data.
                </p>
                <p className="list-table-one mb-2">
                  Please read this Policy in consonance with the Terms. By using
                  the Services, you confirm that you have read and agree to be
                  bound by this Policy and consent to the processing activities
                  described under this Policy.
                </p>
              </div>
              <div className="private-policy-main-text list-table-one">
                {demo.map((item, index) => {
                  return (
                    <>
                      <div className="main-text" key={index}>
                        <p className="h4 mb-3">
                          {index + 1}&nbsp;&nbsp; {item.header}
                        </p>
                        <p className="list-table-one h6 mb-3">
                          {item.subHeader}
                        </p>
                        <ul className="main-private-list list-table-one p-0">
                          {item.list.map((item, index) => {
                            return (
                              <>
                                <li className="mb-2" key={index}>
                                  <span className="fw-semibold">
                                    {item.listkey}
                                  </span>{" "}
                                  {item.listkey === true && ":"} {item.text}
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="private-policy-domo-text">
                <p className="h6 list-table-one mb-4">
                  In this Privacy Policy, the following definitions are used:
                </p>
                <p className="h6 list-table-one mb-4">Our Company involves:</p>
                <p className="list-table-one mb-2">
                  (a){" "}
                  <span className="fw-semibold">
                    RAUNIYAR FOUNDATION BHARAT
                  </span>{" "}
                  GROUP is associated with champion teams Bairgania
                  Mahotsav,Bairgania Cricket League ( Men & Women’s Premier
                  League, Kabaddi, Kho-Kho etc.), International Cricket team
                  program & Book & Play facilities and India’s top Bairgania
                  Mahotsav 2024 dedicated to the welfare of country people –{" "}
                  <span className="fw-bold">RAUNIYAR FOUNDATION BHARAT</span>{" "}
                  Half Bairgania Mahotsav 2024 .
                </p>
                <p className="list-table-one mb-2">
                  For the purposes of certain applicable Data Protection Laws,
                  we will act as a "controller" when we use your Data for our
                  own purposes and a "processor" when we use your Data for and
                  under the instructions of a Group Entity and other
                  participating entities.
                </p>
                <p className="list-table-one mb-2">
                  This Privacy Policy primarily explains how we use your
                  personal data when we act as a controller of your Data. Where
                  we act as a "processor" on behalf of an{" "}
                  <span className="fw-bold">RAUNIYAR FOUNDATION BHARAT</span>{" "}
                  GROUP Entity or other participating entity (i.e. the
                  “controller”).
                </p>
              </div>
              <div className="private-policy-main-text list-table-one">
                {demotwo.map((item, index) => {
                  return (
                    <>
                      <div className="main-text" key={index}>
                        <p className="h4 mb-3">
                          {" "}
                          {index + 2} &nbsp;{item.header}
                        </p>
                        <p className="list-table-one h6 mb-3">
                          {item.subHeader}
                        </p>
                        <ul className="main-private-list list-table-one p-0">
                          {item.list.map((item, index) => {
                            return (
                              <>
                                <li className="mb-2" key={index}>
                                  <span className="fw-semibold">
                                    {item.listkey}
                                  </span>{" "}
                                  {item.listkey === true && ":"} {item.text}
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  );
                })}
                <p className="list-table-one mb-2">
                  Where processing your Data is necessary for us to carry out
                  our obligations arising from any contracts entered into
                  between you and us: If you enter into a contract with us in
                  relation to any of our product or service offerings, including
                  products or service offerings we offer to you directly on the
                  Platform, we may process certain Data about you in order to
                  perform our obligations under this contract, including to
                  enable us to communicate with you about such products and/or
                  services and to process your payment transactions.
                </p>
                <p className="list-table-one mb-2">
                  Where processing your personal information is within our
                  legitimate interests: We can process certain Data where it is
                  necessary for the purposes of the legitimate interests pursued
                  by us or by a third party, except where such interests are
                  overridden by the interests or fundamental rights or freedoms
                  of yours which require protection of your Data.
                </p>
                <p className="list-table-one mb-2">
                  Where you give us your opt-in consent to process your personal
                  information: In certain circumstances, where required under
                  relevant Data Protection Laws, we will seek to obtain your
                  consent before we undertake certain processing activities with
                  your Data.
                </p>
                <p className="list-table-one mb-2">
                  Where required under relevant Data Protection Laws (e.g. under
                  direct marketing legislation), we will obtain your opt-in
                  consent prior to sharing your Data with third parties and
                  carrying out certain marketing activities (unless we are
                  permitted by relevant Data Protection Laws to contact you
                  without your opt-in consent in relation to goods and services
                  which are similar to those which you have already purchased,
                  used or interacted with).
                </p>
                <p className="list-table-one mb-2">
                  You have the right to withdraw your consent to these
                  activities. You can do so at any time, and details of how to
                  do so can be found in the section below, titled "Your Rights
                  and Choices".
                </p>
                <p className="list-table-one mb-2">
                  Where processing your personal information is necessary for
                  our compliance with a legal obligation: In certain
                  circumstances, we may disclose your Data for the purposes of
                  compliance with a legal obligation (for example, to comply
                  with a law, regulation, or compulsory legal request). We do
                  not think that any of the above activities prejudice you in
                  any way. However, you do have the right to object to us
                  processing your personal information in certain circumstances.
                  If you would like to know more about these circumstances and
                  how to object to our processing activities, please see the
                  section below, titled "Your Rights and Choices".
                </p>
                <p className="list-table-one mb-2">
                  Some of the above grounds for processing will overlap and
                  there may be several grounds which justify our use of your
                  Data.
                </p>
                <p className="list-table-one mb-2">
                  Further, where required under relevant Data Protection Laws,
                  we will only process special category personal data (as
                  defined under Data Protection Laws) if there is a valid legal
                  basis, which might involve obtaining your explicit consent.
                </p>
                <p className="list-table-one mb-2">
                  Where we use or share or otherwise process your Data as a
                  processor on behalf of a Group Entity or other participating
                  entity (i.e., the controller) for the purposes of offering you
                  access to Third Party Applications on the Platform or as part
                  of any services that we provide to that entity, we do so under
                  the instructions of that entity and that entity is responsible
                  for ensuring that there is a legal basis for us to use or
                  share your Data on its behalf (if such legal basis is required
                  under relevant Data Protection Laws).
                </p>
                {demothree.map((item, index) => (
                  <div className="section" key={index}>
                    <h4 className="header mb-3">
                      {index + 8}. {item.header}
                    </h4>
                    {item.content.map((contentItem, contentIndex) => (
                      <p className="description mb-2" key={contentIndex}>
                        {contentItem.description
                          .split(/(\*\*.*?\*\*)/g)
                          .map((part, i) => {
                            if (part.startsWith("**") && part.endsWith("**")) {
                              return (
                                <strong key={i} className="fw-bold">
                                  {part.replace(/\*\*/g, "")}
                                </strong>
                              );
                            }
                            return part;
                          })}
                      </p>
                    ))}
                  </div>
                ))}
                <div className="main-text">
                  <p className="h4 mb-3">
                    11 ANALYTICS AND TAILORED ADVERTISING
                  </p>
                  <p className="list-table-one h6 mb-3">
                    To help us better understand your use of the Services, we
                    may use third-party web analytics on our Services, such as
                    Google Analytics etc. These service providers use the sort
                    of technology described in the "Automatically-Collected
                    Information" Section above. The information collected by
                    this technology will be disclosed to or collected directly
                    by these service providers, who use the information to
                    evaluate our users' use of the Services. We also use Google
                    Analytics as described in the following section. To prevent
                    Google Analytics from collecting or using your information,
                    you may install the Google Analytics Opt-Out Browser add-on.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    Tailored Advertising: Third parties whose products or
                    services are accessible or advertised via the Services may
                    also use cookies or similar technologies to collect
                    information about your use of the Services. This is done in
                    order to help them-
                  </p>
                  <ul className="main-private-list list-table-one p-0">
                    <li className="mb-2">
                      Inform, optimize, and serve ads based on past visits to
                      our website and other sites and
                    </li>
                    <li className="mb-2">
                      Report how our ad impressions, other uses of ad services,
                      and interactions with these ad impressions and ad services
                      are related to visits to our website.
                    </li>
                  </ul>
                  <p className="list-table-one mb-2">
                    We also allow other third parties (e.g., ad networks and ad
                    servers such as Google Analytics, and other
                    programmatic/AdTech players) to serve tailored ads to you on
                    the Services, and to access their own cookies or similar
                    technologies on your computer, mobile phone, or other device
                    you use to access the Services. We neither have access to,
                    nor does this Privacy Policy govern, the use of cookies or
                    other tracking technologies that may be placed by such third
                    parties. These parties may permit you to opt out of ad
                    targeting. If you are interested in more information about
                    tailored browser advertising and how you can generally
                    control cookies from being put on your computer to deliver
                    tailored advertising (i.e., not just for the Services), you
                    may visit the Network Advertising Initiative's Consumer
                    Opt-Out Link, and/or the Digital Advertising Alliance's
                    Consumer Opt-Out Link to opt-out of receiving tailored
                    advertising from companies that participate in those
                    programs. To opt out of Google Analytics for Display
                    Advertising or customize Google Display Network ads, you can
                    visit the Google Ads Settings page. Please note that to the
                    extent advertising technology is integrated into the
                    Services, you may still receive ads even if you opt-out of
                    tailored advertising. In that case, the ads will just not be
                    tailored to your interests. Also, we do not control any of
                    the above opt-out links and are not responsible for any
                    choices you make using these mechanisms or the continued
                    availability or accuracy of these mechanisms.
                  </p>
                  <p className="list-table-one mb-2">
                    When accessing the Services from a mobile application you
                    may also receive tailored in-application advertisements.
                    Each operating system: iOS, Android and Windows Phone
                    provides its own instructions on how to prevent the delivery
                    of tailored in-application advertisements. You may review
                    the support materials and/or the privacy settings for the
                    respective operating systems in order to opt-out of tailored
                    in-application advertisements. For any other devices and/or
                    operating systems, please visit the privacy settings for the
                    applicable device or operating system or contact the
                    applicable platform operator.
                  </p>
                </div>
                {demofour.map((item, index) => {
                  return (
                    <>
                      <div className="main-text" key={index}>
                        <p className="h4 mb-3">
                          {" "}
                          {index + 12} &nbsp;{item.header}
                        </p>
                        <p className="list-table-one h6 mb-3">
                          {item.subHeader}
                        </p>
                        <ul className="main-private-list list-table-one p-0">
                          {item.list.map((item, index) => {
                            return (
                              <>
                                <li className="mb-2" key={index}>
                                  <span className="fw-semibold">
                                    {item.listkey}
                                  </span>{" "}
                                  {item.listkey === true && ":"} {item.text}
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  );
                })}

                <div className="main-text">
                  <p className="h4 mb-3">20. HOW TO CONTACT US</p>
                  <p className="list-table-one h6 mb-3">
                    To request to review, update, or delete your personal
                    information or to otherwise reach us, please submit a
                    request by e-mailing us at  You may contact us for
                    information on Service Providers, Partners and Group
                    Entities with whom we may share your Data in compliance with
                    this Privacy Policy and applicable law. We will respond to
                    your request within 30 days.
                  </p>
                </div>
                <div className="main-text">
                  <p className="h4 mb-3">21. GRIEVANCE:</p>
                  <p className="list-table-one h6 mb-3">
                    Please find below the details of the grievance:
                  </p>
                  <p className="list-table-one">
                    Email Id:{" "}
                    <a href="mailto:info@rauniyarfoundationbharat.org">
                      info@rauniyarfoundationbharat.org
                    </a>{" "}
                    <span className="fs-5 mx-4">/</span>
                    <a href="mailto:rauniyarfoundation@gmail.com">
                      rauniyarfoundation@gmail.com
                    </a>{" "}
                  </p>
                  <p>
                    The grievance officer is identified above pursuant to the
                    provisions of applicable laws including but not limited to
                    the information Technology Act, 2000 and the Consumer
                    Protection Act, 2019 and the rules enacted under those laws.
                  </p>
                </div>
                <div className="main-text">
                  <p className="h4 list-table-one mb-3 ms-0">
                    PRIVACY POLICY APPLICABLE TO THE EUROPEAN ECONOMIC AREA
                    (EEA) AND THE UNITED KINGDOM (UK)
                  </p>
                  <p className="list-table-one mb-3">
                    1.{" "}
                    <span className="fw-semibold">
                      {" "}
                      RAUNIYAR FOUNDATION BHARAT GROUP
                    </span>{" "}
                    (RFB, we/us/our) is committed to safeguarding the privacy of
                    our customers (you/your). This privacy policy (the Privacy
                    Policy) applies to our customers, potential customers who
                    are in the European Economic Area (EEA) and the United
                    Kingdom (UK). It sets out our personal data collection and
                    sharing practices and informs you of the ways in which we
                    collect personal data, the uses of that personal data and
                    the ways in which we will share any personal data you choose
                    to provide to us. To ensure that you can make informed
                    decisions and feel confident about sharing certain personal
                    data with us, please read this Privacy Policy to understand
                    who we share your Personal Data with and for what purposes
                    and the choices you have concerning how your Personal Data
                    is collected and used by us.
                  </p>
                  <p className="list-table-one mb-3">
                    1. 2. If you are an existing customer of ours, further
                    details about how we use your personal data are set out in
                    your customer contract with us. Further notices highlighting
                    certain uses we wish to make of your personal data together
                    with the ability to opt in or out of selected uses may also
                    be provided when we collect personal data from you.
                  </p>
                  <p className="list-table-one mb-3">
                    3. Our websites/ Platform may contain links to other
                    third-party websites. If you follow a link to any of those
                    third-party websites, please note that they have their own
                    privacy policies and that we do not accept any
                    responsibility or liability for their policies or processing
                    of your personal data. Please check these policies before
                    you submit any personal data to such third-party websites.
                  </p>
                  <p className="list-table-one mb-3">
                    4. This Privacy Policy is intended to explain our privacy
                    practices and covers the following areas:
                  </p>
                  <ul className="demo-list p-0">
                    <li>
                      <p className="list-table-one mb-2">
                        What personal data about you we may collect?
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        How may we use your personal data?
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        Who may we share your personal data with?
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        How we protect your personal data?
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        Contacting us & your rights to prevent marketing and to
                        access and update your personal data?
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        How changes to this Privacy Policy and the Cookies
                        Policy will be made. ta?
                      </p>
                    </li>
                  </ul>
                </div>
                {privacyPolicyData.map((item, index) => (
                  <div key={index} className="main-text">
                    <p className="h3 mb-3">{item.header}</p>
                    <ul className="demo-list" style={{ listStyleType: "disc" }}>
                      {item.list.map((listItem, listIndex) => (
                        <li key={listIndex}>{listItem}</li>
                      ))}
                    </ul>
                  </div>
                ))}
                <div className="main-text">
                  <p className="h3 mb-3">
                    3. WHO WE SHARE YOUR PERSONAL INFORMATION WITH
                  </p>
                  <p className="list-table-one h6 mb-3">
                    We may disclose information about you to any of our, agents,
                    suppliers or subcontractors insofar as reasonably necessary
                    for the purposes set out in this privacy policy. This will
                    include customs and immigrations authorities, airports at
                    which we operate, credit card fraud companies (to detect and
                    prevent fraud), payment operating services, notification
                    channels to notify customers regarding their bookings,
                    flight schedule changes by sending SMS and emails, the
                    departure control system (DCS), the baggage reconciliation
                    system (BRS), advance passenger processing (APP) and
                    customer and border protection (CBP), travel processing
                    system.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    In addition, we may disclose information about you to
                    counterparties, customs and immigration authorities, other
                    government bodies, regulators and law enforcement agencies
                    where required by law, in connection with any legal
                    proceedings or prospective legal proceedings, or to
                    establish, exercise or defend our legal rights including
                    providing information to others for the purposes of crime
                    detection and prevention.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    Except as provided in this Privacy Policy, we will not
                    otherwise provide your information to third parties.
                  </p>
                </div>
                <div className="main-text">
                  <p className="h3 mb-3">
                    4. STORAGE AND SECURITY OF YOUR PERSONAL DATA
                  </p>
                  <p className="list-table-one h6 mb-3">
                    No data transmission over the Internet or website can be
                    guaranteed to be secure from intrusion. However, we maintain
                    commercially reasonable physical, electronic and procedural
                    safeguards to protect your personal data in accordance with
                    data protection legislative requirements.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    All information you provide to us is stored on our or our
                    subcontractors’ secure servers in on outside of the EEA or
                    the UK (i.e. India only) and accessed and used subject to
                    our security policies and standards.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    Where we have given you (or where you have chosen) a
                    password which enables you to access certain parts of our
                    websites, you are responsible for keeping this password
                    confidential and for complying with any other security
                    procedures that we notify you of. We ask you not to share a
                    password with anyone.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    Protecting{" "}
                    <span className="fw-semibold">
                      RAUNIYAR FOUNDATION BHARAT
                    </span>{" "}
                    : We may share your Data when we believe that disclosure is
                    reasonably necessary (a) To comply with a law, regulation or
                    compulsory legal request; (b) To protect{" "}
                    <span className="fw-semibold">
                      RAUNIYAR FOUNDATION BHARAT
                    </span>{" "}
                    GROUP Entity's intellectual property rights; (c) To protect{" "}
                    <span className="fw-semibold">
                      RAUNIYAR FOUNDATION BHARAT
                    </span>{" "}
                    GROUP against harm or financial loss; (d) When we believe
                    disclosure is necessary to protect individuals’ vital
                    interests, or (e) In connection with an investigation of
                    suspected or actual fraudulent or illegal activity. This may
                    include exchanging information with other companies and
                    organizations for fraud protection, risk management and
                    dispute resolution.
                  </p>
                </div>
                <div className="main-text">
                  <p className="h3 mb-3">
                    5. PROCESSING OUTSIDE THE EEA AND THE UK
                  </p>
                  <p className="list-table-one h6 mb-3">
                    We will sometimes transfer personal data to other
                    jurisdictions, including destinations outside the country in
                    which you are located, whose data protection laws may not be
                    adequate or of the same level as in your country. We will,
                    in all circumstances, safeguard personal data as set out in
                    this Privacy Policy.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    Where your personal data is transferred from an entity
                    inside the European Economic Area (the EEA) or the UK (as
                    the case may be) to outside the EEA or the UK (as the case
                    may be), we may be required to take specific additional
                    measures to safeguard the relevant personal data. Where we
                    transfer your personal data, we will establish legal grounds
                    justifying such transfer, or otherwise to ensure the
                    appropriate security of your personal data.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    Storage limits: Our retention periods for personal data are
                    based on business needs and legal requirements. We retain
                    personal data for as long as is necessary for the processing
                    purpose(s) for which the information was collected, and any
                    other permissible, related purpose. For example, we may
                    retain certain transaction details and correspondence until
                    the time limit for claims arising from the transaction has
                    expired, or to comply with regulatory requirements regarding
                    the retention of such data.
                  </p>
                </div>
                <div className="main-text">
                  <p className="h3 mb-3">6. YOUR RIGHTS & CONTACTING US</p>
                  <p className="list-table-one h6 mb-3">
                    Marketing: You have the right to ask us not to process your
                    personal data for marketing purposes. We will inform you if
                    we intend to use your information for such purposes or if we
                    intend to disclose your information to any third party for
                    such purposes. Where you do not wish to receive marketing
                    from us, you can decline or withdraw your consent at any
                    time, either by choosing not to select the relevant check
                    boxes on our subscription form, by clicking the unsubscribe
                    link in any marketing message received from us, or by
                    contacting us here.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    Updating Information: We will use reasonable endeavours to
                    ensure that your personal data is accurate. In order to
                    assist us with this, you should notify us of any changes to
                    the personal data that you have provided to us by contacting
                    us as set out in the “Contacting Us” section below. Please
                    note that it is your responsibility to ensure that your
                    booking information or flight itinerary is accurate.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    Your rights: If you have any questions in relation to our
                    use of your personal data, you should first contact us as
                    per the “Contacting Us” section below. Under certain
                    conditions, you may have the right to require us to:
                  </p>
                  <ul className="demo-list p-0">
                    <li>
                      <p className="list-table-one mb-2">
                        Provide you with further details on the use we make of
                        your information.
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        Provide you with a copy of information that you have
                        provided to us;
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        Update any inaccuracies in the personal data we hold
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        Delete any personal data that we no longer have a lawful
                        ground to use;
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        Where processing is based on consent, withdraw your
                        consent so that we stop that particular processing.
                        Please note that in relation to health information, this
                        will prevent us from reacting to any health condition
                        you may have;
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        To ask us to transmit the personal data you have
                        provided to us and we still hold about you to a third
                        party electronically;
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        Object to any processing based on the legitimate
                        interests ground unless our reasons for undertaking that
                        processing outweigh any prejudice to your data
                        protection rights; and
                      </p>
                    </li>
                    <li>
                      <p className="list-table-one mb-2">
                        Restrict how we use your information whilst a complaint
                        is being investigated.
                      </p>
                    </li>
                  </ul>
                  <p className="list-table-one h6 mb-3">
                    Your exercise of these rights is subject to certain
                    exemptions to safeguard the public interest (e.g. the
                    prevention or detection of crime) and our interests (e.g.
                    the maintenance of legal privilege). If you exercise any of
                    these rights, we will check your entitlement and respond in
                    most cases within 90 days from the date of receipt of any
                    such request.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    If you are not satisfied with our use of your personal data
                    or our response to any exercise of these rights, you have
                    the right to complain to the relevant data protection
                    authority.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    Contacting us: If you have any questions in relation to this
                    policy, please contact us at {" "}
                    <a
                      href="https://rauniyarfoundationbharat.org/"
                      target="_blank"
                    >
                      info@rauniyarfoundationbharat.org
                    </a>
                  </p>
                </div>
                <div className="main-text">
                  <p className="h3 mb-3">
                    7. Changes to our Privacy Policy and/or Cookies Policy
                  </p>
                  <p className="list-table-one h6 mb-3">
                    and/or Cookies Policy We may change the content of our
                    websites and how we use cookies and consequently our Privacy
                    Policy and our Cookie Policy may change from time to time in
                    the future. If we change this Privacy Policy or our Cookies
                    Policy, we will update the date it was last changed below.
                    If these changes are material, we will indicate this clearly
                    on our website.
                  </p>
                  <p className="list-table-one h6 mb-3">
                    This Privacy Policy was last updated on 06 Sep 2024.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PratePolicyPopup;
