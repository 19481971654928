import React, { useState } from "react";
import "./contactus.css";
import axios from "axios";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");

  const onContactData = () => {
    let data = {
      name: name,
      email: email,
      mobile: phone,
      message: msg,
    };

    const CONTACT_URL =
      "https://script.google.com/macros/s/AKfycbyzNBCNlKNHjH4zAemjD0QIAhgAsqqelNODTYb7akAIzOY5uuzpm5PKLNhFXuhEb3Okxw/exec";
    axios
      .post(CONTACT_URL, data, {
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

    if (name === "" || email === "" || phone === "" || msg === "") {
      toast.error("Please fill in all fields.");
      return;
    }
    toast.success("Your Details are save  successful!");

    setName("");
    setEmail("");
    setPhone("");
    setMsg("");
  };

  return (
    <>
      <section className="moa-sec">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center w-100 py-3">
            <div className="main-contact row align-items-center w-100 h-100 gy-3">
              <div className="col-md-6">
                <div className="contactus-img w-100 overflow-hidden">
                  <img
                    src={require("../../assetes/img/contactus.avif")}
                    alt="Contact Us"
                    className="w-100 object-fit-cover"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <div className="contactus-card p-4 border rounded-2 w-100">
                    <div className="contactus-card-form row gy-3 align-items-center w-100 ">
                      <div className="col-lg-12 col-md-6 d-flex flex-column">
                        <label htmlFor="name" className="mb-2 fw-semibold">
                          Your Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          placeholder="Your name"
                          className="contactus-input"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 d-flex flex-column">
                        <label htmlFor="email" className="mb-2 fw-semibold">
                          Your E-mail Address
                        </label>
                        <input
                          type="email"
                          id="email"
                          placeholder="Your email address"
                          className="contactus-input"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 d-flex flex-column">
                        <label htmlFor="phone" className="mb-2 fw-semibold">
                          Your Phone Number
                        </label>
                        <input
                          type="text"
                          id="phone"
                          placeholder="Your phone number"
                          className="contactus-input"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          maxLength={10}
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 d-flex flex-column">
                        <label htmlFor="message" className="mb-2 fw-semibold">
                          Your Message
                        </label>
                        <textarea
                          id="message"
                          cols={10}
                          rows={5}
                          placeholder="Write your message"
                          className="contactus-text"
                          value={msg}
                          onChange={(e) => setMsg(e.target.value)}
                          required
                        ></textarea>
                      </div>

                      <div className="col-lg-12 col-md-6">
                        <button className="w-100" onClick={onContactData}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
