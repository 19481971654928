import React from "react";
import "./pages.css";
const TermsAndCondition = () => {
  const termsAndConditions = [
    {
      clause:
        "By participating in the event, you consent to the use of images and videos featuring you for marketing and advertising purposes, including but not limited to social media, websites, out-of-home advertising, print ads, and other promotional materials. If you wish to request the removal of a specific image or video, please contact us at info@rauniyarfoundationbharat.org.",
    },
    {
      clause:
        "By registering your contact information with the Rauniyar Foundation Bharat Half Marathon, you consent to us using the information to respond to queries directed by you and to reach out to you for upcoming events registered as part of Rauniyar Foundation Bharat Half Marathon.",
    },
    {
      clause:
        "The content of the pages of this website is for your general information and use only. It is subject to change without notice.",
    },
    {
      clause:
        "This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, personal information may be stored by us for use by third parties.",
    },
    {
      clause:
        "Neither <strong className='fw-bold'>RAUNIYAR FOUNDATION BHARAT GROUP</strong> nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.",
    },
    {
      clause:
        "Your use of any information or materials on this website is entirely at your own risk, for which <strong className='fw-bold'>RAUNIYAR FOUNDATION BHARAT GROUP</strong> shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.",
    },
    {
      clause:
        "This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.",
    },
    {
      clause:
        "The <strong className='fw-bold'>RAUNIYAR FOUNDATION BHARAT GROUP</strong> TM and all products and logos denoted with “TM” or “®” are trademarks or registered trademarks of <strong className='fw-bold'>RAUNIYAR FOUNDATION BHARAT GROUP</strong> or its affiliates. <strong className='fw-bold'>RAUNIYAR FOUNDATION BHARAT GROUP</strong> trademarks may not be used in connection with any product or service that is not a <strong className='fw-bold'>RAUNIYAR FOUNDATION BHARAT GROUP</strong> product, function, or service.",
    },
    {
      clause:
        "Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.",
    },
    {
      clause:
        "From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that <strong className='fw-bold'>RAUNIYAR FOUNDATION BHARAT GROUP</strong> endorses the website(s). <strong className='fw-bold'>RAUNIYAR FOUNDATION BHARAT GROUP</strong> has no responsibility for the content of the linked website(s).",
    },
    {
      clause:
        "Applicable Law and Jurisdiction of this WEBSITE are governed by and to be interpreted in accordance with laws of India, without regard to the choice or conflicts of law provisions of any jurisdiction. The user/site visitor agrees that in the event of any dispute arising in relation to this Disclaimer or any dispute arising in relation to the website whether in contract or tort or otherwise, to submit to the jurisdiction of the courts located at SURAT only for the resolution of all such disputes.",
    },
  ];

  return (
    <>
      <section className="moa-sec">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center w-100">
            <div className="termsandconditon-sec p-2">
              <div className="chairman-title d-flex align-items-center px-2 py-1">
                <h1 className="main-text-vicechairman-title">
                  Terms and Consdition
                </h1>
              </div>
              <div className="termsandcondition-header-text">
                <p className="mb-4">
                  Visitors to this web site & participants of the event are
                  bound by the following terms and conditions (“terms”). So,
                  please read the terms carefully before continuing to use this
                  site. If you do not agree with any of these terms, please do
                  not use this site.
                </p>
                <p>
                  The use of this website is subject to the following terms of
                  use: 
                </p>
              </div>
              <ul>
                {termsAndConditions.map((item, index) => {
                  return (
                    <li key={index} className="list-table-one">
                      <span dangerouslySetInnerHTML={{ __html: item.clause }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;
