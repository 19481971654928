import React from "react";
import "./about.css";
import { BsChevronDoubleRight } from "react-icons/bs";
const Aboutus = () => {
  return (
    <>
      <section className="moa-sec">
        <div className="aboutus w-100 d-flex align-items-center ">
          <div className="container">
            <div className="aboutus-header d-flex gap-3 align-items-center text-light">
              <div className="header-img overflow-hidden">
                <img
                  src={require("../../assetes/img/header-logo.jpg")}
                  alt=""
                  className="w-100 object-git-cover"
                />
              </div>
              <p className="aboutus-main-title mb-0 fw-semibold">
                RAUNIYAR FOUNDATION BHARAT
              </p>
            </div>
          </div>
        </div>
        <div className="container py-3 px-2 d-flex align-items-center w-100">
          <div className="main-box mt-3">
            <div className="first-box list-table-one">
              Rauniyar Foundation Bharat, through Rauniyar LifeStyle Private
              Limited, has significantly contributed to and made measurable
              progress toward its vision of an inclusive India. Moving well
              beyond its business activities, the Suresh Kumar & Son’s Group
              contributes to a positive societal impact through diverse
              community engagement initiatives. Led by Mr. Vijay Kumar Gupta &
              Mrs. Archana Patel Gupta, Rauniyar Foundation Bharat was set up in
              2020 to provide impetus to various philanthropic initiatives of
              Suresh Kumar & Son’s. The efforts of the group have already
              decided to touch the lives of more than 75000 people across India
              in more than 13 states and several urban locations. The Foundation
              has a comprehensive development approach. Its prime objective is
              to create and support meaningful activities through innovative
              institutions to address some of India’s most pressing
              developmental challenges in the education, sports & etc. The
              Foundation lays significant emphasis on leveraging technology for
              developmental solutions. Following its model of direct engagement,
              the Foundation directly reaches out to the affected communities
              through trained professionals. In coming years, Rauniyar
              Foundation Bharat initiatives will be continued to evolve, while
              remaining responsive to the ever-changing needs of society. The
              Foundation is currently focusing on creating change in areas of
              Women Empowerment, Rural Transformation, Health, and Education,
              Sports for Development, Disaster Response, Arts, Culture &
              Heritage and Urban Renewal. To maximize its reach and improve
              outcomes, Rauniyar Foundation Bharat has also established
              strategic partnerships with organizations that have the technical
              expertise and experience to undertake various programmers in the
              identified focus areas of operation.
            </div>
            <div className="my-3">
              <p className="chairman-text fw-semibold">
                <BsChevronDoubleRight className="fs-5 me-2" />
                Vijay Kumar Gupta Founder and Chairman, Rauniyar Foundation
                Bharat <br />
                <br />
                Vijay Kumar Gupta is a Director of Rauniyar LifeStyle Private
                Limited. & Founder of S Kumar & Son’s, Aashi Trade Link,
                Himalaya International, Bagmati International and Sui Dhaaga
                Fashion Studio,Board member of VaishVyapar NEWS channel and
                Nyaas Samiti member of SHRI RAM JANKI MATH NYAAS
                SAMITI-BAIRGANIA,BHARAT{" "}
              </p>
            </div>
            <div className="my-3">
              <p className="chairman-text fw-semibold">
                <BsChevronDoubleRight className="fs-5 me-2" />
                Archana Patel Gupta Co-Founder and Vice Chairman of Rauniyar
                Foundation Bharat <br />
                <br /> Archana Gupta is a Fashion Designer, Director of Rauniyar
                LifeStyle Private Limited, Owner of Aashi Trade Link and Nyaas
                Samiti member of SHRI RAM JANKI MATH NYAAS SAMITI-BAIRGANIA,
                BHARAT
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
