import React, { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import CreatableSelect from "react-select/creatable";
import ArrayObj from "../../../Constant/arrayObj";
import Pako from "pako";
import { useForm } from "react-hook-form";
import "./cricketleage.css";

const CricketTeamForm = () => {
  const initialPlayers = Array.from({ length: 15 }, () => ({
    name: "",
    dob: "",
    age: "",
    position: "",
    arm: "",
    id_proof: "",
    health: "",
    tshirtSize: "",
    pentSize: "",
  }));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const [showModal, setShowModal] = useState(false);
  const [players, setPlayers] = useState(initialPlayers);
  const [schoolName, setSchoolName] = useState("");
  const fileInputRef = useRef(null);
  const [teamName, setTeamName] = useState("");
  const [couchFName, setCouchFName] = useState("");
  const [couchLName, setCouchLName] = useState("");
  const [category, setCategory] = useState("");
  const [teamPhoto, setTeamPhoto] = useState("");

  const options = ArrayObj.schoolList.map((item) => ({
    value: item.name.toLowerCase(),
    label: item.name,
    other: item.is_other,
  }));

  useEffect(() => {}, [category, teamPhoto]);

  const HandleAddSchool = (name) => {
    if (name) {
      setSchoolName(name.value);
    }
  };

  const handleLabelClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // covert img in base64

  const uint8ArrayToBase64 = (uint8Array) => {
    const binaryString = Array.from(uint8Array)
      .map((byte) => String.fromCharCode(byte))
      .join("");
    return window.btoa(binaryString);
  };

  const onConvertImg = async (data) => {
    let compressedBase64String = "";

    const event = data;

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        compressedBase64String = compressBase64(base64String);
        setTeamPhoto(compressedBase64String);
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    }
  };

  const base64ToUint8Array = (base64) => {
    const binaryString = window.atob(base64.split(",")[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  };

  const compressBase64 = (base64String) => {
    const uint8Array = base64ToUint8Array(base64String);

    const compressed = Pako.deflate(uint8Array);

    return uint8ArrayToBase64(compressed);
  };

  const handleInputChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][field] = value;

    if (field === "dob") {
      const birthDate = new Date(value);
      const userDate = new Date();
      let age = userDate.getFullYear() - birthDate.getFullYear();

      updatedPlayers[index].age = age;
    }

    if (field === "id_proof") {
      let file = value;

      let compressedBase64String = "";
      if (file) {
        const render = new FileReader();
        render.onloadend = () => {
          const base64String = render.result;
          compressedBase64String = compressBase64(base64String);
          updatedPlayers[index].id_proof = compressedBase64String;
          setPlayers(updatedPlayers);
        };

        render.readAsDataURL(file);
      }
    }

    setPlayers(updatedPlayers);
  };

  const handleSave = (data) => {
    console.log("players", data);
    console.log("players==>", players);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        Register Your Team
      </button>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable position-relative">
          <div className="modal-content cricket_modal">
            <div
              className="close-btn cricket-btn  position-absolute"
              style={{ cursor: "pointer" }}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <IoMdClose className="close-modal" />
            </div>

            <div className="w-100">
              <div className="form-section page-section">
                <div className="form-input-wide" data-type="control_head">
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <div
                      className="form-header-group hasImage header-large w-100"
                      data-imagealign="Top"
                    >
                      <div className="header-logo w-100 overflow-hidden">
                        <img
                          src={require("../../../assetes/img/cricket_tennis.png")}
                          alt="CRICKET TOURNAMENT"
                          className="header-logo-top w-100 object-fit-cover"
                        />
                      </div>
                      <div className="d-flex justify-content-center w-100 my-3">
                        <div
                          className="bpl-logo overflow-hidden"
                          style={{ width: "25%" }}
                        >
                          <img
                            src={require("../../../assetes/ourassosiates/bpl-logo.png")}
                            alt=""
                            className="w-100 object-fit-cover"
                          />
                        </div>
                      </div>
                      <div className="cricket-form-header d-flex flex-column">
                        <div className="header-text d-flex justify-content-center align-items-center w-100">
                          <p
                            id="header_73"
                            className="form-header h1 cricket-header-text text-center"
                            data-component="header"
                          >
                            Bairgania Premier League 2024 <br />
                            First Edition Tennis Ball Cricket Tournament
                          </p>
                        </div>
                        <p
                          id="subHeader_73"
                          className="form-subHeader h5  text-center mt-3"
                        >
                          Registration Form
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container p-4">
                  <div className="row align-items-center w-100 gy-3 gx-2">
                    <div className="col-md-6">
                      <div>
                        <label className="race-details fw-bold mb-2">
                          Team Name
                        </label>
                        <div
                          id="cid_80"
                          className="form-input-wide"
                          data-layout="half"
                        >
                          <input
                            type="text"
                            {...register("teamName", {
                              required: "Enter Your Team Name..",
                            })}
                            placeholder="Enter Team Name"
                            className="race-category"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                          />
                        </div>
                        {errors.teamName && (
                          <p className="invalid">{errors.teamName.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="race-details fw-bold mb-2">
                        Coach Name
                      </label>
                      <div className="row g-2">
                        <div className="col-md-6">
                          <div>
                            <input
                              type="text"
                              {...register("coach_fname", {
                                required: "Enter first name",
                              })}
                              className="race-category w-100"
                              placeholder="First Name"
                              value={couchFName}
                              onChange={(e) => setCouchFName(e.target.value)}
                            />
                          </div>
                          {errors.coach_fname && (
                            <p className="invalid">
                              {errors.coach_fname.message}
                            </p>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div>
                            <input
                              type="text"
                              {...register("coach_lname", {
                                required: "Enter last name",
                              })}
                              className="race-category w-100"
                              placeholder="Last Name"
                              value={couchLName}
                              onChange={(e) => setCouchLName(e.target.value)}
                            />
                            {errors.coach_lname && (
                              <p className="invalid">
                                {errors.coach_lname.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label className="race-details fw-bold my-3">
                          School/College Name
                        </label>
                        <CreatableSelect
                          isClearable
                          options={options}
                          onChange={HandleAddSchool}
                          className="school-select"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: "1px solid gray",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            colors: {
                              ...theme.colors,
                              primary: "gray",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label className="race-details fw-bold my-3">
                          Tournament Category
                        </label>
                        <select
                          {...register("match_category", {
                            required: "Select category",
                          })}
                          className="race-category"
                          defaultValue={category}
                          onClick={(e) => setCategory(e.target.value)}
                        >
                          <option value="" selected disabled>
                            Select Category
                          </option>
                          <option value="U_19">U-19</option>
                          <option value="open">Open</option>
                        </select>
                        {errors.match_category && (
                          <p className="invalid">
                            {errors.match_category.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label className="race-details fw-bold my-3">
                        Player Details
                      </label>
                      <div className="table-responsive">
                        <table className="player_details mb-3">
                          <thead>
                            <tr className="form-matrix-tr form-matrix-header-tr">
                              <th className="form-matrix-th table-header">
                                No
                              </th>
                              <th
                                scope="col"
                                className="form-matrix-headers table-header"
                              >
                                <label id="label_81_col_0">Name</label>
                              </th>
                              <th
                                scope="col"
                                className="form-matrix-headers table-header"
                              >
                                <label id="label_81_col_1">DOB</label>
                              </th>
                              <th
                                scope="col"
                                className="form-matrix-headers table-header"
                              >
                                <label id="label_81_col_1">Age</label>
                              </th>
                              <th
                                scope="col"
                                className="form-matrix-headers table-header"
                              >
                                <label id="label_81_col_2">Position</label>
                              </th>
                              <th
                                scope="col"
                                className="form-matrix-headers table-header"
                              >
                                <label id="label_81_col_3">Arm</label>
                              </th>
                              <th
                                scope="col"
                                className="form-matrix-headers table-header"
                              >
                                <label id="label_81_col_3">ID Proof</label>
                              </th>
                              <th
                                scope="col"
                                className="form-matrix-headers table-header"
                              >
                                <label id="label_81_col_3">
                                  Health History
                                </label>
                              </th>
                              <th
                                scope="col"
                                className="form-matrix-headers table-header"
                              >
                                <label id="label_81_col_4">T-shirt Size</label>
                              </th>
                              <th
                                scope="col"
                                className="form-matrix-headers table-header"
                              >
                                <label id="label_81_col_4">Trousers Size</label>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {players.map((player, index) => (
                              <tr
                                key={index}
                                className="form-matrix-tr form-matrix-value-tr"
                              >
                                <th
                                  scope="row"
                                  className="form-matrix-headers table-header"
                                >
                                  {index + 1}
                                </th>
                                <td className="form-matrix-values player-field name-feild-table">
                                  <div>
                                    <input
                                      type="text"
                                      {...register(`players[${index}].name`, {
                                        required: "Enter Player name",
                                      })}
                                      placeholder="Enter Name"
                                      value={player.name}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "name",
                                          e.target.value
                                        )
                                      }
                                      className="race-category"
                                    />
                                    {errors?.players?.[index]?.name && (
                                      <p className="invalid">
                                        {errors.players[index].name.message}
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td className="form-matrix-values player-field">
                                  <div>
                                    <input
                                      type="date"
                                      {...register(`players[${index}].dob`, {
                                        required: "Enter Player DOB",
                                      })}
                                      placeholder="Enter Date of birth"
                                      value={player.dob}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "dob",
                                          e.target.value
                                        )
                                      }
                                      className="race-category"
                                    />
                                    {errors?.players?.[index]?.dob && (
                                      <p className="invalid">
                                        {errors.players[index].dob.message}
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td className="form-matrix-values player-field">
                                  <input
                                    type="number"
                                    placeholder="Enter Age"
                                    value={player.age > 0 ? player.age : ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "age",
                                        e.target.value
                                      )
                                    }
                                    className="race-category"
                                    disabled
                                  />
                                </td>
                                <td className="form-matrix-values player-field">
                                  <div>
                                    <input
                                      type="text"
                                      {...register(
                                        `players[${index}].position`,
                                        {
                                          required: "Enter Player's Position",
                                        }
                                      )}
                                      placeholder="Enter Position"
                                      value={player.position}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "position",
                                          e.target.value
                                        )
                                      }
                                      className="race-category"
                                    />
                                    {errors?.players?.[index]?.position && (
                                      <p className="invalid">
                                        {errors.players[index].position.message}
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td className="form-matrix-values player-field">
                                  <div>
                                    <select
                                      {...register(`players[${index}].arm`, {
                                        required: "Enter Player's Arm",
                                      })}
                                      value={player.arm}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "arm",
                                          e.target.value
                                        )
                                      }
                                      className="race-category"
                                    >
                                      <option value="" disabled selected>
                                        Select Arm
                                      </option>
                                      <option value="Left">Left</option>
                                      <option value="Right">Right</option>
                                    </select>
                                    {errors?.players?.[index]?.arm && (
                                      <p className="invalid">
                                        {errors.players[index].arm.message}
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td className="form-matrix-values player-field">
                                  <div>
                                    <input
                                      {...register(
                                        `players[${index}].id_proof`,
                                        {
                                          required: "ID proof is required",
                                        }
                                      )}
                                      type="file"
                                      placeholder="Enter id"
                                      // value={player.id_proof}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "id_proof",
                                          e.target.files[0]
                                        )
                                      }
                                      className="race-category"
                                    />
                                    {errors?.players?.[index]?.id_proof && (
                                      <p className="invalid">
                                        {errors.players[index].id_proof.message}
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td className="form-matrix-values player-field">
                                  <div>
                                    <select
                                      {...register(`players[${index}].health`, {
                                        required:
                                          "Select Player's health issue",
                                      })}
                                      value={player.health}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "health",
                                          e.target.value
                                        )
                                      }
                                      className="race-category t-shirt"
                                    >
                                      <option value="" disabled selected>
                                        Select Health History
                                      </option>

                                      {ArrayObj.Historus.map((item, idx) => (
                                        <option key={idx} value={item.value}>
                                          {item.value}
                                        </option>
                                      ))}
                                    </select>
                                    {errors?.players?.[index]?.health && (
                                      <p className="invalid">
                                        {errors.players[index].health.message}
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td className="form-matrix-values player-field">
                                  <div>
                                    <select
                                      {...register(
                                        `players[${index}].tshirtSize`,
                                        {
                                          required:
                                            "Select Player's t-shirt size",
                                        }
                                      )}
                                      value={player.tshirtSize}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "tshirtSize",
                                          e.target.value
                                        )
                                      }
                                      className="race-category t-shirt"
                                    >
                                      <option value="" disabled selected>
                                        Select T-shirt Size
                                      </option>

                                      {ArrayObj.tshirtSize.map((item, idx) => (
                                        <option key={idx} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    </select>
                                    {errors?.players?.[index]?.tshirtSize && (
                                      <p className="invalid">
                                        {
                                          errors.players[index].tshirtSize
                                            .message
                                        }
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td className="form-matrix-values player-field">
                                  <div>
                                    <select
                                      {...register(
                                        `players[${index}].pentSize`,
                                        {
                                          required: "Select Player's pent size",
                                        }
                                      )}
                                      value={player.pentSize}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "pentSize",
                                          e.target.value
                                        )
                                      }
                                      className="race-category"
                                    >
                                      <option value="" disabled>
                                        Select Trouser Size
                                      </option>
                                      {ArrayObj.trouserSize.map((item, idx) => (
                                        <option key={idx} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    </select>
                                    {errors?.players?.[index]?.pentSize && (
                                      <p className="invalid">
                                        {errors.players[index].pentSize.message}
                                      </p>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label className="race-details fw-bold mb-2">
                          Mailing Address
                        </label>
                        <div>
                          <input
                            type="email"
                            placeholder="Enter email"
                            className="race-category"
                            {...register("mailingAddress", {
                              required: "Mailing Address is required",
                            })}
                          />
                          {errors.mailingAddress && (
                            <p className="text-danger">
                              {errors.mailingAddress.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div>
                        <label className="race-details fw-bold mb-2">
                          Phone Number
                        </label>
                        <div>
                          <input
                            type="tel"
                            placeholder="Enter phone Number"
                            className="race-category"
                            {...register("phoneNumber", {
                              required: "Phone Number is required",
                            })}
                          />
                          {errors.phoneNumber && (
                            <p className="text-danger">
                              {errors.phoneNumber.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div>
                        <label htmlFor="" className="race-details fw-bold mb-2">
                          Address
                        </label>
                        <textarea
                          rows={4}
                          className="race-details fw-semibold mb-2 w-100 text-area-add"
                          {...register("address", {
                            required: "Address is required",
                          })}
                        ></textarea>
                        {errors.address && (
                          <p className="text-danger">
                            {errors.address.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div>
                        <label className="race-details fw-bold mb-2">
                          City
                        </label>
                        <input
                          type="text"
                          placeholder="Enter City"
                          className="race-category"
                          {...register("city", {
                            required: "City is required",
                          })}
                        />
                        {errors.city && (
                          <p className="text-danger">{errors.city.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div>
                        <label className="race-details fw-bold mb-2">
                          State
                        </label>
                        <input
                          type="text"
                          placeholder="Enter state"
                          className="race-category"
                          {...register("state", {
                            required: "State is required",
                          })}
                        />
                        {errors.state && (
                          <p className="text-danger">{errors.state.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div>
                        <label className="race-details fw-bold mb-2">
                          Pin code*
                        </label>
                        <input
                          id="pin_code"
                          type="tel"
                          className="race-category"
                          placeholder="Enter Pin code"
                          inputMode="numeric"
                          {...register("pinCode", {
                            required: "Pin code is required",
                          })}
                        />
                        {errors.pinCode && (
                          <p className="text-danger">
                            {errors.pinCode.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div>
                        <label className="race-details fw-bold my-3">
                          Emergency Contact
                        </label>
                        <input
                          type="tel"
                          placeholder="Enter Emergency Contact Number"
                          className="race-category"
                          inputMode="numeric"
                          {...register("emergencyContact", {
                            required: "Emergency Contact is required",
                          })}
                        />
                        {errors.emergencyContact && (
                          <p className="text-danger">
                            {errors.emergencyContact.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div>
                        <label className="race-details fw-bold my-3">
                          Doctor's Contact
                        </label>
                        <input
                          type="tel"
                          placeholder="Enter Doctor Contact Number"
                          className="race-category"
                          inputMode="numeric"
                          {...register("doctorContact", {
                            required: "Doctor's Contact is required",
                          })}
                        />
                        {errors.doctorContact && (
                          <p className="text-danger">
                            {errors.doctorContact.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div>
                        <div
                          className="jfQuestion-fields"
                          data-wrapper-react="true"
                        >
                          <div
                            className="jfField isFilled"
                            onClick={handleLabelClick}
                          >
                            <div className="jfUpload-wrapper">
                              <div className="jfUpload-files-container">
                                <label
                                  className="form-label upload-demo form-label-top form-label-auto w-100"
                                  style={{ cursor: "pointer" }}
                                >
                                  Upload Team Photo
                                </label>
                                <input
                                  type="file"
                                  // {...register("teamPhoto", {
                                  //   required: "Team photo is required",
                                  // })}
                                  className="form-upload-multiple"
                                  style={{ display: "none" }}
                                  ref={fileInputRef}
                                  onChange={(e) => onConvertImg(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="submit-cricket-form"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="submit-cricket-form submit-form"
                onClick={handleSubmit(handleSave)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CricketTeamForm;
