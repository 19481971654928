import React from "react";
import "./about.css";
import { FaArrowRightLong } from "react-icons/fa6";
const AOA = () => {
  return (
    <>
      <section className="moa-sec">
        <div className="container">
          <div className="chairman-title  d-flex align-items-center px-2 py-1 mb-3">
            <h1 className="main-text-vicechairman-title">
              Articles of Association (AOA)
            </h1>
          </div>
          <div className="main-area">
            <ul>
              <li className="">
                <p className="aoa-text">
                  Table applicable to company as notified under schedule I of
                  the Companies Act, 2013 (F, G, H) &nbsp;&nbsp;&nbsp;
                  <FaArrowRightLong />
                  <span className="enlarged-letter fw-semibold">F</span>
                </p>
                <p className="aoa-text">
                  Table F / G / H (basis on the selection of above-mentioned
                  field) as notified under schedule I of the companies Act, 2013
                  is applicable to
                </p>
                <p className="aoa-text">(F – a company limited by shares)</p>
                <p className="aoa-text">
                  G – a company limited by guarantee and having a share capital
                </p>
                <p className="aoa-text">
                  H – a company limited by guarantee and not having share
                  capital) &nbsp;&nbsp;&nbsp;
                  <FaArrowRightLong /> &nbsp;
                  <span className="fw-semibold">
                    F - A COMPANY LIMITED BY SHARES
                  </span>
                </p>
              </li>
              <li className="d-flex gap-5">
                <p className="aoa-text ">
                  The name of the company is &nbsp;&nbsp;&nbsp;{" "}
                  <FaArrowRightLong /> &nbsp;{" "}
                  <span className="fw-semibold">
                    {" "}
                    RAUNIYAR FOUNDATION BHARAT
                  </span>
                </p>
              </li>
              <li className="d-flex gap-5">
                <table className="table table-bordered  table-striped-columns text-center">
                  <tr>
                    <th>Article No.</th>
                    <th>Description </th>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Transmission of shares</th>
                  </tr>

                  <tr>
                    <td>
                      <p>&#x2160;</p>
                    </td>
                    <td>
                      <ul className="list-table">
                        <li>
                          In these regulations the Act means the Companies Act
                          2013 the seal means the common seal of the
                          company.Unless the context otherwise requires words or
                          expressions contained in these regulations shall bear
                          the same meaning as in the Act or any statutory
                          modification thereof in force at the date at which
                          these regulations become binding on the
                          company.Thecompany is a Private Company within the
                          meaning of Section 2 (68) of the Companies Act 2013
                          and accordingly-(i) restricts the right to transfer
                          its shares(ii) except in case of One Person Company
                          limits the number of its members to two hundred
                          Provided that where two or more persons hold one or
                          mores hares in a company jointly they shall for the
                          purposes of this clause be treated as a single member
                          .Provided further that (A) Persons who are in the
                          employment of the company and(B)Persons who having
                          been formerly in the employment of the company were
                          members of the company while in that employment and
                          have continued to be members after the Employment
                          ceased Shall not be included in the number of members
                          and (iii) prohibits any invitation to the public to
                          subscribe for any securities of the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Share Capital and Variation of rights</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&#8545; &nbsp; 1</p>
                    </td>
                    <td>
                      <ul className="list-table-one ">
                        <li>
                          Subject to the provisions of the Act and these
                          Articles the shares in the capital of the company
                          shall be under the control of the Directors who may
                          issue allot or otherwise dispose of the same or any of
                          them to such persons in such proportion and on such
                          terms and conditions and either at a premium or at par
                          and at such time as they may from time to time think
                          fit.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 2</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Every person whose name is entered as a member in the
                          register of members shall be entitled to receive
                          within two months after incorporation in case of
                          subscribers to the memorandum or after allotment or
                          within one month after the application for the
                          registration of transfer or &nbsp; transmission or
                          within such other period as the conditions of issue
                          shall be pr ovided one certi ficate for all his shares
                          without payment of any charges or se veral certi
                          ficateseach for one or more of his sharesupon payment
                          of twenty rupees for each certi ficate after the
                          first. E very certi ficate shall be under the seal and
                          shall specify the shares to which it relates and the
                          amount paid - up thereon. In respect of any share or
                          shares held jointly by se veral persons the company
                          shall not be bound to issue more than one certi ficate
                          and delivery of a certi ficate for a share to one of
                          se veral joint holders shall be su fficient delivery
                          to all such holders.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 3</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          If any share certi ficate be worn out defaced
                          mutilated or torn or if there be no further space on
                          the back for endorsement of transfer then upon
                          production and surrender thereof to the company a new
                          certi ficate ma y be issued in lieu thereof and if any
                          certi ficate is lost or destr o yed then upon proof
                          thereof to the satisfaction of the company and on
                          execution of such indemnity as the company deem
                          adequate a new certi ficate in lieu thereof shall be
                          given. E very certi ficate under this Article shall be
                          issued on payment of twenty rupees for each certi
                          ficate. The pr ovisions of Articles(2) and(3) shall
                          mutatis mutandis apply to debentures of the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 4</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Except as required by law no person shall be
                          recognised by the company as holding any share upon
                          any trust and the company shall not be bound by or be
                          compelled in any way to recognise (e ven when having
                          notice thereof ) any equitable contingent future or
                          partial interest in any share or any interest in any
                          fractional part of a share or (except only as by these
                          regulations or by law otherwise pr ovided) any other
                          rights in respect of any share except an absolute
                          right to the entirety thereof in the registered
                          holder.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 5</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The company may exercise the powers of paying
                          commissions conferred by sub-section (6) of section 40
                          pr ovided that the rate per cent or the amount of the
                          commission paid or agreed to be paid shall be
                          disclosed in the manner required by that section and
                          rules made thereunder. The rate or amount of the
                          commission shall not exceed the rate or amount
                          prescribed in rules made under sub-section (6) of
                          section 40. The commission may be satis fied by the
                          payment of cash or the allotment of fully or partly
                          paid shares or partly in the one way and partly in the
                          other.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 6</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          If at any time the share capital is divided into
                          different classes of shares the rights attached to any
                          class (unless otherwise pr ovided by the terms of
                          issue of the shares of that class) may subject to the
                          pr ovisions of section 48 and whether or not the
                          company is being wound up be varied with the consent
                          in writing of the holders of three-fourths of the
                          issued shares of that class or with the sanction of a
                          special resolution passed at a separate meeting of the
                          holders of the shares of that class. To e very such
                          separate meeting the pr ovisions of these regulations
                          relating to general meetings shall mutatis mutandis
                          apply but so that the necessar y quorum shall be at
                          least two persons holding at least one-third of the
                          issued shares of the class in question.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 7</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The rights conferred upon the holders of the shares of
                          any class issued with preferred or other rights shall
                          not unless otherwise expressly provided by the terms
                          of issue of the shares of that class be deemed to be
                          varied by the creation or issue of further shares
                          ranking pari passu therewith.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 8</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Subject to the provisions of section 55 any preference
                          shares may with the sanction of an ordinary resolution
                          be issued on the terms that they are to be redeemed on
                          such terms and in such manner as the company before
                          the issue of the shares may by special resolution
                          determine.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Lien</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 9</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The company shall have a first and paramount lienon
                          every share (not being a fully paid share) for all
                          monies (whether presently payable or not) called or
                          payable at a fixed time in respect of that share and
                          on all shares (not being fully paid shares) standing
                          registered in the name of a single person for all
                          monies presently payable by him or his estate to the
                          companyProvided that the Board of directors may at any
                          time declare any share to be wholly or in part exempt
                          from the provisions of this clause. The companys lien
                          if any on a share shall extend to all dividends
                          payable and bonuses declared from time to time in
                          respect of such shares.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 10</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The company may sell in such manner as the Board
                          thinks fit any shares on which the company has a
                          lienProvided that no sale shall be madea unless a sum
                          in respect of which the lien exists is presently
                          payable or b until the expiration of fourteen days
                          after a notice in writing stating and demanding
                          payment of such part of the amount in respect of which
                          the lien exists as is presently payable has been given
                          to the registered holder for the time being of the
                          share or the person entitled thereto by reason of his
                          death or insolvency.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 11</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          To give effect to any such sale the Board may
                          authorise some person to transfer the shares sold to
                          the purchaser thereof The purchaser shall be
                          registered as the holder of the shares comprised in
                          any such transfer. The purchaser shall not be bound to
                          see to the application of the purchase money nor shall
                          his title to the shares be affected by any
                          irregularity or invalidity in the proceedings in
                          reference to the sale.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 12</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The proceeds of the sale shall be received by the
                          company and applied in payment of such part of the
                          amount in respect of which the lien exists as is
                          presently payable. The residue if any shall subject to
                          a like lien for sums not presently payable as existed
                          upon the shares before the sale be paid to the person
                          entitled to the shares at the date of the sale.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Calls on shares</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 13</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board may from time to time make calls upon the
                          members in respect of any monies unpaid on their
                          shares (whether on account of the nominal value of the
                          shares or by way of premium) and not by the conditions
                          of allotment thereof made payable at fixed
                          timesProvided that no call shall exceed one-fourth of
                          the nominal value of the share or be payable at less
                          than one month from the date fixed for the payment of
                          the last preceding call. Each member shall subject to
                          receiving at least fourteen days notice specifying the
                          time or times and place of payment pay to the company
                          at the time or times and place so specified the amount
                          called on his shares. A call may be revoked or
                          postponed at the discretion of the Board.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 14</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A call shall be deemed to have been made at the time
                          when the resolution of the Board authorizing the call
                          was passed and may be required to be paid by
                          instalments.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 15</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The joint holders of a share shall be jointly and
                          severally liable to pay all calls in respect thereof.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 16</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          If a sum called in respect of a share is not paid
                          before or on the day appointed for payment thereof the
                          person from whom the sum is due shall pay interest
                          thereon from the day appointed for payment thereof to
                          the time of actual payment at ten per cent per annum
                          or at such lower rate if any as the Board may
                          determine. The Board shall be at liberty to waive
                          payment of any such interest wholly or in part.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 17</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Any sum which by the terms of issue of a share becomes
                          payable on allotment or at any fixed date whether on
                          account of the nominal value of the share or by way of
                          premium shall for the purposes of these regulations be
                          deemed to be a call duly made and payable on the date
                          on which by the terms of issue such sum becomes
                          payable. In case of non-payment of such sum all the
                          relevant provisions of these regulations as to payment
                          of interest and expenses forfeiture or otherwise shall
                          apply as if such sum had become payable by virtue of a
                          call duly made and notified.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 18</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board - a. may if it thinks fit receive from any
                          member willing to advance the same all or any part of
                          the monies uncalled and unpaid upon any shares held by
                          him andb. upon all or any of the monies so advanced
                          may (until the same would but for such advance become
                          presently payable) pay interest at such rate not
                          exceeding unless the company in general meeting shall
                          otherwise direct twelve per cent per annum as may be
                          agreed upon between the Board and the member paying
                          the sum in advance.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Transfer of shares</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 19</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The instrument of transfer of any share in the company
                          shall be executed by or on behalf of both the
                          transferor and transferee. The transferor shall be
                          deemed to remain a holder of the share until the name
                          of the transferee is entered in the register of
                          members in respect thereof.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 20</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board may subject to the right of appeal conferred
                          by section 58 decline to register the transfer of a
                          share not being a fully paid share to a person of whom
                          they do not approve or any transfer of shares on which
                          the company has a lien.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 21</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board may decline to recognise any instrument of
                          transfer unlessa. the instrument of transfer is in the
                          form as prescribed in rules made under sub-section (1)
                          of section 56b. the instrument of transfer is
                          accompanied by the certificate of the shares to which
                          it relates and such other evidence as the Board may
                          reasonably require to show the right of the transferor
                          to make the transfer andc. the instrument of transfer
                          is in respect of only one class of shares.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 22</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          On giving not less than seven days previous notice in
                          accordance with section 91 and rules made thereunder
                          the registration of transfers may be suspended at such
                          times and for such periods as the Board may from time
                          to time determineProvided that such registration shall
                          not be suspended for more than thirty days at any one
                          time or for more than forty-five days in the aggregate
                          in any year.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 22</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          On giving not less than seven days previous notice in
                          accordance with section 91 and rules made thereunder
                          the registration of transfers may be suspended at such
                          times and for such periods as the Board may from time
                          to time determineProvided that such registration shall
                          not be suspended for more than thirty days at any one
                          time or for more than forty-five days in the aggregate
                          in any year.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Transmission of shares</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 23</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          On the death of a member the survivor or survivors
                          where the member was a joint holder and his nominee or
                          nominees or legal representatives where he was a sole
                          holder shall be the only persons recognised by the
                          company as having any title to his interest in the
                          shares Nothing in clause (i) shall release the estate
                          of a deceased joint holder from any liability in
                          respect of any share which had been jointly held by
                          him with other persons.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 24</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Any person becoming entitled to a share in consequence
                          of the death or insolvency of a member may upon such
                          evidence being produced as may from time to time
                          properly be required by the Board and subject as
                          hereinafter provided elect either to be registered
                          himself as holder of the share or to make such
                          transfer of the share as the deceased or insolvent
                          member could have made. The Board shall in either case
                          have the same right to decline or suspend registration
                          as it would have had if the deceased or insolvent
                          member had transferred the share before his death or
                          insolvency.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 25</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          If the person so becoming entitled shall elect to be
                          registered as holder of the share himself he shall
                          deliver or send to the company a notice in writing
                          signed by him stating that he so elects. If the person
                          aforesaid shall elect to transfer the share he shall
                          testify his election by executing a transfer of the
                          share. All the limitations restrictions and provisions
                          of these regulations relating to the right to transfer
                          and the registration of transfers of shares shall be
                          applicable to any such notice or transfer as aforesaid
                          as if the death or insolvency of the member had not
                          occurred and the notice or transfer were a transfer
                          signed by that member.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 26</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A person becoming entitled to a share by reason of the
                          death or insolvency of the holder shall be entitled to
                          the same dividends and other advantages to which he
                          would be entitled if he were the registered holder of
                          the share except that he shall not before being
                          registered as a member in respect of the share be
                          entitled in respect of it to exercise any right
                          conferred by membership in relation to meetings of the
                          company Provided that the Board may at any time give
                          notice requiring any such person to elect either to be
                          registered himself or to transfer the share and if the
                          notice is not complied with within ninety days the
                          Board may thereafter withhold payment of all dividends
                          bonuses or other monies pa yable in respect of the
                          share until the requirements of the notice ha ve been
                          complied with.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 27</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          In case of a One Person Company on the death of the
                          sole member the person nominated by such member shall
                          be the person recognised by the company as having
                          title to all the shares of the member the nominee on
                          becoming entitled to such shares in case of the
                          members death shall be informed of such e vent by the
                          Board of the company such nominee shall be entitled t
                          o the same dividends and other rights and liabilities
                          t o which such sole member of the company was entitled
                          or liable on becoming member such nominee shall
                          nominate any other person with the prior written
                          consent of such person who shall in the e vent of the
                          death of the member become the member of the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Forfeiture of shares</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 28</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          If a member fails to pay any call or instalment of a
                          call on the day appointed for payment thereof the
                          Board may at any time thereafter during such time as
                          any part of the call or instalment remains unpaid ser
                          ve a notice on him requiring payment of so much of the
                          call or instalment as is unpaid together with any
                          interest which may ha v e accrued.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 29</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The notice aforesaid shall name a further day (not
                          being earlier than the expiry of fourteen days from
                          the date of service of the notice) on or before which
                          the payment required by the notice is to be made and
                          state that in the event of non-payment on or before
                          the day so named the shares in respect of which the
                          call was made shall be liable to be for feited.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 30</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          If the requirements of any such notice as aforesaid ar
                          e not complied with any share in respect of which the
                          notice has been given may at any time thereafter befor
                          e the payment required by the notice has been made be
                          for feited by a resolution of the Board to that
                          effect.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 31</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A for feited share may be sold or otherwise disposed
                          of on such terms and in such manner as the Board
                          thinks fit.At any time before a sale or disposal as
                          aforesaid the Board may cancel the for feiture on such
                          terms as it thinks fit.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 32</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A person whose shares ha ve been for feited shall
                          cease to be a member in respect of the for feited
                          shares but shall notwithstanding the for feiture
                          remain liable to pa y to the company all monies which
                          at the date of for feitur e were presently pa yable by
                          him to the company in respect of the shares. The
                          liability of such person shall cease if and when the
                          company shall ha ve received payment in full of all
                          such monies in respect of the shares.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 33</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A duly veri fied declaration in writing that the
                          declarant is a director the manager or the secretary
                          of the company and that a share in the company has
                          been duly for feited on a date stated in the
                          declaration shall be conclusiv e evidence of the facts
                          therein stated as against all persons claiming to be
                          entitled to the share The company may receive the
                          consideration if any given for the share on any sale
                          or disposal thereof and ma y execute a transfer of the
                          share in fa vour of the person t o whom the share is
                          sold or disposed of The transferee shall thereupon be
                          registered as the holder of the shar e and The
                          transferee shall not be bound to see to the
                          application of the purchase money if any nor shall his
                          title to the share be affected by any irregularity or
                          invalidity in the proceedings in reference to the for
                          feitur e sale or disposal of the share.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 34</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The pr ovisions of these regulations as to for feiture
                          shall apply in the case of non-payment of any sum
                          which b y the terms of issue of a share becomes pa
                          yable at a fi xed time whether on account of the
                          nominal value of the share or by way of premium as if
                          the same had been pa yable by virtue of a call duly
                          made and noti fied.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Alteration of capital</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 35</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The company may from time to time by ordinar y
                          resolution increase the share capital by such sum to
                          be divided into shares of such amount as may be speci
                          fied in the resolution.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 36</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Subject to the pr ovisions of section 61 the company
                          ma y by ordinary resolution consolidate and divide all
                          or any of its share capital into shares of larger
                          amount than its existing shares convert all or any of
                          its fully paid-up shares into stock and reconvert that
                          stock into fully paidup shares of any denomination
                          sub-divide its existing shares or any of them into
                          shares of smaller amount than is fi xed by the
                          memorandum cancel any shares which at the date of the
                          passing of the resolution ha v e not been taken or
                          agreed to be taken by any person.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 37</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Where shares are converted into stock the holders of
                          stock may transfer the same or any part thereof in the
                          same manner as and subject to the same regulations
                          under which the shares from which the stock arose
                          might before the conversion ha ve been transferred or
                          as near thereto as circumstances admit Pr ovided that
                          the Board may from time to time fix the minimum amount
                          of stock transferable so howe ver that such minimum
                          shall not exceed the nominal amount of the shares from
                          which the stock arose. the holders of stock shall
                          according t o the amount of stock held by them ha ve
                          the same rights privileges and advantages as regards
                          dividends voting at meetings of the companyand other
                          matters as if the y held the shares from which the
                          stock arose but no such privilege or advantage (except
                          participation in the dividends and pr o fits of the
                          company and in the assets on winding up) shall be
                          conferred by an amount of stock which would not if
                          existing in shares ha ve conferred that privilege or
                          advantage. such of the regulations of the company as
                          are applicable to paid-up shares shall apply to stock
                          and the words share and shareholder in those
                          regulations shall include stock and stock-holder
                          respectively.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 38</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The company may by special resolution reduce in any
                          manner and with and subject to any incident authorised
                          and consent required by law it share capital any
                          capital redemption reserve account or any share
                          premium account.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Capitalisation of pro fits</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 39</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          to capitalise any part of the amount for the time
                          being standing to the credit of any of the companys
                          reser v e accounts or to the credit of the pr o fit
                          and loss accountor otherwise a vailable for
                          distribution and that such sum be accordingly set free
                          for distribution in the manner speci fied in clause
                          (ii) amongst the members who would ha ve been entitled
                          thereto if distributed by way of dividend and in the
                          same proportions. The sum aforesaid shall not be paid
                          in cash but shall be applied subject t o the pr
                          ovision contained in clause (iii) either in or towards
                          paying up any amounts for the time being unpaid on any
                          shares held by such members respectively paying up in
                          full unissued shares of the company to be allotted and
                          distributed credited as fully paid-up to and amongst
                          such members in the proportions aforesaid partly in
                          the wa y speci fied in sub-clause (A) and partly in
                          that speci fied in sub-clause (B) A securities premium
                          account and a capital redemption reser ve account may
                          for the purposes of this regulation be applied in the
                          paying up of unissued shares to be issued to members
                          of the company as fully paid bonus shares The Board
                          shall giv e effect to the resolution passed by the
                          company in pursuance of this regulation.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 40</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Whene ver such a resolution as aforesaid shall ha ve
                          been passed the Board shall make all appropriations
                          and applications of the undivided pr o fits resolved
                          to be capitalised thereby and all allotments and
                          issues of fully paid shares if any and generally do
                          all acts and things required to give effect thereto.
                          The Board shall have power to make such pr ovisions by
                          the issue of fractional certi ficates or by payment in
                          cash or otherwise as it thinks fit for the case of
                          shares becoming distributable in fractions and to
                          authorise any person to enter on behalf of all the
                          members entitled thereto into an agreement with the
                          company pr oviding for the allotment to them
                          respectively credited as fully paid-up of any further
                          shares to which they may be entitled upon such
                          capitalisation or as the case may require for the
                          payment by the company on their behalf by the
                          application thereto of their respective proportions of
                          pr o fits resolved to be capitalised of the amount or
                          any part of the amounts remaining unpaid on their
                          existing shares Any agreement made under such
                          authority shall be effective and binding on such
                          members.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Buy-back of shares</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 41</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Notwithstanding anything contained in these articles
                          but subject to the pr ovisions of sections 68 to 70
                          and any other applicable pr ovision of the Act or any
                          other law for the time being in force the company may
                          purchase its own shares or other speci fied
                          securities.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>General meetings</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 42</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          All general meetings other than annual general meeting
                          shall be called extraordinary general meeting.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 43</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board may whene ver it thinks fit call an
                          extraordinar y general meeting. If at any time
                          directors capable of acting who are su fficient in
                          number to form a quorum ar e not within India any
                          director or any two members of the company may call an
                          extraordinary general meeting in the same manner as
                          nearly as possible as that in which such a meeting may
                          be called by the Board.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Proceedings at general meetings</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 44</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          No business shall be transacted at any general meeting
                          unless a quorum of members is present at the time when
                          the meeting proceeds to business. Save as otherwise
                          provided herein the quorum for the general meetings
                          shall be as provided in section 103.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 45</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The chairperson if any of the Board shall preside as
                          Chairperson at every general meeting of the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 46</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          If there is no such Chairperson or if he is not
                          present within fifteen minutes after the time
                          appointed for holding the meeting or is unwilling to
                          act as chairperson of the meeting the directors
                          present shall elect one of their members to be
                          Chairperson of the meeting.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 47</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          If at any meeting no director is willing to act as
                          Chairperson or if no director is present within
                          fifteen minutes after the time appointed for holding
                          the meeting the members present shall choose one of
                          their members to be Chairperson of the meeting.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 48</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          In case of a One Person Company the resolution
                          required to be passed at the general meetings of the
                          company shall be deemed to have been passed if the
                          resolution is agreed upon by the sole member and
                          communicated to the company and entered in the minutes
                          book maintained under section 118 such minutes book
                          shall be signed and dated by the member the resolution
                          shall become effective from the date of signing such
                          minutes by the sole member.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Adjournment of meeting</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 49</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Chairperson may with the consent of any meeting at
                          which a quorum is present and shall if so directed by
                          the meeting adjourn the meeting from time to time and
                          from place to place. No business shall be transacted
                          at any adjourned meeting other than the business left
                          unfinished at the meeting from which the adjournment
                          took place. When a meeting is adjourned for thirty
                          days or more notice of the adjourned meeting shall be
                          given as in the case of an original meeting. Save as
                          aforesaid and as provided in section 103 of the Act it
                          shall not be necessary to give any notice of an
                          adjournment or of the business to be transacted at an
                          adjourned meeting.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Voting rights</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 50</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Subject to any rights or restrictions for the time
                          being attached to any class or classes of shares on a
                          show of hands every member present in person shall
                          have one vote and on a poll the voting rights of
                          members shall be in proportion to his share in the
                          paid-up equity share capital of the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 51</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Subject to any rights or restrictions for the time
                          being attached to any class or classes of shares on a
                          show of hands every member present in person shall
                          have one vote and on a poll the voting rights of
                          members shall be in proportion to his share in the
                          paid-up equity share capital of the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 52</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          In the case of joint holders the vote of the senior
                          who tenders a vote whether in person or by proxy shall
                          be accepted to the exclusion of the votes of the other
                          joint holders.For this purpose seniority shall be
                          determined by the order in which the names stand in
                          the register of members.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 53</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A member of unsound mind or in respect of whom an
                          order has been made by any court having jurisdiction
                          in lunacy may vote whether on a show of hands or on a
                          poll by his committee or other legal guardian and any
                          such committee or guardian may on a poll vote by pr
                          oxy.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 54</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Any business other than that upon which a poll has
                          been demanded maybe proceeded with pending the taking
                          of the poll.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 55</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          No member shall be entitled to vote at any general
                          meeting unless all calls or other sums presently pa
                          yable by him in respect of shares in the company ha ve
                          been paid.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 56</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          No objection shall be raised to the quali fication of
                          any voter except at the meeting or adjourned meeting
                          at which the vote objected to is given or tendered and
                          e ver y vote not disallowed at such meeting shall be
                          valid for all purposes. Any such objection made in due
                          time shall be referred to the Chairperson of the
                          meeting whose decision shall be final and conclusive.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Proxy</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 57</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The instrument appointing a pr oxy and the
                          power-ofattorney or other authority if any under which
                          it is signed or a notarised copy of that power or
                          authority shall be deposited at the registered o ffice
                          of the company not less than 48 hours before the time
                          for holding the meeting or adjourned meeting at which
                          the person named in the instrument proposes to vote or
                          in the case of a poll not less than 24 hours before
                          the time appointed for the taking of the poll and in
                          default the instrument of pr oxy shall not be treated
                          as valid.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 58</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          An instrument appointing a pr oxy shall be in the form
                          as prescribed in the rules made under section 105.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 59</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A vote given in accordance with the terms of an
                          instrument of pr oxy shall be valid notwithstanding
                          the pr evious death or insanity of the principal or
                          the r e vocation of the pr oxy or of the authority
                          under which the pr oxy was executed or the transfer of
                          the shares in respect of which the pr oxy is givenPr
                          ovided that no intimation in writing of such death
                          insanity r e vocation or transfer shall ha ve been
                          received by the company at its o ffice before the
                          commencement of the meeting or adjourned meeting at
                          which the pr oxy is used.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Board of Directors</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 60</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The number of the directors and the names of the first
                          directors shall be determined in writing by the
                          subscribers of the memorandum or a majority of them.1.
                          VIJ AY KUMAR GUPTA2. ARCHANA GUPTA
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 61</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The remuneration of the directors shall in so far as
                          it consists of a monthly payment be deemed to accrue
                          from day-to-da y. In addition to the remuneration pa
                          yable to them in pursuance of the Act the directors
                          may be paid all tr a velling hotel and other expenses
                          properly incurred by them in attending and returning
                          from meetings of the Board of Directors or any
                          committee thereof or general meetings of the company
                          or in connection with the business of the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 62</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board may pay all expenses incurred in getting up
                          and registering the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 63</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The company may exercise the powers conferred on it by
                          section 88 with regard to the keeping of a foreign
                          register and the Board may (subject to the provisions
                          of that section) make and vary such regulations as it
                          may think fit respecting the keeping of any such
                          register.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 64</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          All cheques promissory notes drafts hundis bills of
                          exchange and other negotiable instruments and all
                          receipts for monies paid to the company shall be
                          signed drawn accepted endorsed or otherwise executed
                          as the case may be by such person and in such manner
                          as the Board shall from time to time by resolution
                          determine.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 65</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Every director present at any meeting of the Board or
                          of a committee thereof shall sign his name in a book
                          to be kept for that purpose.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 66</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Subject to the provisions of section 149 the Board
                          shall have power at any time and from time to time to
                          appoint a person as an additional director provided
                          the number of the directors and additional directors
                          together shall not at any time exceed the maximum
                          strength fixed for the Board by the articles. Such
                          person shall hold office only up to the date of the
                          next annual general meeting of the company but shall
                          be eligible for appointment by the company as a
                          director at that meeting subject to the provisions of
                          the Act.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Proceedings of the Board</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 67</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board of Directors may meet for the conduct of
                          business adjourn and otherwise regulate its meetings
                          as it thinks fit. A director may and the manager or
                          secretary on the requisition of a director shall at
                          any time summon a meeting of the Board.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 68</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Save as otherwise expressly provided in the Act
                          questions arising at any meeting of the Board shall be
                          decided by a majority of votes. In case of an equality
                          of votes the Chairperson of the Board if any shall
                          have a second or casting vote.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 69</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The continuing directors may act notwithstanding any
                          vacancy in the Board but if and so long as their
                          number is reduced below the quorum fixed by the Act
                          for a meeting of the Board the continuing directors or
                          director may act for the purpose of increasing the
                          number of directors to that fixed for the quorum or of
                          summoning a general meeting of the company but for no
                          other purpose.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 70</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board may elect a Chairperson of its meetings and
                          determine the period for which he is to hold office.
                          If no such Chairperson is elected or if at any meeting
                          the Chairperson is not present within five minutes
                          after the time appointed for holding the meeting the
                          directors present may choose one of their number to be
                          Chairperson of the meeting.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 71</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board may subject to the provisions of the Act
                          delegate any of its powers to committees consisting of
                          such member or members of its body as it thinks fit.
                          Any committee so formed shall in the exercise of the
                          powers so delegated conform to any regulations that
                          may be imposed on it by the Board.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 72</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A committee may elect a Chairperson of its meetings.
                          If no such Chairperson is elected or if at any meeting
                          the Chairperson is not present within fi ve minutes
                          after the time appointed for holding the meeting the
                          memberspresent may choose one of their members t o be
                          Chairperson of the meeting.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 73</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A committee may meet and adjourn as it thinks fit.
                          Questions arising at any meeting of a committee shall
                          be determined by a majority of votes of the members
                          present and in case of an equality of votes the
                          Chairperson shall ha ve a second or casting vote.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 74</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          All acts done in any meeting of the Board or of a
                          committee thereof or by any person acting as a
                          director shall notwithstanding that it may be
                          afterwards disco vered that there was some defect in
                          the appointment of any one or more of such directors
                          or of any person acting as aforesaid or that they or
                          any of them were disquali fied be as valid as if e
                          very such director or such person had been duly
                          appointed and was quali fied to be a director.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 75</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Sa ve as otherwise expressly pr ovided in the Act a
                          resolution in writing signed by all the members of the
                          Board or of a committee thereof for the time being
                          entitled to receive notice of a meeting of the Board
                          or committee shall be valid and effective as if it had
                          been passed at a meeting of the Board or committee
                          duly convened and held.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 76</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          In case of a One Person Company where the company is
                          having only one director all the businesses to be
                          transacted at the meeting of the Board shall be
                          entered into minutes book maintained under section 118
                          such minutes book shall be signed and dated by the
                          director the resolution shall become effective from
                          the date of signing such minutes by the director.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>
                      Chief Executive Officer, Manager, Company Secretary or
                      Chief Financial Officer
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 77</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Subject to the pr ovisions of the Act A chief executiv
                          e o fficer manager company secretary or chief
                          financial o fficer may be appointed by the Board for
                          such term at such remuneration and upon such
                          conditions as it ma y think fit and any chief
                          executive o fficer manager company secretary or chief
                          financial o fficer so appointed may be remo ved by
                          means of a resolution of the Board A director may be
                          appointed as chief executive o fficer manager company
                          secretary or chief financial o fficer
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 78</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          A pr ovision of the Act or these regulations requiring
                          or authorising a thing to be done by or to a director
                          and chief executive o fficer manager company secretary
                          or chief financial o fficer shall not be satis fied by
                          its being done by or to the same person acting both as
                          director and as or in place of chief executive o
                          fficer manager company secretary or chief financial o
                          fficer.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>The Seal</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 79</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board shall pr ovide for the safe custody of the
                          seal. The seal of the company shall not be a ffi xed
                          to any instrument except by the authority of a
                          resolution of the Board or of a committee of the Board
                          authorised by it in that behalf and except in the
                          presence of at least two directors and of the
                          secretary or such other person as the Board may
                          appoint for the purpose and those two directors and
                          the secretary or other person aforesaid shall sign e
                          very instrument to which the seal of the company is so
                          a ffi xed in their presence.Explanation- For the
                          purposes of this sub-paragraph it is hereby clari fied
                          that on and from the commencement of the
                          Companies(Amendment) Act 2015 (21 of 2015) i.e. with
                          effect from the 29th May 2015 company may not be
                          required to have the seal by virtue of registration
                          under the Act and if a company does not ha ve the seal
                          the pr ovisions of this sub-paragraph shall not be
                          applicable.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Dividends and Reserve</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 80</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The company in general meeting may declare dividends
                          but no dividend shall exceed the amount recommended by
                          the Board.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 81</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Subject to the pr ovisions of section 123 the Board ma
                          y from time to time pay to the members such interim
                          dividends as appear to it to be justi fied by the pr o
                          fits of the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 82</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board may before recommending any dividend set
                          aside out of the pr o fits of the company such sums as
                          it thinks fit as a reser ve or reser ves which shall
                          at the discretion of the Board be applicable for any
                          purpose t o which the pr o fits of the company may be
                          properly applied including pr ovision for meeting
                          contingencies or for equalizing dividends and pending
                          such application ma y at the like discretion either be
                          emplo yed in the business of the company or be
                          invested in such investments (other than shares of the
                          company) as the Board ma y from time to time thinks
                          fit. The Board may also carr y forward any pr o fits
                          which it may consider necessary not to divide without
                          setting them aside as a reserve.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 83</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Subject to the rights of persons if any entitled to
                          shares with special rights as to dividends all
                          dividends shall be declared and paid according to the
                          amounts paid or credited as paid on the shares in
                          respect whereof the dividend is paid but if and so
                          long as nothing is paid upon any of the shares in the
                          company dividends may be declared and paid according
                          to the amounts of the shares. No amount paid or
                          credited as paid on a share in advance of calls shall
                          be treated for the purposes of this regulation as paid
                          on the share. All dividends shall be apportioned and
                          paid proportionately to the amounts paid or credited
                          as paid on the shares during any portion or portions
                          of the period in respect of which the dividend is paid
                          but if any share is issued on terms pr oviding that it
                          shall rank for dividend as from a particular date such
                          share shall rank for dividend accordingly.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 84</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board may deduct from any dividend pa yable to any
                          member all sums of money if any presently pa yable b y
                          him to the company on account of calls or otherwise in
                          relation to the shares of the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 85</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Any dividend interest or other monies payable in cash
                          in respect of shares may be paid by cheque or warrant
                          sent through the post directed to the registered
                          address of the holder or in the case of joint holders
                          to the registered address of that one of the joint
                          holders who is first named on the register of members
                          or to such person and to such address as the holder or
                          joint holders may in writing direct. Every such cheque
                          or warrant shall be made payable to the order of the
                          person to whom it is sent.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 86</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Any one of two or more joint holders of a share may
                          give effective receipts for any dividends bonuses or
                          other monies payable in respect of such share.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 87</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Notice of any dividend that may have been declared
                          shall be given to the persons entitled to share
                          therein in the manner mentioned in the Act.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 88</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          No dividend shall bear interest against the company.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Accounts</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 89</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          The Board shall from time to time determine whether
                          and to what extent and at what times and places and
                          under what conditions or regulations the accounts and
                          books of the company or any of them shall be open to
                          the inspection of members not being directors. No
                          member (not being a director) shall have any right of
                          inspecting any account or book or document of the
                          company except as conferred by law or authorised by
                          the Board or by the company in general meeting.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Winding up</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 90</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Subject to the provisions of Chapter XX of the Act and
                          rules made thereunder If the company shall be wound up
                          the liquidator may with the sanction of a special
                          resolution of the company and any other sanction
                          required by the Act divide amongst the members in
                          specie or kind the whole or any part of the assets of
                          the company whether they shall consist of property of
                          the same kind or not. For the purpose aforesaid the
                          liquidator may set such value as he deems fair upon
                          any property to be divided as aforesaid and may
                          determine how such division shall be carried out as
                          between the members or different classes of members.
                          The liquidator may with the like sanction vest the
                          whole or any part of such assets in trustees upon such
                          trusts for the benefit of the contributories if he
                          considers necessary but so that no member shall be
                          compelled to accept any shares or other securities
                          whereon there is any liability.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Indemnity</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 91</p>
                    </td>
                    <td>
                      <ul className="list-table-one">
                        <li>
                          Every officer of the company shall be indemnified out
                          of the assets of the company against any liability
                          incurred by him in defending any proceedings whether
                          civil or criminal in which judgment is given in his
                          favour or in which he is acquitted or in which relief
                          is granted to him by the court or the Tribunal.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Others</th>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp; 92</p>
                    </td>
                    <td>
                      <ul className="list-table-one d-flex justify-content-around align-items-center w-100">
                        <li>
                          <div className="chairmain">
                            <p className="fw-semibold mb-1">
                              1. Vijay Kumar Gupta
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="chairmain">
                            <p className="fw-semibold mb-1">
                              2. Archana Patel Gupta
                            </p>
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default AOA;
