import "./App.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import Header from "./Components/Header/Header";
import Aboutus from "./Components/Aboutus/Aboutus";
import Footer from "./Components/Footer/Footer";
import Dashboard from "./Components/Dashboard/Dashboard";
import Careears from "./Components/Careears/Careears";
import MOA from "./Components/Aboutus/MOA";
import AOA from "./Components/Aboutus/AOA";
import ChairmanMsg from "./Components/Aboutus/ChairmanMsg";
import ViceChairmanMsg from "./Components/Aboutus/ViceChairmanMsg";
import HalfMerathon from "./Components/Aboutus/HalfMerathon";
import Run4UnemploymentAwareness from "./Components/Philanthropy/Run4UnemploymentAwareness";
import Chairity from "./Components/Philanthropy/Chairity/Chairity";
import TempleDevelopment from "./Components/Philanthropy/Chairity/TempleDevelopment";
import ContactUs from "./Components/ContactUs/ContactUs";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivatePolicy from "./pages/PrivatePolicy";
import RefundAndCancelationPolicy from "./pages/RefundAndCancelationPolicy";
import FlageProgram from "./pages/FlageProgram";
import HaeGHarTiranga from "./pages/HaeGHarTiranga";
import BairganiaMahotsav from "./Components/Philanthropy/BairganiyaMahostav/BairganiaMahotsav";
import OpenTermsAndConditionPopup from "./pages/OpenTermsAndConditionPopup";
import PratePolicyPopup from "./pages/PratePolicyPopup";
import BairganiyaMahostavDashboard from "./Components/Philanthropy/BairganiyaMahostav/BairganiyaMahostavDashboard";
import Photography from "./Components/Philanthropy/BairganiyaMahostav/Photography";
import Painting from "./Components/Philanthropy/BairganiyaMahostav/Painting";
import Fashion from "./Components/Philanthropy/BairganiyaMahostav/Fashion";
import TopBtn from "./pages/TopBtn";
import "./responsive.css";
import FoodAndBeverge from "./Components/Philanthropy/BairganiyaMahostav/FoodAndBeverge";
import BairganiyaCricketLeage from "./Components/Philanthropy/BairganiyaCricketLeage/BairganiyaCricketLeage";
import Dance from "./Components/Philanthropy/BairganiyaMahostav/Dance";
import NewsAndPr from "./Components/NewsAndPr";



function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [pathname])

  return (
    <>
      <div className="overflow-auto">
        <Header />
        <ToastContainer autoClose={3000} />
        <TopBtn />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/moa" element={<MOA />} />
          <Route path="/aoa" element={<AOA />} />
          <Route path="/chairmanmsg" element={<ChairmanMsg />} />
          <Route path="/vicechairmanmsg" element={<ViceChairmanMsg />} />
          <Route path="/careears" element={<Careears />} />
          <Route path="/halfmerathon" element={<HalfMerathon />} />
          <Route
            path="/Run4UnemploymentAwareness"
            element={<Run4UnemploymentAwareness />}
          />
          <Route path="/charity" element={<Chairity />} />
          <Route path="/bairganiaMahotsav" element={<BairganiaMahotsav />} />
          <Route path="/templedevelopment" element={<TempleDevelopment />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/termsandcondition" element={<TermsAndCondition />} />
          <Route path="/privatepolicy" element={<PrivatePolicy />} />
          <Route path="/refundandcancelationpolicy" element={<RefundAndCancelationPolicy />} />
          <Route path="/rfbflageprogram" element={<FlageProgram />} />
          <Route path="/rfbharghartiranga" element={<HaeGHarTiranga />} />
          <Route path="/mahostavtermsandcondition" element={<OpenTermsAndConditionPopup />} />
          <Route path="/mahostavprivacypolicy" element={<PratePolicyPopup />} />
          <Route path="/mahostavdashboard" element={<BairganiyaMahostavDashboard />} />
          <Route path="/photographymahostav" element={<Photography />} />
          <Route path="/paintingmahostav" element={<Painting />} />
          <Route path="/fashionmahostav" element={<Fashion />} />
          <Route path="/foodandbeverage" element={<FoodAndBeverge />} />
          <Route path="/dance" element={<Dance />} />
          <Route path="/bpl" element={<BairganiyaCricketLeage />} />
          <Route path="/newsandpr" element={<NewsAndPr />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
