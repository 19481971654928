import React from "react";
import "./about.css";
const ChairmanMsg = () => {
  return (
    <section className="moa-sec">
      <div className="container">
        <div className="row gx-2 gy-2 w-100">
          <div className="col-lg-2 col-md-2 col-sm-3">
            <div className="chairman-demo">
              <div className="chairman-img d-flex justify-content-center align-items-start w-100">
                <img
                  src={require("../../assetes/img/Sir.jpg")}
                  alt=""
                  className="w-100 object-fit-cover align-self-start"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-8">
            <div className="chairman-title d-flex align-items-center px-2 py-1">
              <h1 className="main-text-vicechairman-title">
                VIJAY KUMAR GUPTA Founder & Chairman of Rauniyar Foundation
                Bharat
              </h1>
            </div>

            <div className="main-text-chairman list mt-4">
              <p>Dear Friends,</p>
              <p>
                Talking about such a private and personal subject matter as
                helping others is not easy. Helping our fellow man is a
                privilege that each one of us should use with discretion and
                respect, in relation to our own means. This is what I did until
                now, realizing that my actions are but a mere drop of water in
                the vast ocean, yet certain I possessed a moral duty to do all
                that I could for the well-being of those less fortunate than
                myself.
              </p>
              <p>
                Experience has taught me that if we pass on generosity, if we
                share the happiness and the goodness that resides in every one
                of us, it will come back to us, multiplied. “In the kingdom of
                love – an Irish theologist wrote – there is no competition; no
                possessiveness or control. The more love you give away, the more
                love you will have.”
              </p>
              <p>
                When I was a boy, I too asked for help, I too was familiar with
                the feeling of needing help. I looked for support, for
                assistance from my close friends and family. After that, I had
                the chance, or better yet the joy, of being able to give back: a
                growing desire to help, which became a responsibility, an
                obligatory priority, and an ethical imperative.
              </p>
              <p>
                The soul needs love as much as the body needs air. Love, as
                Dante teaches us, is the hidden rhythm of the universe (and
                “moves the sun and the other stars”): as soon as we glimpse a
                spark of love, it is essential that we offer up its warmth, like
                a blessing, to those who are hungry, incarcerated or confined to
                a hospital, prisoners of hardship.
              </p>
              <p>
                Over the years, I have tried to make myself useful as much as I
                was able to, however, I grew to believe that it was not enough.
                That is why I decided to fully commit to this venture, doing
                away with the discretion that charitable deeds usually demand.
                That is why I created a foundation: to unite forces, to make
                sure that my actions are no mere “drop in the ocean” but rather
                join with others – as Mother Theresa teaches us – to become the
                ocean.
              </p>
              <p>
                To my friends, to those who either for artistic or professional
                reasons have had the opportunity to get to know – and hopefully
                to appreciate – my work as a textile business man and the
                intellectual integrity that has marked my career, I ask for
                tangible support. I ask you to join me in the battle against
                poverty in developing countries and I ask you to respond to the
                significant challenges in the social and scientific research
                fields.
              </p>
              <p>
                The Rauniyar Foundation Bharat carries my name: it is not some
                frivolous exercise in vanity, but rather represents the sealing
                of a pact and the unequivocal assumption of responsibility.
                Indeed, I am committing to overseeing and to guaranteeing the
                quality and transparency of each step of every project.
              </p>
              <p>
                Professionally, I am devoted to my greatest passion, social
                welfare. After all, life has been very generous with me – both
                professionally and privately – and I feel it is my duty to repay
                this debt and give back. What many people think is my main
                problem in life, is actually the last on my list. The challenge
                is not to conjure up a face or a sunset, but to find our way
                amidst the hundreds of daily difficulties that present
                themselves (which wealth and success do not help diminish!) with
                a strong desire to not resort to compromise, not to lose my
                integrity (and the serenity that goes with it), and not to lose
                myself.
              </p>
              <p>
                I earnestly invite all my friends to give in to the temptation
                of love and to donate to support the Foundation. When one loves,
                and when we let ourselves be loved, fear becomes boldness and
                the emptiness becomes fulfilment, because – as Shree Krishna
                reminds us – “love is patient, love is kind. It does not envy,
                it does not boast (…) It always protects, always trusts, always
                hopes, always perseveres.”
              </p>
              <p>
                If you are still skeptical, try and close your eyes. You will be
                able to look far into the distance, to think and reflect without
                distraction within the intimacy of your own mind about what we
                are and the part of eternity that is the secret to each of our
                existences. I am sure you will then agree with my humble
                reflection that sparked the project that gave life to the
                Foundation and that has become its mission:
              </p>
              <p>
                Our faith in love and justice calls upon us to build a better
                world than the one we have found. It calls upon us to give back
                to the world all the good things that we have received, so that
                the less fortunate or the most vulnerable members of our society
                will have a chance to achieve a life full of opportunities and
                beauty, so those who take up the challenge will find real
                opportunities and the energy required to thrive in their lives.
              </p>
              <p>Regards,</p>
              <p className="fw-bold mb-1">Vijay Kumar Gupta</p>
              <p className="fw-bold mb-1">Founder And Chairman</p>
              <p className="fw-bold mb-1">Rauniyar Foundation Bharat</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChairmanMsg;
