import React from "react";
import { MutatingDots } from "react-loader-spinner";
import "./../App.css";

const Loader = () => {
  return (
    <div className="loader-overlay">
      <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#aa3b98"
        secondaryColor="#18a790"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
      />
    </div>
  );
};

export default Loader;
