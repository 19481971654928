import React from "react";
import "./pages.css";

const FlageProgram = () => {
  const flageText = [
    {
      header: "Vision",
      text: [
        "Our primary vision is to popularize the display of the Tiranga by more and more Indians, with a great sense of pride. When we display the flag, we rise above narrow considerations of religion, caste, language, region, etc. and it reminds us of being, first and foremost, an Indian. At the same time, it represents every Indian religion, language, culture and region. It celebrates our rich diversity. It is, thus, the greatest symbol of our 'unity in diversity'.",
      ],
    },
    {
      header: "Mission",
      text: [
        "The current age has seen an increasing trend of nationalism and patriotism becoming more slogans. The ‘Rauniyar Foundation Bharat Flag program’ is an attempt to rekindle that spirit of nationalism. By choosing the path of striving to uphold the dignity of the Tiranga, this project aims at regenerating that sense of pride and removing those layers of hesitation which keep us from living a life of a true nationalist.",

        "Today's education system is such that we are given all education excluding the education that generates morality, patriotism and nationalism. We are slowly forgetting the importance of the nation in our lives. We tend to take Patriotism as a trademark of the armed forces.",

        "Since independence our brave soldiers have been laying down their lives with pride, so that, the Tricolour may fly high with dignity. Remember the time when India wins a cricket match and you wave the Indian Flag with grace and passion.",

        "The ‘Rauniyar Foundation Bharat Flag program’ is an initiative to trigger our senses. It is an attempt to strive for the up-lifting of the true sense of nationalism. The Tiranga embodies our modem democratic, secular and liberal values, which should be embraced by all Indians in their endeavor to build a strong and progressive India, which would take its rightful place in the world. To take the message of our Tiranga to every Indian, we intend to make use of all available and appropriate mediums, such as -ART AND CULTURE (Printing and photography exhibitions, Cultural programs and festivals, Performing Arts etc.), SEMINARS, WORKSHOPS and COLLABORATION with other civil society and advocacy groups, professionals, corporate houses, educationists and other like-minded individuals with a special focus on children and the youth of India. Very soon we will be going to install one flag each year across the length and breadth of the India Nepal Border. ",

        "The monumental flags are not lowered at the sunset and are adequately illuminated during the night. This was introduced after a policy decision by the Ministry of Home Affairs on 23rd December 2009, which allowed the National Flag to fly day and night on mammoth sized National Flags.",
      ],
    },
    {
      header: "History of Indian Flag",
      text: [
        "The hard truth of the pre-independence period is that India never had a National Flag that could represent it as one nation. The need for having a flag wasn't really felt by Indians until the partition of Bengal was announced. That day was declared as the national day of mourning. A year later, on the anniversary of anti-partition movement a flag was unfurled. It was designed by Sachindra Prasad Bose. After the partition was annulled people forgot about the flag. Madam Bhikaji Rustom Cama who attended the 2nd International Socialist Congress in Germany gave a speech about the political fight with British and waved the flag. It was made by Hem Chandra Das. Years later in 1917, during the Home Rule Movement Bal Gangadhar Tilak and Mrs. Anne Besant also designed a flag.",

        "Four years later in 1921 Gandhi ji asked Mr Pingley Venkayya to design a national flag, for Freedom Movement , which should have a 'Charkha' in the flag as it represented self reliance, progress and common man. It was also called the Swaraj flag, the Gandhi flag and the Charkha Flag. However, in 1931, a seven-member flag committee was established in Karachi to modify the flag and they had given a new design.",

        "The big day for India came when Lord Mountbatten announced the decision to free India. The need to have a Flag that is acceptable to all parties was realized and an ad-hoc flag committee was headed by Dr. Rajendra Prasad to design the flag for free India. Gandhiji's consent was taken and Pingley Venkayya's flag was decided to be modified. Instead of the Charkha, the emblem of Ashoka's Sarnath pillar, the wheel , was decided upon. None of the colours had any communal significance. The National Flag was adopted on 22 July, 1947.",
      ],
      list: [
        "Unofficial flag of India in 1906",
        "The Berlin committee flag, first raised by Bhikaiji Cama in 1907",
        "The flag used during the Home Rule movement in 1917",
        "The flag unofficially adopted in 1921",
        "The flag adopted in 1931. This flag was also the battle ensign of the Indian National Army",
      ],
    },
    {
      header: "Physical Features of The National Flag of India",
      text: [
        "The National Flag of India is horizontally rectangular in a shape. It comprises of four colours, The India Saffron, White, India Green which are the primary colours and the Navy Blue which is the secondary colour. The saffron colour signifies courage, sacrifice, valour, wisdom and action. It is a mixture of two colours red and yellow in fixed ratios. White symbolizes purity, peace and tranquility. Green symbolizes growth of the country, the vegetation, agriculture and plant life – relation with the earth, on which all other life depends. The Navy blue in the Chakra refers to the boundless sky and fathomless sea and the inner energy.",

        "The Indian Flag has the Ashoka Chakra on the white band which has 24 equally spaced spokes. The wheel/chakra is the Law of Dharma. It also means 24 hours a day which indicates movement of time i.e., the progressiveness of the country.",

        "The ratio of the width to length of the Flag is 2:3. The fabric used to make the national flag to be used in government offices or public premises has to be either Khadi/Hand-spun cotton or silk. For the general public the flag can be of any material. However, earlier it was mandatory to use Khadi.",
      ],
    },
    {
      header: "Fly your Tiranga with Pride",
      text: [
        <p className="list-table-onde">
          The official book/ booklet that lays down the instructions or the use
          of our National Flag is the{" "}
          <span className="fw-semibold">FLAG CODE OF INDIA</span> .
        </p>,

        "The time in which the national flag is normally flown during the daytime lies between the sunrise and the sunset. If it is to fly at night then it must be adequately lighted.",

        "The Tiranga can be printed, stitched as a part of your dress or costume but not below the waistline.",
        "When the Tiranga is flown with the national flag of another country it will be placed/flown to the left of the other country's flag. However, when the Tiranga is flown with the United Nations' flag it can be flown on either side of it.",
      ],
    },
    {
      header: "Flag Code of India 2002",
      text: [
        "Flag Code of India although is not a law within the meaning of Article 13(3)(a) of the Constitution of India for the purpose of clause (2) of Article 19 thereof, it would not restrictively regulate the free exercise of the right of flying the national flag. ",
      ],
    },
  ];

  return (
    <>
      <section className="moa-sec">
        <div className="container">
          <div className="row w-100 gx-1 gy-2">
            <div className="col-md-1">
              <div className="flage-img overflow-hidden">
                <img
                  src={require("../assetes/ourassosiates/flage.jpg")}
                  alt=""
                  className="w-100 object-fit-cover"
                />
              </div>
            </div>
            <div className="col-md-11 p-2">
              <div className="d-flex justify-content-center align-items-center w-100">
                <div className="flage_program-text p-3 w-100">
                  <div className="chairman-title d-flex align-items-center px-2 py-1">
                    <h1 className="main-text-vicechairman-title flag-title ">
                      Rauniyar Foundation Bharat Flag Program
                    </h1>
                  </div>
                  <p className="list-table-one">
                    On 23rd January 2004, the Supreme Court of India ruled that
                    the display of the national flag by every citizen on all
                    days of the year, a fundamental right. Thereafter, In year
                    2023 we have started Rauniyar Foundation Bharat Flag program
                    in India, as non-governmental organization.
                  </p>
                  <p className="list-table-one">
                    The Tricolour is a common factor of inspiration for every
                    Indian; it is indeed a true expression of pride for our
                    mother land. To take this victory beyond pure symbolism and
                    translate it into action, Rauniyar Foundation Bharat Flag
                    program of India is undertaking various projects motivating
                    the people of our country to draw inspiration from the
                    National Flag and work for the betterment of the nation.
                  </p>
                  <p className="list-table-one">
                    The Rauniyar Foundation Bharat Flag program of India was set
                    up to display our National Flag with honour and pride at
                    every citizen homes, offices and factories on all days of
                    the year.
                  </p>
                  {flageText.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <p className="half-merathone-list-title list-table-one my-2">
                            &nbsp; {item.header}
                          </p>

                          {Array.isArray(item.text) ? (
                            item.text.map((item, index) => {
                              return (
                                <>
                                  <div key={index}>
                                    <p className="list-table-one">{item}</p>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <p className="list-table-one">{item.text}</p>
                          )}

                          {item.list && (
                            <>
                              <div className="flag-List d-flex justify-content-around align-items-center w-100">
                                <ul>
                                  {item.list.map((listItem, listIndex) => (
                                    <li
                                      key={listIndex}
                                      className="list-table-one"
                                    >
                                      {listItem}
                                    </li>
                                  ))}
                                </ul>
                                <div className="indian-flag-img overflow-hidden">
                                  <img
                                    src={require("../assetes/ourassosiates/i.webp")}
                                    alt=""
                                    className="w-100 object-fit-cover"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                  <p
                    className="list-table-one m-0"
                    style={{ textAlign: "justify", overflow: "hidden" }}
                  >
                    However, the Flag Code to the extent it provides for
                    preserving respect and dignity of the National Flag, the
                    same deserves to be followed. Link &nbsp; <br />
                    <a
                      href="https://www.mha.gov.in/sites/default/files/flagcodeofindia_070214.pdf"
                      target="_blank"
                      className="ms-2"
                    >
                      https://www.mha.gov.in/sites/default/files/flagcodeofindia_070214.pdf
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FlageProgram;
