import React from "react";

const TempleDevelopment = () => {
  return (
    <section className="moa-sec">
      <div className="container">
        <h1>TempleDevelopment</h1>
      </div>
    </section>
  );
};

export default TempleDevelopment;
