import React from "react";
import { FaAngleUp } from "react-icons/fa";
import "./pages.css";

const TopBtn = () => {
  const GotoTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="top-btn rounded-circle" onClick={GotoTop}>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <FaAngleUp className="fs-3 text-light" />
        </div>
      </div>
    </>
  );
};

export default TopBtn;
