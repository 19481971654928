import { Tab } from "bootstrap";
import React from "react";
import { Tabs } from "react-bootstrap";

const BairganiyaMahostavDashboard = () => {
  return (
    <>
      <section className="mearathon">
        <div className="container">
          <div className="row w-100 gx-3 gy-4">
            <div className="col-md-7">
              <div className="merathon-detail">
                <div className="merathone-header d-flex flex-column align-items-center">
                  <div className="mahostav-img mb-3 overflow-hidden">
                    <img
                      src={require("../../../assetes/img/BairganiaPNG.png")}
                      alt=""
                      className="w-100 object-fit-cover"
                    />
                  </div>
                </div>
                <div className="register-dec">
                  <Tabs
                    defaultActiveKey="Regular"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab
                      eventKey="Regular"
                      className="tab-title"
                      title="Bairgania Mahotsav 2024"
                    >
                      <p
                        className={`regular-dec mahostav-dec main-text-chairman list-table-one`}
                      >
                        Only changes are stable in the universe, otherwise
                        everything is unstable! We are born as a child and go
                        through many changes… After the Bairgania Mahotsav 2023,
                        many modern changes have been seen in the development of
                        the Bairgania Block. We are coming again to honour the
                        talent of Bairagnia Block…. It is now the responsibility
                        of every person of Bairagnia Block to create a positive
                        field around himself, an positive energy field that
                        becomes bigger and bigger. Create as many waves of
                        laughter, joy, celebration as possible; Dance, sing so
                        that the entire human race gradually starts catching the
                        fire and the winds of Zen. Your participation and
                        attendance will be greatly appreciated, and we would be
                        honored to have you as our guest and participant. We
                        will be happy to provide you with any additional
                        information or assistance you may need. We look forward
                        to welcoming you to this event. With wings of hope,
                        everyone can fly. On the difficult path of struggle,
                        only man can progress! In deep despair, hope can take on
                        a pleasant color. Rauniyar Foundation Awards Bairgania
                        Mahostav 2023 Love has brought us together, and we
                        invite you to join us in the joyous celebration of the
                        Rauniyar Foundation Awards Bairgania Mahotsav 2024.We
                        have found our eternal being in each other and invite
                        you to witness the beginning of our eternal
                        togetherness.
                      </p>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BairganiyaMahostavDashboard;
