import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { OtpInput } from "reactjs-otp-input";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import "./Header/header.css";

const RegisterUser = ({ onClose, onSuccess, onSendData }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isRegister, setIsRegister] = useState(null);
  const [loader, setLoader] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [isDisableResendOtp, setIsDisableResendOtp] = useState(true);
  const [timer, setTimer] = useState(60);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    setIsRegister(null);
  }, [onClose]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onEnterOtp = (data) => {
    setOtp(data);
  };
  const onSubmit = (data) => {
    const modifiedData = {
      ...data,
      mobileNumber: `${countryCode}${data.mobileNumber}`,
    };

    setLoader(true);
    axios
      .post(`${BASE_URL}/api/user/login`, modifiedData)
      .then(function (response) {
        setIsRegister(response.data);
        toast.success("OTP sent to your phone");
      })
      .catch(function (error) {
        toast.error("Something went wrong!");
      })
      .finally(function () {
        setLoader(false);
      });
  };

  const onOtpSubmit = (data) => {
    const payload = JSON.stringify({
      mobileNumber: `${countryCode}${data.mobileNumber}`,
      otp: otp,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/api/user/otp/verify`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    setLoader(true);
    axios
      .request(config)
      .then(function (response) {
        let token = response.data.data.token;
        localStorage.setItem("token", JSON.stringify(token));
        toast.success("User Login Successfully..");
        onSuccess();
        reset();
        setOtp("");
        navigate("/");
        getUserData(token);
        onSendData(false);

        const backdrops = document.querySelectorAll(".offcanvas-backdrop");

        if (backdrops) {
          backdrops?.forEach((backdrop) => backdrop?.remove());

          document.body.classList.remove("offcanvas-open", "overflow-hidden");
        }
      })
      .catch(function (error) {
        // toast.error(`Error: ${"Something went wrong!"}`);
      })
      .finally(function () {
        setLoader(false);
      });
  };

  const getUserData = (token) => {
    axios
      .get(`${BASE_URL}/api/user/register/data`, {
        headers: {
          authorization: token,
        },
      })
      .then(function (response) {
        let participate = response.data.data;
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  const resendOtpLink = (data) => {
    setIsDisableResendOtp(true);
    let payload = {
      ...data,
      mobileNumber: `${countryCode}${data.mobileNumber}`,
    };

    axios
      .post(`${BASE_URL}/api/user/login`, payload)
      .then((response) => {
        toast.success(`OTP sent successfully!`);
        setIsDisableResendOtp(true);
        setTimer(60);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (timer > 0) {
      const timerId = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
      setIsDisableResendOtp(false);
    }
  }, [timer]);

  return (
    <>
      {loader && <Loader />}
      {!loader && (
        <>
          <div className="offcanvas-body">
            <div className="d-flex justify-content-center align-items-center w-100">
              <div className="fundation-img overflow-hidden">
                <img
                  src={require("../assetes/img/main-logo.png")}
                  alt=""
                  className="w-100 object-fit-cover"
                />
              </div>
            </div>
            {isRegister === null ? (
              <div className="off-register d-flex justify-content-center w-100">
                <div className="register-main">
                  <div className="register-main-header">
                    <p className="register-main-title h3">
                      Start your Registration
                    </p>
                    <p className="register-main-dec fw-medium">
                      Register using your mobile number. You will receive a
                      One-Time Password to verify your identity.
                    </p>
                  </div>
                  <div className="main-register d-flex flex-column align-items-center text-start w-100 mt-5">
                    <label
                      htmlFor=""
                      className="register-label fw-semibold mb-2 w-100"
                    >
                      Mobile Number
                    </label>
                    <div className="d-flex w-100">
                      <select
                        className="country-drop"
                        style={{
                          width: "36%",
                          border: "1px solid #ccc",
                          marginRight: "4px",
                          borderRadius: "6px",
                        }}
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                      >
                        <option value="+91">+91 (INA)</option>
                        <option value="+977">+977 (NP)</option>
                      </select>
                      <input
                        {...register("mobileNumber", {
                          required: "Mobile number is required",
                          pattern: {
                            value: /^[0-9]+$/i,
                            message: "Numeric characters only",
                          },
                        })}
                        type="tel"
                        placeholder="Enter your mobile number"
                        className="register-tab w-100"
                        maxLength={10}
                      />
                    </div>
                    <div className="w-100 px-2 pt-1">
                      {errors.mobileNumber && (
                        <p className="invalid text-start">
                          {errors.mobileNumber.message}
                        </p>
                      )}
                    </div>
                    <div className="register-button w-100 mt-3">
                      <button
                        className="register-btn fw-semibold"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Request OTP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="off-register d-flex justify-content-center w-100">
                <div className="register-main">
                  <div className="register-main-header">
                    <p className="register-otp-title h3">
                      Verify your mobile number
                    </p>
                    <p className="register-main-dec fw-medium">
                      Enter the OTP sent to the registered mobile number. Do not
                      share the OTP with anyone.
                    </p>
                  </div>
                  <div className="main-register d-flex flex-column align-items-center text-start w-100 mt-3">
                    <div className="w-100 mt-4 d-flex justify-content-center mb-4">
                      <OtpInput
                        name="otp"
                        value={otp}
                        onChange={(e) => onEnterOtp(e)}
                        numInputs={6}
                        separator={<span> </span>}
                        className="otp-input"
                      />
                    </div>
                    <div className="w-100 px-2 pt-1">
                      {errors.otp && (
                        <p className="invalid text-start">
                          {errors.otp.message}
                        </p>
                      )}
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 py-2 mt-2 mb-1">
                      {isDisableResendOtp ? (
                        <p className="resend-msg mb-0">
                          Resend code in {timer}
                        </p>
                      ) : (
                        <p className="resend-msg mb-0">
                          Didn't receive the code?
                        </p>
                      )}
                      <div className="resend-link ">
                        {!isDisableResendOtp && (
                          <button
                            className="resend-otp fw-semibold"
                            onClick={handleSubmit(resendOtpLink)}
                            disabled={isDisableResendOtp}
                          >
                            Resend OTP
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="register-button w-100 mt-3">
                      <button
                        className="register-btn fw-semibold"
                        onClick={handleSubmit(onOtpSubmit)}
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default RegisterUser;
