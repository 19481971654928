import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const FoodAndBeverge = () => {
  const tradeShowContent = [
    "The Food and Beverage industry is in a league of its own.",
    "Some of the most prominent trade shows in the world are centered on the hospitality industry, so it’s essential to leave your mark at your next event.",
    "But how do you stand out in an industry that continues to grow at an incredible rate?",
    "How do you make your mark known in this saturated market?",
    "How do you make your next Food and Beverage trade show worth your time and effort?",
    "Whether you’re exhibiting at the world-famous Food and Beverage trade show or the annual Rauniyar Foundation Bharat (RFB)- Bairagnia Mahotsav Bairgania, we want you to stand out from the competition regardless of where you are.",
    "We will give you some ideas that will set you apart and make your next Food and Beverage Trade Show successful.",
    "Let’s launch into the Rauniyar Foundation Bharat (RFB)- Bairagnia Mahotsav 2024 Food and Beverage Trade Show Exhibit Ideas that you could potentially use at your this year event.",
  ];
  const showDec = [
    {
      header: "TASTING STATIONS",
      txt: [
        "This is the most obvious addition you can add to your exhibit to make you stand out.",
        "While also being the most obvious added feature to your booth or exhibit, it is also the most effective way to draw attendees to your booth.",
        "We highly suggest setting up an interactive tasting station where potential clients can sample your products and make their own decisions based on their taste buds.",
        "Now, if you really want to get creative, and depending on your product, we suggest you consider a 'mix-your-own' section for beverages or even a 'top-it-yourself' snack bar. This would give your potential clients other options and separate you from being just a 'sample bar.'",
        "If your product is more advanced and unique, consider having a touchscreen panel that describes each sample’s tasting notes, ingredients, and important information. If the touch screen or any like-minded technology isn’t readily available, you should consider going old school with a poster or any type of signage.",
        "Keep your potential clients in the know.",
      ],
    },
    {
      header: "A COOKING SHOW",
      txt: [
        "This is very similar to our taste testing stations, which allow patrons the ability to try some of your products. Still, this feature also allows prospective clients to see the effort put into their creations.",
        "Cooking is an art, and it should be showcased as one.",
        "A cooking demonstration will allow people to appreciate the effort and precision some chefs must use to create a perfect dish.",
        "Scheduling regular demos on the trade show floor could lead to a bustling booth. You should be using this prime opportunity to showcase how your specific product can be used in various recipes, which shows versatility and could potentially inspire potential buyers.",
      ],
    },
    {
      header: "TRANSPORT TO ANOTHER WORLD",
      txt: [
        "One of the newest and most futuristic aspects you could add to your exhibit is Virtual Reality.",
        "By adding Virtual Reality to your exhibit, you could literally transport prospective clients to the foothills of Bairgania, where the vineyards stretch for miles, or a vegetable farm in Bairgania, where they can learn all about the origin of your products.",
        "Virtual Reality will add an unforgettable element to your exhibit, which could lead to more potential clients. Simply put, this immersive experience can create a much-needed memorable connection between the consumer and your brand’s story.",
      ],
    },
    {
      header: "NEW PRODUCT LAUNCH",
      txt: [
        "There’s nothing more exciting than debuting a product at your next trade show. The excitement from the buzz of eager trade show goers and even the anticipation from your loyal employees could be what you need to elevate your product.",
        "If you decide to introduce a new product at your next event, create a special 'launch counter' specifically for your new product. You should make your 'launch counter' visually striking with bold graphics and dynamic lighting. You should make this section of your exhibit special.",
        "You can also offer samples or even limited-time offers, creating a buzz on the trade show floor while encouraging attendees to share their experiences on social media.",
      ],
    },
    {
      header: "THEMES ON THEMES ON THEMES",
      txt: [
        "It might help if you design your trade show booth around a specific theme that aligns with your brand or product.",
        "Here’s a perfect example: if you’re promoting tropical fruit juices, you should consider designing your booth with a vibrant, colorful tropical theme with all the necessary decorations and maybe even add some beachy sounds.",
        "A fun or in-depth theme showcased around your booth can create a more engaging and cohesive experience for your potential clients. The more you stand out, the better for your prospects down the road.",
        "A theme booth is a must in a sea of plain booths.",
      ],
    },
    {
      header: "GET IN THE HEALTH ZONE",
      txt: [
        "This tip is a little unconventional. Given the growing interest in health and wellness, you might consider having a specific zone dedicated to health-focused products or services.",
        "You should provide informational pamphlets or digital kiosks that showcase your product’s health benefits. Due to the uptick in health-centric foods and services, this has become increasingly important to most clients.",
        "You could also offer quick health assessments or seminars from nutritionists that could potentially draw in the health-conscious prospective client.",
        "Whether your product emphasizes the importance of a healthy diet or doesn’t, it’s imperative. You still understand that the patron wants to know what they are consuming in detail.",
        "Make it known and make it easy to understand.",
      ],
    },
    {
      header: "NETWORKING AND RELAXING",
      txt: [
        "When you go to a trade show or expo event, the objective is always to network. You should consider creating a welcoming networking lounge where industry professionals can network and relax. You would need to create an incentive to stay in this designed area.",
        "Offering complimentary refreshments or even a taste test with comfortable seating could lead to potential clients staying long at your booth.",
        "You could also designate a private meeting space within your networking lounge for serious business discussions.",
        "Creating an area designed for that specific reason could be a perfect setup for your networking abilities.",
      ],
    },
  ];

  return (
    <section className="mearathon">
      <div className="container">
        <div className="row w-100 gx-3 gy-4">
          <div className="col-md-7">
            <div className="merathon-detail">
              <div className="register-dec">
                <Tabs
                  defaultActiveKey="Regular"
                  id="uncontrolled-tab-example"
                  className="mb-3 d-none"
                >
                  <Tab
                    eventKey="Regular"
                    className="tab-title"
                    // title="YUVA Pratibha-2024"
                  >
                    <div className="main-text-mahostav">
                      <p
                        className={`regular-dec mahostav-dec main-text-chairman list-table-one `}
                      >
                        <p>
                          <div className="chairman-title d-flex align-items-center px-2 py-1 my-3">
                            <h1
                              className="main-text-vicechairman-title mb-0"
                              style={{ lineHeight: "30px" }}
                            >
                              Rauniyar Foundation Bharat(RFB) FOOD AND BEVERAGE
                              TRADE SHOW
                            </h1>
                          </div>
                          {tradeShowContent.map((item, index) => {
                            return (
                              <>
                                <div className="mt-3" key={index}>
                                  <p className="list-table-one mb-1">{item}</p>
                                </div>
                              </>
                            );
                          })}

                          <span className="fw-semibold d-flex align-items-center gap-3">
                            <FaArrowRightLong className="me-2 my-4" />
                            Entry Fee:{" "}
                            <Link
                              to={"/bairganiaMahotsav"}
                              className="text-mahostav"
                            >
                              Entry Link
                            </Link>{" "}
                            <br />
                          </span>
                          {showDec.map((item, index) => {
                            return (
                              <>
                                <div
                                  className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2"
                                  key={index}
                                >
                                  <h1 className="main-text-vicechairman-title mb-0">
                                    {index + 1}. {item.header}
                                  </h1>
                                </div>
                                {item.txt.map((item, index) => {
                                  return (
                                    <>
                                      <p className="list-table-one" key={index}>
                                        {item}
                                      </p>
                                    </>
                                  );
                                })}
                              </>
                            );
                          })}

                          <div className="food footer">
                            <div className="d-flex align-items-start w-75">
                              <FaArrowRightLong className="me-2 my-2" />
                              <p className="list-table-one fw-semibold">
                                Rauniyar Foundation Bharat(RFB) FOOD AND
                                BEVERAGE Awards 2024
                              </p>
                            </div>
                            <span className="fw-semibold d-flex gap-2 my-2">
                              <FaArrowRightLong className="me-2 my-1" />
                              Registration Deadline: 15 November 2024
                            </span>
                            <span className="fw-semibold d-flex gap-2 my-2">
                              <FaArrowRightLong className="me-2 my-1" />
                              Prizes: Kitchen equipment and more.
                            </span>
                            <span className="fw-semibold d-flex gap-2 my-2">
                              <FaArrowRightLong className="me-2 my-1" />
                              Entry Fee: N/A
                            </span>
                          </div>
                        </p>
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="d-flex  align-items-center w-100 h-100 overflow-hidden">
              <div className="mahostav-video p-3 w-100">
                <div className="video-mahostav overflow-hidden rounded-2">
                  <video
                    className="w-100 h-100 object-fit-cover"
                    poster={require("../../../assetes/img/BairganiaPNG.png")}
                    controls
                  >
                    <source src={require("../../../assetes/video/food.mov")} />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FoodAndBeverge;
