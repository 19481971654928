import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "../../App.css";
const Run4UnemploymentAwareness = () => {
  return (
    <>
      <section className="moa-sec" style={{ marginTop: "300px" }}>
        <div className="container">
          <div className="half-merathone-list mb-3">
            <div className="half-merathone-list-header d-flex gap-3 align-items-center w-100">
              <div className="half-marathon-img">
                <img
                  src={require("../../assetes/img/Hanumanji-PNG.png")}
                  alt=""
                  className="w-100"
                />
              </div>
              <div className="chairman-title  d-flex align-items-center px-2 py-1">
                <h1 className="demo-title">Run4Unemployment Awareness</h1>
              </div>
            </div>
          </div>
          <p className="text-bold main-text-chairman demo-run-dec">
            In its 1st edition, the Rauniyar Foundation Bharat Unemployment
            Awareness half marathon to enhance its philanthropic impact. This
            will enables participants to support various causes, including
            disaster relief, education, health, and economic mobility.{" "}
            <FaArrowRightLong /> <Link to={"/halfmerathon"}>more Info...</Link>
          </p>
        </div>
      </section>
    </>
  );
};

export default Run4UnemploymentAwareness;
