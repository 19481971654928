import React from "react";
import "./careers.css";

const Careers = () => {
  return (
    <section className="moa-sec">
      <div className="container mt-5">
        <div className="careers-content d-flex justify-content-center align-items-center w-100">
          <p className="careear-text demo-run-dec mt-3">
            Careers Rauniyar Foundation Bharat is a non-profit organization that
            aspires to Change Lives and Empower India. We seek highly motivated
            and qualified individuals to engage with Rauniyar Foundation Bharat
            in areas of Women Empowerment, Rural Transformation, Health, and
            Education, Sports for Development, Disaster Response, Arts, Culture
            & Heritage, and Urban Renewal. Our team plays an important role in
            making a difference to the lives of the underprivileged. Guided by
            an immensely spirited team, Rauniyar Foundation Bharat is an equal
            opportunity employer.{" "}
            <a href="https://rauniyarfoundationbharat.org/">
              Join the movement now!
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Careers;
