import React from "react";

const ViceChairmanMsg = () => {
  return (
    <section className="moa-sec">
      <div className="container">
        <div className="row w-100 gy-2">
          <div className="col-lg-2 col-md-2 col-sm-2 d-flex justify-content-center align-items-start">
            <div className="">
              <div className="chairman-main-img  overflow-hidden w-100">
                <img
                  src={require("../../assetes/img/Mam.jpg")}
                  alt=""
                  className="w-100 object-fit-cover align-self-start"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-10 col-md-10 col-sm-8">
            <div className="chairman-title  d-flex align-items-center px-2 py-1">
              <h1 className="main-text-vicechairman-title">
                ARCHANA PATEL GUPTA Founder & Director of Rauniyar Foundation
                Bharat
              </h1>
            </div>
            <div className="main-text-vicechairman main-text-chairman list">
              <p className="mt-4">
                Thank you so much for taking the time to learn more about The
                Rauniyar Foundation Bharat. I hope you will become as passionate
                and as enthusiastic about our mission as I am.
              </p>
              <p>
                This Foundation is very near and dear to my heart. It is
                committed to increasing the number of minority youth who excel
                both academically and professionally and are equipped with the
                essential tools to become effective leaders in their
                communities. Foundation programs will provide mentoring and
                tutoring, leadership development, and college preparation to
                minority youth living in underserved communities in India.
                Furthermore, annual scholarships will be distributed to
                graduating high school seniors who show academic excellence and
                exemplary leadership potential.
              </p>
              <p>
                While growing up in Vadodara, Gujarat, I learned the value of
                education and reaching towards my dreams. As a first-generation
                Patel community member, I watched my parents work exceptionally
                hard to make ends meet. We didn’t live in the best
                neighborhoods, but I knew at an early age I didn’t want to
                become a product of my environment. Instead, I chose to become a
                product of my decisions, choosing to go to college and commit to
                high achievement and success. In spite of the challenges and the
                many obstacles I faced, I graduated from the International
                Institute of Fashion Designing of Surat with a Fashion Designer
                degree.
              </p>
              <p>
                The thing is, most statistics on Patel females will tell you the
                same thing. I either would have ended up living a life as a
                housewife without a college degree or worse. It’s by God’s grace
                and the support of my husband and family that I was able to beat
                those odds.
              </p>
              <p>
                But my journey isn’t over yet. Every obstacle I’ve overcome made
                me even more committed to look for opportunities to give back.
                I’ve made it my mission to make a positive impact on society
                and, especially, to lift up future generations in the process.
                The Foundation believes children are a symbol of hope for the
                future, and we should serve as a resource for motivation and
                empowerment. I encourage you to get involved and become a
                volunteer, partner, and financial contributor to The Rauniyar
                Foundation Bharat. Your efforts will make a substantial
                difference in the lives of many children.
              </p>
              <p>Kind Regards,</p>
              <p className="fw-semibold mb-1">Archana Patel Gupta</p>
              <p className="fw-semibold mb-1">Founder and Director</p>
              <p className="fw-semibold mb-1">Rauniyar Foundation Bharat</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViceChairmanMsg;
